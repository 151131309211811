import Form from 'components/Form/Form'

// @ts-ignore
import AddressDef from './AddressDef.json'
// @ts-ignore
import css from './Address.module.css'

type AddressProps = {
  quote: any
  customer: any
  children?: any
  onSubmit?: any
  readOnly?: boolean
}

const Address = ({ quote, customer, children, onSubmit, readOnly }: AddressProps) => {
  const data = { ...quote, company: customer }

  return (
    <div>
      <Form data={data} inputs={AddressDef} onSubmit={onSubmit} className={css.Grid} readOnly={readOnly}>
        {children}
      </Form>
    </div>
  )
}

export default Address
