import React, { useState, useEffect } from 'react'
import {
  Tooltip,
  Rating,
  ActionIcon,
  Grid,
  Text,
  ThemeIcon,
  Paper,
  Group,
  Stack,
  Button,
  Badge,
  NumberFormatter,
  Loader,
} from '@mantine/core'
import {
  IconFileExport,
  IconUpload,
  IconFileDownload,
  IconPencil,
  IconDownload,
  IconTrash,
  IconPackage,
  IconMail,
  IconSeeding,
  IconFileTypeXls,
  IconApi,
  IconFileUpload,
  IconLibraryPlus,
} from '@tabler/icons-react'
import { SlCloudDownload, SlWallet } from 'react-icons/sl'
import { IoPricetagOutline } from 'react-icons/io5'
import { PiCurrencyDollarThin } from 'react-icons/pi'
import { observer } from 'mobx-react-lite'
import DelayedActionButton from '../../../components/DelayedActionButton/DelayedActionButton'
import BookModal from './BookModal'
import Spinner from 'components/Spinner/Spinner'
import { ExcelExporter } from '../../../utils/excelExport'

const BookItem = observer(({ booksStore, book, icon, color, onSetDefault, deleteBook }) => {
  const [isExporting, setIsExporting] = useState(false)

  const exportBook = async (book) => {
    try {
      setIsExporting(true)
      await booksStore.fetchPricebookEntries([book._key])
      let entries = booksStore.getPricebookEntries(book._key)

      if (entries && entries.length > 0) {
        const exporter = new ExcelExporter().addSheet(entries, 'Book entries', [
          'productId',
          'productCode',
          'productName',
          'unitPrice',
          'isActive',
        ])
        exporter.download(`${new Date().toISOString()} ${book.name}-export.xlsx`)
      }
    } catch (error) {
      console.error('Export failed:', error)
    } finally {
      setIsExporting(false)
    }
  }

  return (
    <Paper p="xs" withBorder sx={{ height: '100%', boxSizing: 'border-box' }}>
      <Group spacing={8} position="left" wrap="nowrap" mb={2}>
        <ThemeIcon variant="transparent" color={color} size={24} radius="xl">
          {icon}
        </ThemeIcon>
        <Text size="sm" fw={book.isDefault ? 700 : 400} truncate>
          {book.name}
        </Text>
        <Tooltip label="Mark as default" color="gray" offset={8}>
          <Rating value={book.isDefault ? 1 : 0} count={1} fractions={1} onChange={() => !book.isDefault && onSetDefault(book)} />
        </Tooltip>
      </Group>
      <Grid grow gutter={5}>
        <Grid.Col span={5}>
          <Text size="xs" c="dimmed" lineClamp={2}>
            {book.description}
          </Text>

          <Group justify="flex-start" wrap="nowrap" mt={14} h={24}>
            {/* <Button variant="default" leftSection={<IconUpload size={18} />} size="sm" radius="sm">
            <Text size="xs">Upload</Text>
          </Button>
          <Button variant="default" leftSection={<IconFileExport size={18} />} size="sm" radius="sm">
            <Text size="xs">Export</Text>
          </Button> */}
            {/* <Button variant="default" leftSection={<IconPencil size={18} />} size="sm" radius="sm">
            <Text size="xs">Edit</Text>
          </Button> */}

            <Tooltip
              label="Upload an XLSX that replaces ALL book entries with the content of the file"
              color="gray"
              offset={8}
              position="bottom"
            >
              <ActionIcon variant="white" color="gray.8" size="xs">
                <IconUpload size={18} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Export this book entries as XLSX" color="gray" position="bottom" offset={8}>
              <ActionIcon variant="white" color="gray.8" size="xs" disabled={isExporting} onClick={async () => exportBook(book)}>
                {isExporting ? <Loader size={10} /> : <SlCloudDownload size={18} />}
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Delete book with all its entries" color="gray" position="bottom" offset={8}>
              <ActionIcon variant="white" color="gray.8" size="xs">
                <IconPencil size={18} />
              </ActionIcon>
            </Tooltip>
            {/* <Tooltip label="Delete book with all its entries" color="gray" position="bottom" offset={8}>
            <ActionIcon variant="white" color="red.3" size="xs" onClick={() => deleteBook(book.key)}>
              <IconTrash size={18} />
            </ActionIcon>
          </Tooltip> */}
            <DelayedActionButton onAction={() => deleteBook(book._key)} delayInSeconds={5}>
              <ActionIcon variant="white" color="red.3" size="xs">
                <IconTrash size={18} />
              </ActionIcon>
            </DelayedActionButton>
          </Group>
        </Grid.Col>
        <Grid.Col span={4}>
          <Group grow>
            <Text size="xs" c="dimmed">
              Products:
            </Text>
            <Text size="xs" align="right">
              <NumberFormatter thousandSeparator decimalScale={0} value={book.productCount}></NumberFormatter>
            </Text>
          </Group>
          <Group grow>
            <Text size="xs" c="dimmed">
              Created:
            </Text>
            <Text size="xs" align="right">
              {book.createdAt}
            </Text>
          </Group>
          <Group grow>
            <Text size="xs" c="dimmed">
              Updated:
            </Text>
            <Text size="xs" align="right">
              {book.updatedAt}
            </Text>
          </Group>
        </Grid.Col>
      </Grid>
    </Paper>
  )
})

const BooksSettings = observer(({ booksStore }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [bookType, setBookType] = useState('')

  const handleCreateBook = (type) => {
    setBookType(type === 'pricebook' ? 'pricebook' : 'costbook')
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
    setBookType('')
  }

  const handleModalSuccess = () => {
    booksStore.fetchBooks()
  }

  const priceIcon = <IoPricetagOutline size={16} style={{ strokeWidth: 1 }} />
  const costIcon = <PiCurrencyDollarThin size={16} style={{ strokeWidth: 2 }} />

  if (booksStore && booksStore.isLoading && booksStore.priceBooks.length === 0 && booksStore.costBooks.length === 0) {
    return <Loader size="md" />
  }

  if (booksStore && booksStore.error && booksStore.priceBooks.length === 0 && booksStore.costBooks.length === 0) {
    return <Text color="red">{booksStore.error}</Text>
  }

  if (!booksStore || booksStore.isLoading) return <Spinner />

  return (
    <div>
      <Grid gutter="md">
        <Grid.Col span={6}>
          <Group justify="flex-start" mt={0} mb="lg">
            <Text size="sm" fw="500">
              Price Books
            </Text>
            <Tooltip label="Create a new PRICE book" color="gray" position="bottom" offset={8}>
              <ActionIcon variant="transparent" color="green.4" size="xs" onClick={() => handleCreateBook('pricebook')}>
                <IconLibraryPlus size={20} />
              </ActionIcon>
            </Tooltip>
          </Group>
          <Stack spacing="xs">
            {booksStore.priceBooks.map((book) => (
              <BookItem
                key={book._id}
                book={{ ...book, isPricebook: true }}
                icon={priceIcon}
                color="blue"
                onSetDefault={booksStore.setDefaultBook}
                deleteBook={booksStore.deleteBook}
                booksStore={booksStore}
              />
            ))}
          </Stack>
        </Grid.Col>

        <Grid.Col span={6}>
          <Group justify="flex-start" mt={0} mb="lg">
            <Text size="sm" fw="500">
              Cost Books
            </Text>
            <Tooltip label="Create a new COST Book" color="gray" position="bottom" offset={8}>
              <ActionIcon variant="transparent" color="green.4" size="xs" onClick={() => handleCreateBook('costbook')}>
                <IconLibraryPlus size={20} />
              </ActionIcon>
            </Tooltip>
          </Group>
          <Stack spacing="xs">
            {booksStore.costBooks.map((book) => (
              <BookItem
                key={book._key}
                book={{ ...book, isCostbook: true }}
                icon={costIcon}
                color="orange"
                onSetDefault={booksStore.setDefaultBook}
                deleteBook={booksStore.deleteBook}
                booksStore={booksStore}
              />
            ))}
          </Stack>
        </Grid.Col>
      </Grid>

      {modalOpen && (
        <BookModal
          opened={modalOpen}
          onClose={handleModalClose}
          bookType={bookType}
          onSuccess={handleModalSuccess}
          booksStore={booksStore}
        />
      )}
    </div>
  )
})

export default BooksSettings
