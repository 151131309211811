import http, { client, upload } from './http'

class OpportunitiesApi {
  getAll = () => client.get('/sales/opportunities')
  getOne = (id) => client.get('/sales/opportunities/' + id)

  getWithUrlKey = (urlKey) => client.get('/sales/onelink/opportunities/' + urlKey)
  oneLinkReply = (urlKey, body) => client.put(`/sales/onelink/opportunities/${urlKey}`, body)

  reply = (id, body) => client.put(`/sales/opportunities/${id}/reply`, body)

  createOrder = (id, body) => client.post(`/sales/opportunities/${id}/order`, body)

  delete = (id) => client.delete(`/sales/opportunities/${id}`)

  updateBooks = (id, books) => client.put(`/sales/opportunities/${id}/books`, books)

  getFiles = (id, urlKey) => {
    if (urlKey) {
      return client.get(`/sales/onelink/${id}/files/${urlKey}`)
    }
    return client.get(`/sales/opportunities/${id}/files`)
  }

  uploadFile = (id, file, body, urlKey) => {
    if (urlKey) {
      return upload(`/sales/onelink/${id}/files/${urlKey}`, file, body)
    }
    return upload(`/sales/opportunities/${id}/files`, file, body)
  }

  downloadFile = async (id, fileId, urlKey) => {
    const client = http.create()
    let url = `/sales/opportunities/${id}/files/${fileId}`

    if (urlKey) url = `/sales/onelink/${id}/files/${fileId}/${urlKey}`

    return client.get(url, { responseType: 'blob' })
  }

  deleteFile = async (id, fileId, urlKey) => {
    const client = http.create()
    let url = `/sales/opportunities/${id}/files/${fileId}`

    if (urlKey) url = `/sales/onelink/${id}/files/${fileId}/${urlKey}`

    return client.delete(url)
  }

  verifyUserContact = async ({ email, zipCode }, urlKey) => {
    email = (email || '').toLowerCase().trim()
    zipCode = (zipCode || '').trim()
    if (email && zipCode && urlKey) {
      const res = await client.post(`/account/verifyUserContact`, { email, zipCode }, { headers: { 'x-api-key': urlKey } })
      return res
    }
    return { email: { isValid: false }, zipCode: { isValid: false } }
  }

  sendInquiryForm = async ({ email, name, zipCode, ...formData }, urlKey) => {
    email = (email || '').toLowerCase().trim()
    zipCode = (zipCode || '').trim()
    if (email && zipCode && urlKey) {
      const res = await client.post(`/account/products/newProductsInquiry`, { email, zipCode, name, ...formData }, { headers: { 'x-api-key': urlKey } })
      return res
    }
    return { email: { isValid: false }, zipCode: { isValid: false } }
  }
}

const opportunities = new OpportunitiesApi()
export default opportunities
