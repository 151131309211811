import { Tabs } from '@mantine/core'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import { PageHeader } from 'components/PageHeader/PageHeader'

import ViewTerms from './ViewTerms'
import AccountUsers from './AccountUsers'
import AccountDetails from './AccountDetails'

import accountApi from 'api/account'

// @ts-ignore
import css from './Account.module.css'

const AccountPage = () => {
  const resolve = usePreload(accountApi.getMyAccount)
  const { data, onRetry } = resolve

  const { users, settings } = (data || {}) as any

  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Settings', href: '/' }, //
    { label: 'Account' },
  ]

  return (
    <Preload {...resolve} className={css.layout}>
      <PageHeader title="My Account" breadcrumbs={breadcrumbs} />

      <Tabs keepMounted={false} color="teal" defaultValue={'account'}>
        <Tabs.List>
          <Tabs.Tab value="account">Account</Tabs.Tab>
          <Tabs.Tab value="terms">Terms</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="account">
          <AccountDetails data={data || {}} onRetry={onRetry} />

          <AccountUsers users={users} onRetry={onRetry} />
        </Tabs.Panel>

        <Tabs.Panel value="terms">
          <ViewTerms {...settings} onRetry={onRetry} />
        </Tabs.Panel>
      </Tabs>
    </Preload>
  )
}

export default AccountPage
