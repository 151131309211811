import { makeAutoObservable, observable, computed, action, runInAction } from 'mobx'
import { BundleProductConstraints, Field, ProductEdgeType } from '@broadn/commons'
import { HierarchyProduct, ProductSelection, FeatureItem } from '../screens/Bundles/CPQTypes'
import backend from 'api/backend'

export class BundleState {
  allProducts = new Map<string, HierarchyProduct>()
  topProducts: string[] = []
  selectedProducts = new Set<string>()
  userSelectedProducts = new Set<string>()
  productQuantities: ProductSelection = {}
  bundleProductConstraints: { [k: string]: BundleProductConstraints } = {}
  pricebooks: Array<{ label: string; value: string; isDefault: boolean; description: string }> = []
  selectedPricebook: string | null = null
  pricebookEntries = new Map<string, any>()
  productFieldDefinitions = new Map<string, Field>()
  showProductAttributes = false
  isLoading = true

  unifiedBundleConstraints: BundleProductConstraints | null = null

  constructor() {
    makeAutoObservable(this, {
      allProducts: observable,
      topProducts: observable,
      selectedProducts: observable,

      productQuantities: observable,
      bundleProductConstraints: observable,
      pricebooks: observable,
      selectedPricebook: observable,
      pricebookEntries: observable,
      productFieldDefinitions: observable,
      showProductAttributes: observable,
      isLoading: observable,

      totalPrice: computed,
      selectionStats: computed,

      loadData: action,
      loadPricebooks: action,
      loadPricebookEntries: action,
      loadProductDefinitions: action,
      toggleProductSelection: action,
      updateProductQuantity: action,
      updateProductPrice: action,
      toggleProductAttributes: action,
      applySelectionConstraints: action,
      mergeUserSelectedConstraints: action,
    })

    // Initialize data loading
    this.loadData()
  }

  emptyBundleConstraints(): BundleProductConstraints {
    return {
      bundleId: '',
      requiredBundled: [],
      requiredNonBundled: [],
      optionalSelectedBundled: [],
      optionalSelectedNonBundled: [],
      optionalUnselected: [],
      productExclusion: {},
      productDependency: {},
    }
  }

  // Computed values
  get requiredProducts(): Set<string> {
    const required = new Set<string>()
    if (!this.unifiedBundleConstraints) return required

    const constraints = this.unifiedBundleConstraints
    if (constraints) {
      constraints.requiredBundled.forEach((p) => required.add(p))
      constraints.requiredNonBundled.forEach((p) => required.add(p))

      Array.from(this.selectedProducts).forEach((pid) => {
        if (constraints.productDependency[pid]) {
          constraints.productDependency[pid].forEach((dep) => required.add(dep))
        }
      })
    }

    return required
  }

  get restrictedProducts(): Set<string> {
    const restricted = new Set<string>()
    const constraints = this.unifiedBundleConstraints
    if (!constraints) return restricted

    Array.from(this.selectedProducts).forEach((pid) => {
      if (constraints.productExclusion[pid]) {
        constraints.productExclusion[pid].forEach((ex) => restricted.add(ex))
      }
    })
    return restricted
  }

  get totalPrice(): number {
    return Object.entries(this.productQuantities).reduce((acc, [k, v]) => {
      if (!this.selectedProducts.has(k)) return acc
      return acc + (v.price || 0) * (v.quantity || 0)
    }, 0)
  }

  get selectionStats() {
    return {
      productsRequired: Array.from(this.requiredProducts).filter((p) => !this.selectedProducts.has(p)),
      productsRestricted: Array.from(this.restrictedProducts).filter((p) => this.selectedProducts.has(p)),
      totalSelected: this.selectedProducts.size,
    }
  }

  async loadData() {
    try {
      this.isLoading = true
      const data: any = await backend.getProductHierarchy()
      runInAction(() => {
        const allProductsTemp = new Map(data.allProducts.map((p: any) => [p._id, p]))
        data.allProducts.forEach((product: any) => {
          if (product.features) {
            product.features.sort((a: any, b: any) => (a.feature.number || 0) - (b.feature.number || 0))
            product.features.forEach((feature: any) => {
              if (feature.featureProducts) {
                feature.featureProducts.sort((a: any, b: any) => (b.options.isRequired ? 1 : 0) - (a.options.isRequired ? 1 : 0))
              }
            })
          }
        })

        this.topProducts = data.topProducts
        this.allProducts = allProductsTemp as Map<string, HierarchyProduct>
        this.bundleProductConstraints = data.bundleProductConstraints
        this.isLoading = false
      })

      await Promise.all([this.loadPricebooks(), this.loadProductDefinitions()])
    } catch (error) {
      console.error('Error loading product hierarchy data:', error)
      this.isLoading = false
    }
  }

  async loadPricebooks() {
    try {
      const allPricebooks = await backend.getPriceAndCostBooks()

      runInAction(() => {
        if (allPricebooks && Array.isArray(allPricebooks)) {
          const pbValues = (allPricebooks as any[])
            .filter((pb) => pb.isPricebook)
            .map((pb: any) => ({
              label: pb.name || pb.Name,
              description: pb.description || pb.Description,
              isDefault: pb.isDefault,
              value: pb._key,
            }))

          this.pricebooks = pbValues
          const defaultPb = pbValues.find((pbv) => pbv.isDefault)
          if (defaultPb) {
            this.selectedPricebook = defaultPb.value
            this.loadPricebookEntries(defaultPb.value)
          }
        }
      })
    } catch (error) {
      console.error('Error loading pricebooks:', error)
    }
  }

  async loadPricebookEntries(pricebookId: string) {
    this.isLoading = true
    try {
      const response: any = await backend.getPriceBookEntries(pricebookId)
      if (!response) return
      runInAction(() => {
        const pbes = Array.isArray(response.entries) ? response.entries : response?.data.entries || []
        const pbEntries = new Map<string, any>(pbes.map((pbe: any) => [`Products/${pbe.productId}`, pbe]))
        this.pricebookEntries = pbEntries
      })
    } catch (error) {
      console.error('Error loading pricebook entries:', error)
    }
    finally {
      this.isLoading = false
    }
  }

  async loadProductDefinitions() {
    try {
      const response: any = await backend.getSalesforceObjectDefinition('Product2')

      runInAction(() => {
        if (response) {
          const productFields = response.description.fields.map((f: Field) => [f.name, f])
          this.productFieldDefinitions = new Map(productFields)
        }
      })
    } catch (error) {
      console.error('Error loading product definitions:', error)
    }
  }

  toggleProductSelection(productId: string) {
    const product = this.allProducts.get(productId)
    if (!product) return

    if (this.topProducts.includes(productId) && !this.userSelectedProducts.has(productId)) {
      this.productQuantities = {}
      this.selectedProducts = new Set()
      this.userSelectedProducts = new Set()
      this.unifiedBundleConstraints = this.bundleProductConstraints[productId] || this.emptyBundleConstraints()
    }

    if (this.userSelectedProducts.has(productId)) {
      this.userSelectedProducts.delete(productId)
    } else {
      this.userSelectedProducts.add(productId)
    }

    this.mergeUserSelectedConstraints()
    this.applySelectionConstraints()
  }

  applySelectionConstraints() {
    if (!this.unifiedBundleConstraints) return
    const userSelProdArray = Array.from(this.userSelectedProducts)
    const exclusions = new Set<string>(userSelProdArray.flatMap((usp) => this.unifiedBundleConstraints?.productExclusion[usp] || []))
    const dependentProducts = userSelProdArray.flatMap((usp) => this.unifiedBundleConstraints?.productDependency[usp] || [])

    const newSelections = new Set<string>(
      [
        ...this.unifiedBundleConstraints.requiredBundled,
        ...this.unifiedBundleConstraints.requiredNonBundled,
        ...this.unifiedBundleConstraints.optionalSelectedBundled,
        ...this.unifiedBundleConstraints.optionalSelectedNonBundled,
        ...dependentProducts,
      ].filter((pid) => !exclusions.has(pid))
    )

    this.productQuantities = {}
    this.selectedProducts = new Set()
    newSelections.forEach((pid) => {
      this.selectedProducts.add(pid)
      this.setDefaultQuantity(pid)
    })
    this.userSelectedProducts.forEach((pid) => {
      this.selectedProducts.add(pid)
      this.setDefaultQuantity(pid)
    })
  }

  setDefaultQuantity(productId: string, quantityConfig?: any) {
    const product = this.allProducts.get(productId)
    if (!product) return
    const config = quantityConfig ||
      product.quantityConfig || {
        default: 1,
        min: 0,
        max: Infinity,
        priceDecimals: 0,
      }

    const defaultQty = Math.max(config.default || 0, config.min || 0, 1)

    console.log('pricebookEntries', productId, this.pricebookEntries.get(productId))
    this.productQuantities[productId] = {
      quantity: defaultQty,
      price: this.pricebookEntries.get(productId)?.unitPrice || 0,
    }
  }

  mergeUserSelectedConstraints() {
    this.unifiedBundleConstraints = Array.from(this.userSelectedProducts).reduce((acc, productId) => {
      const product = this.allProducts.get(productId)
      const productConstraints = this.bundleProductConstraints[productId]
      if (!product || !productConstraints) return acc
      return {
        bundleId: acc.bundleId,
        requiredBundled: [...new Set([...acc.requiredBundled, ...productConstraints.requiredBundled])],
        requiredNonBundled: [...new Set([...acc.requiredNonBundled, ...productConstraints.requiredNonBundled])],
        optionalSelectedBundled: [...new Set([...acc.optionalSelectedBundled, ...productConstraints.optionalSelectedBundled])],
        optionalSelectedNonBundled: [...new Set([...acc.optionalSelectedNonBundled, ...productConstraints.optionalSelectedNonBundled])],
        optionalUnselected: [...new Set([...acc.optionalUnselected, ...productConstraints.optionalUnselected])],

        productExclusion: {
          ...acc.productExclusion,
          ...productConstraints.productExclusion,
        },
        productDependency: {
          ...acc.productDependency,
          ...productConstraints.productDependency,
        },
      }
    }, this.emptyBundleConstraints())
  }

  updateProductQuantity(productId: string, quantity: number) {
    const product = this.allProducts.get(productId)
    if (!product || !product.quantityConfig?.quantityEditable) return

    const min = product.quantityConfig?.min || 0
    const max = product.quantityConfig?.max || Infinity
    const clampedQuantity = Math.max(min, Math.min(max, quantity))

    this.productQuantities[productId] = {
      ...(this.productQuantities[productId] || {}),
      quantity: clampedQuantity,
    }

    // Add to selected products if quantity > 0
    if (clampedQuantity > 0 && !this.selectedProducts.has(productId)) {
      this.selectedProducts.add(productId)
    } else if (clampedQuantity === 0 && !this.requiredProducts.has(productId) && this.selectedProducts.has(productId)) {
      this.selectedProducts.delete(productId)
    }
  }

  updateProductPrice(productId: string, price: number) {
    const product = this.allProducts.get(productId)
    if (!product) return
    if (!product.quantityConfig?.priceEditable) return
    this.productQuantities[productId] = {
      ...(this.productQuantities[productId] || {}),
      price,
    }
  }

  toggleProductAttributes() {
    this.showProductAttributes = !this.showProductAttributes
  }

  isProductInCategory(productId: string, category: keyof BundleProductConstraints) {
    if (!this.unifiedBundleConstraints) return false

    const categoryValue = this.unifiedBundleConstraints[category]
    return Array.isArray(categoryValue) && categoryValue.includes(productId)
  }

  getProductAttributes(productId: string): ProductAttributes {
    if (!this.unifiedBundleConstraints) {
      return {
        isRequiredBundled: false,
        isRequiredNonBundled: false,
        isOptionalSelectedBundled: false,
        isOptionalSelectedNonBundled: false,
        isOptionalUnselected: false,
        isExcluded: false,
        isDependency: false,
        isSelected: this.selectedProducts.has(productId),
        isUserSelected: this.userSelectedProducts.has(productId),
      }
    }

    const isExcluded = Array.from(this.selectedProducts).some((pid) => {
      const exclusions = this.unifiedBundleConstraints?.productExclusion[pid] || []
      return exclusions.includes(productId)
    })

    const isDependency = Array.from(this.selectedProducts).some((pid) => {
      const dependencies = this.unifiedBundleConstraints?.productDependency[pid] || []
      return dependencies.includes(productId)
    })

    return {
      isRequiredBundled: this.isProductInCategory(productId, 'requiredBundled'),
      isRequiredNonBundled: this.isProductInCategory(productId, 'requiredNonBundled'),
      isOptionalSelectedBundled: this.isProductInCategory(productId, 'optionalSelectedBundled'),
      isOptionalSelectedNonBundled: this.isProductInCategory(productId, 'optionalSelectedNonBundled'),
      isOptionalUnselected: this.isProductInCategory(productId, 'optionalUnselected'),
      isExcluded: isExcluded,
      isDependency: isDependency,
      isSelected: this.selectedProducts.has(productId),
      isUserSelected: this.userSelectedProducts.has(productId),
    }
  }
}

export type ProductAttributes = {
  isRequiredBundled: boolean
  isRequiredNonBundled: boolean
  isOptionalSelectedBundled: boolean
  isOptionalSelectedNonBundled: boolean
  isOptionalUnselected: boolean
  isExcluded: boolean
  isDependency: boolean
  isSelected: boolean
  isUserSelected: boolean
}
