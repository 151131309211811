import { Checkbox, Stack, Group } from '@mantine/core'

const MultiCheckbox = ({ data, label, description, defaultValue, name, form, size }) => {
  return (
    <Checkbox.Group
      size={size}
      defaultValue={defaultValue}
      label={label}
      description={description}
      withAsterisk
      {...form.getInputProps(name)}
    >
      <Group mt="sm">
        {data.map(({ label, value, disabled }) => (
          <Checkbox value={value} label={label} key={value} disabled={disabled} size={size} />
        ))}
      </Group>
    </Checkbox.Group>
  )
}

export default MultiCheckbox
