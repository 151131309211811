import { useParams } from 'react-router-dom'
import { Button } from '@mantine/core'

import { PageHeader } from 'components/PageHeader/PageHeader'
import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Form from 'components/Form/Form'

import sales from 'api/sales'

const ProductsPage = () => {
  const { id } = useParams()

  const resolve = usePreload(async () => {
    const [fields, product] = await Promise.all([
      sales.getSalesforceDefinitions('Product2'), //
      sales.getProductById(id),
    ])

    return { product, fields }
  })

  const onSubmit = (values) => {
    console.log({ values })
  }

  const { product, fields } = resolve?.data || {}

  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Products', href: '/products' }, { label: 'Product - #' + (product?.ProductCode || product?.productCode) }]

  return (
    <Preload {...resolve}>
      <PageHeader title="Product details" breadcrumbs={breadcrumbs}></PageHeader>

      <div className="container2">
        <Form data={product} inputs={fields} onSubmit={onSubmit} className="columns-2">
          <div>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </Preload>
  )
}

export default ProductsPage
