import { useState } from 'react'
import { Text, Textarea, Button } from '@mantine/core'
import {observer} from 'mobx-react'
import Basket from '../Basket/Basket'
import TermsView from '../Terms/View/TermsView'
import Address from '../Address/Address'

import css from './Preview.module.css'

const Preview = ({ basket, quote, customer, onSubmit }) => {
  const [message, setMessage] = useState()

  return (
    <div className={css.Preview}>
      <Address quote={quote} customer={customer} readOnly />
      <TermsView terms={quote?.terms} />

      <div>
        {/* <h4>Send message</h4> */}
        {/* <Textarea autosize resize="both" value={message} onChange={({ target }) => setMessage(target.value)} minRows={6} w={600} placeholder="Message..." /> */}
        <br />
        <div className="gap-05">
          <Button variant="outline" color="#0694a2" size="sm" radius="sm" onClick={() => onSubmit({ message })}>
            <Text size="xs">Finish onboarding</Text>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default observer(Preview)
