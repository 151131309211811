import { Button, NumberFormatter } from '@mantine/core'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'

import ProductsTable from 'screens/Products/ProductsTable'

import sales from 'api/sales'
import Spinner from 'components/Spinner/Spinner'

const ProductsModal = ({ basketStore, onAddToBasket, setShowModal }) => {
  const resolve = usePreload(sales.getProducts)

  const { data } = resolve
  if (!basketStore || !basketStore.bookStore || basketStore.bookStore.isLoading()) <Spinner />
  return (
    <Preload {...resolve}>
      {data && (
        <div>
          <ProductsTable basketStore={basketStore} {...data} onAddToBasket={onAddToBasket} limit={15} />
          <div className="gap-05">
            <Button
              variant="outline"
              onClick={() => {
                basketStore.revert()
                setShowModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setShowModal(false)
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      )}
    </Preload>
  )
}

export default ProductsModal
