import { Tooltip, Group, Text, Stack, Badge, Menu, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconChevronDown, IconFileTypePdf } from '@tabler/icons-react'
import { formatDistance } from 'date-fns'

import css from '../Thread.module.css'

import PdfPreview from '../../PdfPreview/PdfPreview'

const statuses = {
  initiated: { color: 'green', label: 'Initiated' },
  onboardingCompleted: { color: 'pink', label: 'Onboarding Completed' },
  customerReply: { color: 'cyan', label: 'Customer Reply' },
  quotation: { color: 'blue', label: 'Quotation' },
  quoteSigned: { color: 'green', label: 'Quote Signed' },
  orderSigned: { color: 'green', label: 'Order Signed' },
}

const TimelineTitle = (props) => {
  const [opened, { open, close }] = useDisclosure(false)
  const { customer, account, quote, item } = props
  const { _key, _id, ...restItem } = item
  const { createdBy, createdAt, from, version } = item
  const isCustomer = from === 'customer'
  const company = isCustomer ? customer : account

  const status = statuses[item.status] || { label: item.status }
  return (
    <Stack gap="xs">
      <Group>
        <Text c="gray.8">{company.name}</Text>

        {createdBy && (
          <Tooltip label={createdBy.email}>
            <Text size="sm">
              {createdBy.firstName} {createdBy.lastName}
            </Text>
          </Tooltip>
        )}
      </Group>
      <div className="flex">
        <Badge size="sm" color={status.color} variant={isCustomer && 'light'}>
          {status.label}
        </Badge>
        {version && (
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Badge color="orange" variant="outline" rightSection={<IconChevronDown size={14} />} className={css.versionBtn}>
                V {version}
              </Badge>
            </Menu.Target>

            <Menu.Dropdown className={css.dropdown}>
              <Menu.Label>Quote: V {version}</Menu.Label>
              <Menu.Item leftSection={<IconFileTypePdf size={16} />} className={css.item} onClick={open}>
                View PDF
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
        <time>{formatDistance(createdAt, new Date())} ago</time>

        <Modal
          opened={opened}
          onClose={close}
          title={
            <div className="flex">
              Quote #{quote._key}
              <Badge color="orange" variant="outline">
                V {version}
              </Badge>
            </div>
          }
          centered
          size="auto"
        >
          <PdfPreview {...props} quote={{ ...quote, ...restItem }} canSign={false} />
        </Modal>
      </div>
    </Stack>
  )
}

export default TimelineTitle
