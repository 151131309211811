import { useParams } from 'react-router-dom'
import { Button } from '@mantine/core'

import { PageHeader } from 'components/PageHeader/PageHeader'
import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Form from 'components/Form/Form'

import sales from 'api/sales'

const CustomersPage = () => {
  const { id } = useParams()

  const resolve = usePreload(async () => {
    const [fields, customer] = await Promise.all([
      sales.getSalesforceDefinitions('Account'), //
      sales.getCustomerByID(id),
    ])

    return { fields, customer }
  })

  const onSubmit = (values) => {
    onRetry(() => sales.updateCustomer(customer._key, values))
  }

  const { onRetry, data } = resolve
  const { customer, fields } = data || {}

  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Customers', href: '/customers' }, { label: 'Customer - #' + (customer?.Name || customer?._key) }]

  return (
    <Preload {...resolve}>
      <PageHeader title="Customer details" breadcrumbs={breadcrumbs}></PageHeader>

      <div className="container2">
        <Form data={customer} inputs={fields} onSubmit={onSubmit} className="columns-2">
          <div>
            <Button type="submit">Submit</Button>
          </div>
        </Form>

        <br />
        <br />
        <br />
      </div>
    </Preload>
  )
}

export default CustomersPage
