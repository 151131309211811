import { Link } from 'react-router-dom'
import { NumberFormatter } from '@mantine/core'

import Table from 'components/Table/Table'

import AddToBasket from '../Opportunities/Page/Thread/SuggestedProducts/AddToBasket'
import { observer } from 'mobx-react'
import BasketStore from 'stores/BasketStore'
import { SearchOptions } from 'utils/TableSearcher'

const renderMap = {
  ProductCode: ({ ProductCode }) => <small className="rounded outline w-600">{ProductCode}</small>,
  Name: ({ Name, _key }) => <Link to={`/products/${_key}`}>{Name}</Link>,
  // sellingPrice: ({ sellingPrice, currency = '$' }) => (
  //   <NumberFormatter prefix={`${currency} `} value={sellingPrice} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
  // ),
  msrp: ({ msrp, currency }) => <NumberFormatter prefix={`${currency} `} value={msrp} thousandSeparator decimalScale={2} fixedDecimalScale={false} />,
  photos: ({ photos }) => (
    <div className="thumbs">
      {photos.map((photoUrl, key) => (
        <img src={photoUrl} key={key} height="50px" alt="Product" />
      ))}
    </div>
  ),
  attributes: () => null,
}

const ProductsTable = ({ basketStore, labels = [], products, limit, searchOptions }:
  { basketStore: BasketStore, labels: any[], products: any[], limit: number, searchOptions?: SearchOptions }) => {
  let knownColumns: any[] = []
  let restColumns: any[] = []

  if (products) {
    let productLabels = products[0]
      ? Object.entries(products[0])
        .filter(([k, v]) => !k.startsWith('_') && (v == null || !(typeof v === 'object' && !renderMap[k])))
        .map(([k, v]) => k)
      : []
    knownColumns = labels
      .filter(({ value, hidden }) => {
        const found = productLabels.includes(value)
        if (found) {
          productLabels = productLabels.filter((name) => name !== value)
        }
        return found && !hidden
      })
      .map(({ value, label, hidden }) => {
        const col: any = {
          title: label,
          ellipsis: '200px',
        }
        const render = renderMap[value]
        if (render) {
          col.render = render
        } else {
          col.key = value
        }
        return col
      })

    restColumns = productLabels.map((name) => {
      // return { title: name, key: name, ellipsis: '200px' }
      const col: any = {
        title: name,
        ellipsis: '200px',
      }
      const render = renderMap[name]
      if (render) {
        col.render = render
      } else {
        col.key = name
      }
      return col
    })
  }

  const columns = [
    ...knownColumns,
    basketStore && {
      title: 'Quantity',
      textAlign: 'right',
      render: (product) => {
        const basketDetails = basketStore.getBasketLineForProduct(product._key) || { quantity: 0 }
        return <AddToBasket quantity={basketDetails.quantity} canEdit={true} onUpdateQuantity={(q) => basketStore.updateProductQuantity(product, q)} />
      }
    },
    ...restColumns,
  ].filter(Boolean)

  return <Table data={products} columns={columns} rowKey="_key" limit={limit} displayExtendedMenu={true} searchOptions={searchOptions} />
}

export default observer(ProductsTable)
