import { makeAutoObservable, runInAction } from 'mobx'
import backend from '../api/backend'
import { nan } from 'zod'

type PricebookEntry = {
  _key: string
  _id: string
  productId: string
  unitPrice: number
}

type Pricebook = {
  _key: string
  _id: string
  name: string
  description: string
  productCount: number
  isDefault: boolean
  createdAt: string
  updatedAt: string
  isActive: boolean
}

type PricebookWithEntries = Pricebook & { entries: PricebookEntry[] }

export class BooksStore {
  static instance: BooksStore | null = null
  books: { [k: string]: any } = {}
  priceBooks: Pricebook[] = []
  costBooks: Pricebook[] = []
  waitCounter: number = 0
  error = null
  defaultPricebook: Pricebook | null | undefined = null
  defaultCostbook: Pricebook | null | undefined = null
  selectedPricebook: Pricebook | null | undefined
  selectedCostbook: Pricebook | null | undefined
  pricebookEntries: { [pricebookKey: string]: PricebookWithEntries } = {}
  isCustomerView: boolean = false

  private static initializing: Promise<BooksStore> | null = null

  private constructor(isCustomerView: boolean = false) {
    makeAutoObservable(this)
    this.isCustomerView = isCustomerView
  }

  static init() {
    if (!BooksStore.instance) {
      if (!BooksStore.initializing) {
        BooksStore.initializing = (async () => {
          const store = new BooksStore()
          await store.fetchBooks()
          BooksStore.instance = store
          BooksStore.initializing = null
          return store
        })()
      }
      return BooksStore.initializing
    }
    return Promise.resolve(BooksStore.instance)
  }

  fetchBooks = async () => {
    runInAction(() => {
      this.error = null
      this.waitCounter++
    })
    try {
      const data = await backend.getPriceAndCostBooks()
      const books = (Array.isArray(data) ? data : (data.data as any[])).filter((book) => (this.isCustomerView ? book.isPricebook : true))
      this.assignBooks(books)
      await this.fetchEntries4BooksInUse()
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Failed to fetch books'
      })
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }

  assignBooks = (data: any[] = []) => {
    const pBooks: Pricebook[] = []
    const cBooks: Pricebook[] = []
    runInAction(() => {
      data.forEach((book) => {
        this.books[book._id] = book
        if (book.isDefault) {
          if (book.isPricebook) {
            this.defaultPricebook = book
          } else {
            this.defaultCostbook = book
          }
        }
        const bookData: Pricebook = {
          _key: book._key,
          _id: book._id,
          name: book.name || book.Name || '',
          description: book.description || book.Description || '',
          createdAt: this.formatDate(book.createdAt || book.CreatedDate),
          updatedAt: this.formatDate(book.updatedAt || book.SystemModstamp),
          productCount: book.productCount || 0,
          isActive: book.isActive || book.IsActive || false,
          isDefault: book.isDefault || false,
        }

        if (book.isPricebook || book.attributes?.type === 'Pricebook2') {
          pBooks.push(bookData)
        } else if (book.isCostbook) {
          cBooks.push(bookData)
        }
      })

      this.priceBooks = pBooks.sort((a, b) => Number(b.isDefault) - Number(a.isDefault) || a.name.localeCompare(b.name))
      this.costBooks = cBooks.sort((a, b) => Number(b.isDefault) - Number(a.isDefault) || a.name.localeCompare(b.name))
      if (!this.defaultPricebook) this.defaultPricebook = this.priceBooks.at(0) || null
      if (!this.defaultCostbook) this.defaultCostbook = this.costBooks.at(0) || null
    })
  }

  setSelectedBooks = (pricebookId?: string, costbookId?: string) => {
    runInAction(() => {
      this.selectedPricebook = this.priceBooks.find((book) => book._id === pricebookId)
      this.selectedCostbook = this.costBooks.find((book) => book._id === costbookId)
    })
  }

  getSelectedPricebookPrice = (productId: string): number => {
    if (!this.selectedPricebook) return NaN
    const price =
      this.pricebookEntries[this.selectedPricebook?._key]?.entries.find((entry) => entry.productId === productId)?.unitPrice || NaN
    return price
  }

  getSelectedCostbookPrice = (productId: string): number => {
    if (!this.selectedCostbook) return NaN
    return this.pricebookEntries[this.selectedCostbook?._key]?.entries.find((entry) => entry.productId === productId)?.unitPrice || NaN
  }

  fetchEntries4BooksInUse = async () => {
    runInAction(() => {
      this.waitCounter++
    })
    try {
      const booksEntriesToRetrieve = Array.from(
        new Set([this.selectedPricebook?._key, this.selectedCostbook?._key, this.defaultPricebook?._key, this.defaultCostbook?._key])
      ).filter((key) => key) as string[]
      if (booksEntriesToRetrieve.length > 0) {
        await this.fetchPricebookEntries(booksEntriesToRetrieve)
      }
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Failed to fetch pricebook entries'
      })
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }

  fetchPricebookEntries = async (pricebookKeys: string[]) => {
    const pbsToFetch = pricebookKeys.filter((key) => !this.pricebookEntries[key])
    if (pbsToFetch.length === 0) return

    runInAction(() => {
      this.error = null
      this.waitCounter++
    })

    try {
      const response: any = await backend.getPriceBookEntries(pbsToFetch)
      if (!response) return null
      runInAction(() => {
        response.forEach((pb) => {
          this.pricebookEntries[pb._key] = pb
        })
      })
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || `Failed to fetch pricebook entries for [${pbsToFetch.join(', ')}]`
      })
      return []
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }

  getPricebookEntries = (pricebookKey: string) => {
    return this.pricebookEntries[pricebookKey]?.entries || []
  }

  formatDate = (dateString) => {
    if (!dateString) return ''
    const date = new Date(dateString)
    return isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0]
  }

  getBook = (bookId: string) => {
    return this.books[bookId]
  }

  createBook = async (book) => {
    runInAction(() => {
      this.error = null
      this.waitCounter++
    })
    try {
      await backend.createNewBook(book)
      await this.fetchBooks()
      return true
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Failed to create book'
      })
      return false
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }

  get isLoading() {
    return this.waitCounter > 0
  }

  deleteBook = async (bookId) => {
    runInAction(() => {
      this.waitCounter++
      this.error = null
    })
    try {
      await backend.deleteBook(bookId)
      await this.fetchBooks()
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Failed to delete book'
      })
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }

  setDefaultBook = async (book) => {
    runInAction(() => {
      this.error = null
      this.waitCounter++
    })

    try {
      const bookType = book.isPricebook ? 'pricebook' : 'costbook'
      await backend.markBookAsDefault({
        key: book._id,
        bookType,
      })

      runInAction(() => {
        if (bookType === 'pricebook') {
          this.priceBooks.forEach((b) => (b.isDefault = false))
          const newDefault = this.priceBooks.find((b) => b._id === book._id)
          if (newDefault) {
            newDefault.isDefault = true
            this.defaultPricebook = newDefault
          }
        } else {
          this.costBooks.forEach((b) => (b.isDefault = false))
          const newDefault = this.costBooks.find((b) => b._id === book._id)
          if (newDefault) {
            newDefault.isDefault = true
            this.defaultCostbook = newDefault
          }
        }
      })
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Failed to set default book'
      })
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }
}
