import { formatDistance } from 'date-fns'
import { Accordion, ActionIcon, Group, Text } from '@mantine/core'
import { IconBrandCrunchbase, IconBrandFacebook, IconBrandLinkedin, IconBrandTwitter, IconInfoSquareRounded } from '@tabler/icons-react'

//@ts-ignore
import css from '../CompanyAside.module.css'

const CompanyDetails = ({
  companyInfo: {
    website, //
    createdAt,
    linkedinProfile,
    profiles,
    twitter,
    industry,
    locationSummary,
    founded,
    employeeCount,
    shortDescription,
  },
}) => {
  const socialMediaProfiles = (
    <div className={css.socialProfiles}>
      {(profiles?.length > 0 || linkedinProfile || twitter) && (
        <Group style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} justify="space-around">
          {linkedinProfile && (
            <ActionIcon
              component="a"
              href={linkedinProfile}
              target="_blank"
              rel="noopener noreferrer"
              size="md"
              variant="subtle"
              color="blue.8"
            >
              <IconBrandLinkedin size={20} stroke={1} />
            </ActionIcon>
          )}
          {twitter && (
            <ActionIcon component="a" href={twitter} target="_blank" rel="noopener noreferrer" size="md" variant="subtle" color="blue.8">
              <IconBrandTwitter size={20} stroke={1} />
            </ActionIcon>
          )}
          {profiles?.map((profile, index) => {
            const url = profile.startsWith('http') ? profile : `https://${profile}`

            // Skip LinkedIn profiles if we already have linkedinProfile
            if (profile.includes('linkedin.com') && linkedinProfile) {
              return null
            }
            if (profile.includes('facebook.com')) {
              return (
                <ActionIcon
                  key={index}
                  component="a"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="md"
                  variant="subtle"
                  color="blue.9"
                >
                  <IconBrandFacebook size={20} stroke={1} />
                </ActionIcon>
              )
            } else if (profile.includes('crunchbase.com')) {
              return (
                <ActionIcon
                  key={index}
                  component="a"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="md"
                  variant="subtle"
                  color="blue.9"
                >
                  <IconBrandCrunchbase size={20} stroke={1} />
                </ActionIcon>
              )
            } else if (profile.includes('linkedin.com')) {
              return (
                <ActionIcon
                  key={index}
                  component="a"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="md"
                  variant="subtle"
                  color="blue.8"
                >
                  <IconBrandLinkedin size={20} stroke={1} />
                </ActionIcon>
              )
            } else if (profile.includes('twitter.com') || profile.includes('x.com')) {
              return (
                <ActionIcon
                  key={index}
                  component="a"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="md"
                  variant="subtle"
                  color="blue.8"
                >
                  <IconBrandTwitter size={20} stroke={1} />
                </ActionIcon>
              )
            }
            return null
          })}
        </Group>
      )}
    </div>
  )

  return (
    <Accordion.Item value="details">
      <Accordion.Control>
        <Group wrap="nowrap">
          <IconInfoSquareRounded size={20} stroke={1} />
          <Text>Details</Text>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <ul className={css.list}>
          <li>
            Website:{' '}
            <Group>
              <a style={{ textDecoration: 'none', cursor: 'pointer' }} href={website} target="_blank" rel="noopener noreferrer">
                {website}
              </a>
              {socialMediaProfiles}
            </Group>
          </li>
          {shortDescription && (
            <li>
              <span>{shortDescription}</span>
            </li>
          )}
          {employeeCount && (
            <li>
              Employee count: <b>{employeeCount}</b>
              {founded && (
                <>
                  Founded in: <b>{founded}</b>
                </>
              )}
            </li>
          )}
          {locationSummary && (
            <li>
              Location: <b>{locationSummary}</b>
            </li>
          )}
          {industry && (
            <li>
              Industry: <b>{industry}</b>
            </li>
          )}
          <li>
            Known since: <b>{formatDistance(new Date(createdAt), new Date(), { addSuffix: true })}</b>
          </li>
        </ul>
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default CompanyDetails
