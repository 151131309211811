import { useState, useEffect, useMemo, useCallback } from 'react';
import { Accordion, Checkbox, NumberInput, Group, Text, Box, JsonInput, Stack, Pill, Radio, Select, NumberFormatter, SelectProps, Switch, Badge } from '@mantine/core';
import { Edge, Feature, FeatureItem, HierarchyProduct, ProductSelection } from './CPQTypes';
import { ProductEdgeType, BundleProductConstraints, Field } from '@broadn/commons'
import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import classNames from 'classnames';
import backend from 'api/backend'
import React from 'react';
import css from './ProductHierarchy.module.css';
import { BundleState } from '../../stores/BundleState';
import { observer } from 'mobx-react-lite';



const ProductHierarchy = observer(({ bundleState }: { bundleState: BundleState }) => {
  return (
    <Stack
      gap="md"
      className='productHierarchy'
      style={{
        height: '100%',
        minHeight: 'calc(100vh - 60px)'
      }}
    >
      <Group className={css.stickyHeader} p="xs" align="center">
        {bundleState.pricebooks.length > 0 && (
          <Select
            description="Select the active pricebook"
            data={bundleState.pricebooks}
            value={bundleState.selectedPricebook}
            onChange={(pricebookId) => pricebookId && bundleState.loadPricebookEntries(pricebookId)}
            variant="filled"
            allowDeselect={false}
            searchable
            checkIconPosition="left"
            maxDropdownHeight={400}
            size="xs"
            radius="xs"
            w={230}
            classNames={{
              description: css.selectStyle
            }}
          />
        )}

        {bundleState.selectedBundle && (
          <Stack gap="0" align='flex-end'>
            <Text size='xs' c="#ffffffaa">Bundle</Text>
            <Text fw={500} size="xs">
              {bundleState.allProducts.get(bundleState.selectedBundle)?.ProductCode}
            </Text>
          </Stack>
        )}

        <Stack gap="0" align='flex-end'>
          <Text size='xs' c="#ffffffaa">Product count</Text>
          <Text size='xs'>{bundleState.selectedProducts.size}</Text>
        </Stack>

        <Stack gap="0" align='flex-end'>
          <Text size='xs' c="#ffffffaa">Total</Text>
          <Text size='xs'>
            <NumberFormatter
              prefix="$ "
              thousandSeparator
              fixedDecimalScale={false}
              decimalScale={2}
              value={bundleState.totalPrice}
            />
          </Text>
        </Stack>

        <Stack gap="0" align='flex-end'>
          <Text
            size='xs'
            c={bundleState.selectionStats.productsRequired.length <= 1 ? "#ffffffaa" : "orange.6"}
          >
            # Required
          </Text>
          <Text
            size='sm'
            c={bundleState.selectionStats.productsRequired.length <= 1 ? "white" : "orange.6"}
          >
            <NumberFormatter
              thousandSeparator
              fixedDecimalScale={false}
              value={bundleState.selectionStats.productsRequired.length}
            />
          </Text>
        </Stack>

        <Stack gap="0" align='flex-end'>
          <Text size='xs' c="#ffffffaa"># Restricted</Text>
          <Text size='xs'>
            <NumberFormatter
              thousandSeparator
              fixedDecimalScale={false}
              value={bundleState.selectionStats.productsRestricted.length}
            />
          </Text>
        </Stack>

        <Stack gap="0" align='flex-end'>
          <Text size='xs' c="#ffffffaa">Product attributes?</Text>
          <Switch
            color='teal'
            size="xs"
            checked={bundleState.showProductAttributes}
            onChange={() => bundleState.toggleProductAttributes()}
          />
        </Stack>
      </Group>

      <Box p="md">
        <Radio.Group

          onChange={(value) => bundleState.toggleProductSelection(value)}
        >
          {bundleState.topProducts.map((item: string) => (
            <ProductNode
              key={item}
              productId={item}
              isTopLevel={true}
              bundleState={bundleState}
            />
          ))}
        </Radio.Group>
      </Box>
    </Stack>
  );
});

const ProductNode = observer(({
  productId,
  isTopLevel,
  bundleState
}: {
  productId: string,
  isTopLevel?: boolean,
  bundleState: BundleState
}) => {
  const product = bundleState.allProducts.get(productId);
  if (!product) return null;

  const featureCount = product.features?.length || 0;
  const featureProducts = product.features?.reduce((acc, fi) => acc + fi.featureToProductEdges.length, 0);
  const isBundle = (featureProducts > 0) || (product.directSubproducts || []).length > 0;
  const isSelected = bundleState.selectedProducts.has(product._id);

  const otherSubproductsNodes = product.directSubproducts?.map(pid => (
    <ProductNode
      key={pid}
      productId={pid}
      isTopLevel={false}
      bundleState={bundleState}
    />
  ));

  return (
    <Box m="2">
      {isBundle ? (
        <Box>
          <Group gap="xs" py={4}>
            {isTopLevel ? (
              <Radio
                value={product._id}
                onSelect={(e) => {
                  e.stopPropagation();
                  bundleState.toggleProductSelection(product._id);
                }}
                size="sm"
              />
            ) : (
              <Checkbox
                defaultChecked={isSelected}
                onChange={(e) => {
                  e.stopPropagation();
                  bundleState.toggleProductSelection(product._id);
                }}
                variant={isSelected ? "default" : "outline"}
                size="sm"
              />
            )}
            {/* <Pill size="xs" variant="default" c="gray">bundle</Pill> */}
            <Text fw={500} size="sm">{product.ProductCode}</Text>
            <Text size="sm">{product.Name}</Text>
            {product.ProductFamily && <Text size="sm">({product.ProductFamily})</Text>}
            {bundleState.showProductAttributes && (
              <Badge size="xs" variant="default" c="gray.6">
                <Text fw={300} size="8" c="gray.5">
                  {product.Id}
                </Text>
              </Badge>
            )}
            <IndicatorGroup product={product} bundleState={bundleState} />
            {!isSelected && (
              <Text size="sm" c="dimmed" pl="md">
                {`${featureCount} Features, ${featureProducts} Products, ${(product.directSubproducts || []).length} SubProducts`}
              </Text>
            )}
          </Group>

          {isSelected && (
            <Box pl="md" py={4}>
              {product.features?.map((featureItem: FeatureItem) => (
                <FeatureNode
                  key={featureItem.feature._key}
                  featureItem={featureItem}
                  bundleState={bundleState}
                />
              ))}

              <Box pl="md" py={4}>
                <Text size="xs" c="dimmed">
                  Others
                </Text>
                {otherSubproductsNodes}
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <LeafProduct
          product={product}
          bundleState={bundleState}
        />
      )}
    </Box>
  );
});

const FeatureNode = observer(({
  featureItem,
  bundleState
}: {
  featureItem: FeatureItem,
  bundleState: BundleState
}) => {
  const featureToProductEdges = featureItem.featureToProductEdges
    .map((fpe) => {
      const product = bundleState.allProducts.get(fpe._to);
      if (!product) return null;

      return {
        ...product,
        isRequired: fpe.options.isRequired,
        quantityConfig: {
          default: fpe.options.quantityConfig.default || 0,
          min: fpe.options.quantityConfig.min || 0,
          max: fpe.options.quantityConfig.max || Infinity,
          quantityEditable: fpe.options.quantityConfig.quantityEditable,
          priceEditable: fpe.options.quantityConfig.priceEditable,
          priceDecimals: fpe.options.quantityConfig.priceDecimals || 0
        }
      } as any;
    }).filter(p => p);

  return (
    <Box m="xs">
      <Text size="sm" fw={500} mb="sm">{featureItem.feature.Name}</Text>
      <Box pl="md">
        {featureToProductEdges.map((p) => (
          <ProductNode
            key={p._id}
            productId={p._id}
            isTopLevel={false}
            bundleState={bundleState}
          />
        ))}
      </Box>
    </Box>
  );
});

const LeafProduct = observer(({
  product,
  bundleState
}: {
  product: HierarchyProduct,
  bundleState: BundleState
}) => {
  const isSelected = bundleState.selectedProducts.has(product._id);
  const { isExcluded, isDependency, isRequiredBundled, isRequiredNonBundled, isOptionalSelectedBundled, isOptionalSelectedNonBundled } = bundleState.getProductAttributes(product._id)
  const isRequired = isRequiredBundled || isRequiredNonBundled;

  const currentQuantity = isSelected ?
    (bundleState.productQuantities[product._id]?.quantity || 0) : 0;

  const productOtherAttributes = useMemo(() => {
    if (!bundleState.showProductAttributes) return null;
    return Object.entries(product)
      .filter(([k, v]) => {
        return k.endsWith('__c') && !k.startsWith('SBQQ__') && !!v;
      })
      .concat([['ProductCode', product.ProductCode || product.productCode]])
      .map(([k, v]) => {
        const keyLabel = bundleState.productFieldDefinitions.get(k);
        return [keyLabel ? keyLabel.label : k, v];
      })
      .map(([k, v]) => (
        <Stack gap={0} align='flex-end' key={k}>
          <Text size="xs" c="gray.5">{k}</Text>
          <Text size="xs" c="gray.6">{`${v}`}</Text>
        </Stack>
      ))
  }, [product, bundleState])

  return (
    <Stack gap={0}>
      <Group w={'100%'} justify='space-between'>
        <Group gap="xs">
          <Checkbox
            checked={isSelected}
            onChange={() => {
              if (!isRequired) {
                bundleState.toggleProductSelection(product._id);
              }
            }}
            variant={!isRequired ? "outline" : "default"}
            color={isRequired ? "gray.4" : "blue"}
            disabled={isExcluded || isRequired}
            size="sm"
          />
          <Text fw={isRequired ? 400 : 300} size="sm">
            {product.Name || product.name}
          </Text>
          <Text fw={isRequired ? 400 : 300} size="sm">
            {product.Description || product.description}
          </Text>
          <IndicatorGroup product={product} bundleState={bundleState} />
          {bundleState.showProductAttributes && (
            <Badge size="xs" variant="default" c="gray.6">
              <Text fw={300} size="8" c="gray.5">
                {product.Id}
              </Text>
            </Badge>
          )}
        </Group>
        <Group gap="xs">
          <NumberInput
            value={currentQuantity}
            onChange={(value) => bundleState.updateProductQuantity(product._id, Number(value))}
            min={product.quantityConfig?.min}
            max={product.quantityConfig?.max || Infinity}
            clampBehavior="strict"
            disabled={!product.quantityConfig?.quantityEditable}
            style={{ width: '80px' }}
            size="xs"
          />
          <NumberInput
            value={bundleState.productQuantities[product._id]?.price ||
              bundleState.pricebookEntries.get(product._id)?.UnitPrice || 0}
            onChange={(value) => bundleState.updateProductPrice(product._id, Number(value))}
            disabled={!product.quantityConfig?.priceEditable}
            style={{ width: '100px' }}
            size="xs"
            thousandSeparator
            allowDecimal={true}
            leftSection={"$"}
            decimalScale={product.quantityConfig?.priceDecimals}
          />
        </Group>
      </Group>
      {bundleState.showProductAttributes && (
        <Group gap="sm" pl={'md'} mb='xs'>
          {productOtherAttributes}
        </Group>
      )}
    </Stack>
  );
});

const IndicatorGroup = observer(({
  product,
  bundleState
}: {
  product: HierarchyProduct,
  bundleState: BundleState
}) => {

  const indicators: any[] = useMemo(() => {
    const { isExcluded, isDependency, isRequiredBundled, isRequiredNonBundled, isOptionalSelectedBundled, isOptionalSelectedNonBundled } = bundleState.getProductAttributes(product._id)
    const isRequired = isRequiredBundled || isRequiredNonBundled;

    const indicatorArr: any[] = []
    if (isExcluded) indicatorArr.push(<Pill size="xs" variant="default" c="red.6" key={`${product._id}-restricted`}>restricted</Pill>)
    if (isRequired) indicatorArr.push(<Pill size="xs" variant="default" c="red.6" key={`${product._id}-required`}>req</Pill>)
    if (isRequiredBundled) indicatorArr.push(<Pill size="xs" variant="default" c="gray.6" key={`${product._id}-bundled`}>bundled</Pill>)
    if (isRequiredNonBundled) indicatorArr.push(<Pill size="xs" variant="default" c="violet.6" key={`${product._id}-reqNonBundled`}>reqNonBundled</Pill>)
    if (isOptionalSelectedBundled || isOptionalSelectedNonBundled) indicatorArr.push(<Pill size="xs" variant="default" c="teal.6" key={`${product._id}-optional`}>optional</Pill>)
    if (isDependency) indicatorArr.push(<Pill size="xs" variant="default" c="orange.6" key={`${product._id}-ReqOthers`}>ReqOthers</Pill>)
    return indicatorArr
  }, [product, bundleState, bundleState.selectedProducts])

  return indicators.length > 0 ? <Group gap="xs" key={`${product._id}-indicators-group`}>
    {indicators}
  </Group> : null
})
export default ProductHierarchy
