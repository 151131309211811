import { ActionIcon, Group, NumberFormatter } from '@mantine/core'
import { IconDownload, IconTrashX } from '@tabler/icons-react'
import { saveAs } from 'file-saver'
import { formatDistance } from 'date-fns'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import opportunitiesApi from 'api/opportunities'

const icons = {
  txt: 'FileExtension/txt-icon.svg',
  pdf: 'FileExtension/pdf-icon.svg',
  doc: 'FileExtension/doc-icon.svg',
  ppt: 'FileExtension/ppt-icon.svg',
  gif: 'FileExtension/gif-icon.svg',
  xlsx: 'FileExtension/xsl-icon.svg',
  jpg: 'FileExtension/jpg-icon.svg',
  jpeg: 'FileExtension/jpeg-icon.svg',
  png: 'FileExtension/png-icon.svg',
  zip: 'FileExtension/zip-icon.svg',
  html: 'FileExtension/html-icon.svg',
}

type FilesProps = {
  _key?: string
  [key: string]: any
}

const Files = ({ _key, customer, account, urlKey }: FilesProps) => {
  const resolve = usePreload(async () => {
    const files = (await opportunitiesApi.getFiles(_key, urlKey)) as any
    const groupped = {}
    files.forEach((item) => {
      const by = item.party
      groupped[by] = groupped[by] || []
      groupped[by].push(item)
    })

    return groupped
  })

  const { data, onRetry } = resolve

  const download = async (id, fileId, fileName) => {
    const file = await opportunitiesApi.downloadFile(id, fileId, urlKey)
    saveAs(file.data, fileName)
  }

  const columns = (canDelete) => [
    {
      textAlign: 'center',
      style: { width: '50px' },
      render: ({ fileName }) => <img src={'/' + icons[fileName.split('.').pop()]} width="32px" alt={fileName} />,
    },
    {
      label: 'File name',
      render: ({ title, fileName }) => (
        <div>
          <div>
            <b>{title}</b>
          </div>
          <div className="desc">{fileName}</div>
        </div>
      ),
    },
    {
      label: 'Size',
      render: ({ size }) => (
        <NumberFormatter suffix=" KB" value={size / 1024} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
      ),
    },
    {
      label: 'Created at',
      render: ({ createdAt }) => <time dateTime={createdAt}>{formatDistance(new Date(createdAt), new Date(), { addSuffix: true })}</time>,
    },
    {
      render: (item, { showConfirm }) => (
        <Group justify="end">
          <ActionIcon variant="light" onClick={() => download(_key, item._key, item.fileName)}>
            <IconDownload size={18} stroke={1.5} />
          </ActionIcon>
          {canDelete && (
            <ActionIcon
              variant="light"
              color="red"
              onClick={() => showConfirm(() => onRetry(() => opportunitiesApi.deleteFile(_key, item._key, urlKey)))}
            >
              <IconTrashX size={18} stroke={1.5} />
            </ActionIcon>
          )}
        </Group>
      ),
    },
  ]

  const create = {
    label: 'Upload file',
    form: [
      { label: 'Title', name: 'title' },
      { label: 'File', name: 'file', type: 'file', accept: ['pdf', 'xlsx', 'ppt', 'jpg', 'jpeg', 'doc', 'png'] },
    ],
    onSubmit: ({ file, ...body }) => onRetry(() => opportunitiesApi.uploadFile(_key, file, body, urlKey)),
  }

  return (
    <Preload {...resolve}>
      <div>
        <Table data={data?.account} label={account?.name} columns={columns(!urlKey)} create={!urlKey && create} />
        <Table data={data?.customer} label={customer?.name} columns={columns(!!urlKey)} create={urlKey && create} />
      </div>
    </Preload>
  )
}

export default Files
