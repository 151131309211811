import { observer } from 'mobx-react'
import { Button, NumberFormatter, NumberInput, Text } from '@mantine/core'

import css from '../Thread.module.css'

const AddToBasket = ({ quantity = 0, canEdit = true, onUpdateQuantity, style }: 
  { quantity: number, canEdit: boolean, onUpdateQuantity: (quantity: number) => void, style?: React.CSSProperties }
) => {
  if (!canEdit) {
    return (
      <NumberFormatter
        thousandSeparator
        value={quantity || 0}
      />
    )
  }

  return quantity ? (
    <div className={css.quantity}>
      <NumberInput
        value={quantity}
        min={0}
        size="xs"
        thousandSeparator
        styles={{
          input: {
            outline: '1px solid var(--mantine-color-blue-outline)',
            outlineOffset: '-1px',
            textAlign: 'right',
            paddingRight: '22px',
          },
          controls: {
            border: '0 0 0 1px',
            borderWidth: '1px',
            borderColor: 'var(--mantine-color-blue-outline)',
            outlineOffset: '-1px',
          },
        }}
        onChange={(value) => {
          if (value === 0) {
            onUpdateQuantity(0)
          } else {
            // const diff = value - activeQty
            onUpdateQuantity(Number(value))
          }
        }}
      />
    </div>
  ) : (
    <div className={css.quantity}>
      <Button size="xs" variant="outline" onClick={() => onUpdateQuantity(1)} w={'100%'}>
        Add
      </Button>
    </div>
  )
}

export default observer(AddToBasket)
