import { makeAutoObservable, runInAction, toJS } from 'mobx'
import Basket from '../../Basket/Basket'
import TermsView from '../../Terms/View/TermsView'
import BasketStore, { initBasket } from '../../../../../stores/BasketStore'

import css from './Timeline.module.css'

const TimelineContent = ({ customer, item, status }) => {
  if (!item) return null
  const { message, basket, terms, version } = item
  const staticBasket = BasketStore.fromSnapshot(item)
  return (
    <div>
      <p className={css.pre}>{message}</p>
      {item.basket && <Basket basketStore={staticBasket} label="Historical quote" canEdit={false} />}
      {terms && <TermsView options={terms} />}
    </div>
  )
}

export default TimelineContent
