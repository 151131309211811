import { useState } from 'react'
import { Button, Group, Text } from '@mantine/core'
import { IconAdjustments, IconArrowsUpDown, IconDownload, IconSettings, IconChevronDown, IconColumns } from '@tabler/icons-react'

import css from './ActionHeader.module.css'

const ActionHeader = ({ onColumnsChange, hiddenColumns, columns, triggerExport, isExporting = false }:
  { onColumnsChange: (hiddenColumns: Record<string, boolean>) => void, hiddenColumns: Record<string, boolean>, columns: any[], triggerExport?: () => void, isExporting: boolean }) => {
  const [open, setOpen] = useState(false)
  const visible = columns.filter((col) => !hiddenColumns[col.title])

  return (
    <Group>
      <Group ml={0} pl={0} pr={4} pt={2} pb={2}>
        <div className={css.ddButton}>
          <Button variant="default" leftSection={<IconColumns size={12} />} size="compact-sm" radius="sm" onClick={() => setOpen(!open)}>
            <Text size="xs">Columns ({visible.length})</Text>
          </Button>

          {open && <div className={css.closeBg} onClick={() => setOpen(false)} />}
          {open && (
            <ul>
              {columns.map(({ title }, key) => (
                <li key={key}>
                  <label>
                    <input
                      type="checkbox"
                      value={title}
                      checked={!hiddenColumns[title]}
                      onChange={({ target }) => {
                        onColumnsChange({ ...hiddenColumns, [title]: !target.checked })
                      }}
                    />
                    {title}
                  </label>
                </li>
              ))}
            </ul>
          )}
        </div>

        <Button variant="default" leftSection={<IconAdjustments size={12} />} size="compact-sm" radius="sm">
          <Text size="xs">Filters</Text>
        </Button>

        <Button variant="default" leftSection={<IconArrowsUpDown size={12} />} size="compact-sm" radius="sm">
          <Text size="xs">Sort</Text>
        </Button>
      </Group>

      <Group mr={0} pl={4} pr={0} pt={2} pb={2}>
        <Button variant="default" 
        loading={isExporting}
        disabled={isExporting}
        leftSection={<IconDownload size={12} />} size="compact-sm" radius="sm" rightSection={<IconChevronDown size={12} />}
          onClick={async () => triggerExport && triggerExport()}
        >
          <Text size="xs">Export</Text>
        </Button>

        <Button variant="default" size="compact-sm" radius="sm" leftSection={<IconSettings size={12} />}>
          <Text size="xs">View Settings</Text>
        </Button>
      </Group>
    </Group>
  )
}

export default ActionHeader
