import TrieSearch from 'trie-search'

export type SearchOptions = {
  key: string
  indexColumns: string[]
  minSearchLength?: number
  splitOnRegEx?: RegExp
}

export class TableSearcher {
  private trie: any
  private options: SearchOptions
  private data: Record<string, any>[]

  constructor(options: SearchOptions) {
    this.options = {
      minSearchLength: 2,
      ...options,
    }
    this.trie = new TrieSearch(this.options.indexColumns, {
      ignoreCase: true,
      min: this.options.minSearchLength,
      idFieldOrFunction: this.options.key,
      splitOnRegEx: this.options.splitOnRegEx || /[\s.\-\/?!]/g,
    })
    this.data = []
  }

  setData(data: Record<string, any>[]): this {
    this.data = [...data]
    this.trie.reset()
    this.trie.addAll(this.data)
    return this
  }

  search(query: string): Record<string, any>[] {
    if (!query || query.length < (this.options.minSearchLength || 2)) {
      return this.data
    }

    return this.trie.search(query)
  }

  static search(data: Record<string, any>[], query: string, options: SearchOptions): Record<string, any>[] {
    return new TableSearcher(options).setData(data).search(query)
  }
}
