import { useState } from 'react'
import TableCell from '../Cell/Cell'
import cn from 'classnames'

import css from './Row.module.css'

const TableRow = ({ row, columns, index }) => {
  const [content, setContent] = useState()

  return (
    <>
      <tr className={cn(css.tr, content && css.active)}>
        {columns.map((column, key) => (
          <TableCell key={key} row={row} column={column} setContent={setContent} />
        ))}
      </tr>
      {content && (
        <tr className={css.trContent}>
          <td colSpan={columns.length}>{content(row, { setContent, close: () => setContent(null) })}</td>
        </tr>
      )}
    </>
  )
}

export default TableRow
