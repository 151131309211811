import { useEffect, useState, useMemo } from 'react'
import { Button, Group, Card, Text, Stack, CopyButton, Tooltip, ActionIcon, rem, Loader } from '@mantine/core'
import { IconKey, IconCheck, IconCopy } from '@tabler/icons-react'

import sales from 'api/sales'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'

const ApiKey = ({ edge, onCompleted = () => {} }) => {
  const [loading, setLoading] = useState(false)
  const [settings, setSettingsData] = useState(null)
  const { origin } = window.location

  const handleGenerateKey = async () => {
    setLoading(true)
    try {
      const result = await sales.createApiKey()
      setSettingsData(result)
      if (onCompleted) onCompleted(result)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!edge) return
    setSettingsData(edge)
  }, [edge])

  console.log('Apikey.edge', settings, edge)

  const iframeSrc = useMemo(
    () =>
      settings && settings.apiKey
        ? `<div id="BroadnEnquiry"></div>

<link rel="stylesheet" href="${origin}/cdn/broadn-client.css">
<script type="module" src="${origin}/cdn/broadn-client.js"></script>
<script>window.BROADN = { xApiKey: '${settings.apiKey}' }</script>
`
        : '',
    [settings]
  )

  return (
    <Stack>
      <Group align="center" justify="space-around">
        <Button
          variant="default"
          size="sm"
          radius="sm"
          onClick={handleGenerateKey}
          leftSection={loading ? <Loader size="xs" /> : <IconKey stroke={1} size={16} />}
          disabled={loading}
        >
          {loading ? 'Generating...' : 'Generate new web integration script'}
        </Button>
      </Group>

      {settings?.apiKey && (
        <Stack gap="1em">
          <Stack gap="0">
            <Text size="sm" margin="sm" fw={500}>
              Script.ID:
            </Text>
            <Card padding="lg" radius="md" withBorder>
              <Group align="center" justify="center">
                <Text size="xs" fw={500} c="green.8">
                  {settings.apiKey}
                </Text>
              </Group>
            </Card>
          </Stack>
          <Stack gap="0">
            <Text size="sm" margin="sm" fw={500}>
              Intergration:
            </Text>
            <CopyButton value={iframeSrc} timeout={2000}>
              {({ copied, copy }) => (
                <Card padding="lg" radius="md" withBorder>
                  <Group align="start" justify="end" style={{ position: 'absolute', top: '8px', right: '8px' }}>
                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="top">
                      <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                        {copied ? <IconCheck style={{ width: rem(16) }} /> : <IconCopy style={{ width: rem(16) }} />}
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                  <Group align="center" justify="center">
                    <Text size="xs" fw={500} c="green.8">
                      <code>{iframeSrc}</code>
                    </Text>
                  </Group>
                </Card>
              )}
            </CopyButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default ApiKey
