import { observer } from 'mobx-react-lite'
import { Accordion, Avatar, Badge, Group, Button } from '@mantine/core'
import { Link } from 'react-router-dom'
import { IconMapPin, IconInfoSquareRounded } from '@tabler/icons-react'
import { formatDistance } from 'date-fns'

import Preload, { usePreload } from '../PreLoad/PreLoad'
import sales from 'api/sales'
import { OpportunityStore } from 'stores/OpportunityStore'

//@ts-ignore
import css from './CompanyAside.module.css'

import CompanyDetails from './Sections/CompanyDetails'
import PriceAndCostBooks from './Sections/ProceAndCostBooks'

const CompanyAside = observer(({ opportunityStore }: { opportunityStore: OpportunityStore }) => {
  const resolve = usePreload(() => {
    if (opportunityStore.opportunity?.customer?._key) {
      return sales.getCustomerInfo(opportunityStore.opportunity?.customer?._key)
    }
  })

  if (!opportunityStore || !opportunityStore.opportunity || resolve.isLoading || !resolve.data) return null
  const companyInfo = resolve.data as any

  const {
    logo,
    hqLocation,
    city,
    name,
    description,
    representatives,
    shippingAddress = {},
    opportunities,
    opportunitiesCount,
  } = companyInfo

  return (
    <Preload {...resolve} className={css.layout}>
      <div className={css.header}>
        <h3 className="flex">
          <Avatar variant="outline" size="lg" src={logo} alt={`Logo ${name}`} />
          {name}
        </h3>
        <div className={css.subTitle}>{description}</div>
        {hqLocation && (
          <div className={css.city}>
            <IconMapPin />
            {hqLocation || city}
          </div>
        )}
      </div>

      <Accordion
        className="acordionAside"
        multiple={true}
        defaultValue={['details', 'books', 'shippingAddress', 'representatives', 'opportunities', 'orders']}
      >
        <CompanyDetails companyInfo={companyInfo} />
        <PriceAndCostBooks opportunityStore={opportunityStore} />

        <Accordion.Item value="shippingAddress">
          <Accordion.Control icon={<IconInfoSquareRounded size={20} stroke={1.5} />}>Shipping Address</Accordion.Control>
          <Accordion.Panel>
            <ul className={css.list}>
              <li>
                City: <b>{shippingAddress.ShippingCity}</b>
              </li>
              <li>
                Street: <b>{shippingAddress.ShippingStreet}</b>
              </li>
              <li>
                Postcode: <b>{shippingAddress.ShippingPostalCode}</b>
              </li>
              <li>
                Country: <b>{shippingAddress.ShippingCountry}</b>
              </li>
            </ul>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="representatives">
          <Accordion.Control icon={<IconInfoSquareRounded size={20} stroke={1.5} />}>Representatives</Accordion.Control>
          <Accordion.Panel>
            <ul className={css.list}>
              {representatives &&
                Array.from(Object.values(representatives)).map(({ firstName, lastName, email }: any) => (
                  <li key={email} className={opportunityStore.opportunity?.createdBy?.email === email && css.active}>
                    <Avatar variant="outline" color="blue" name={firstName + ' ' + lastName} />
                    <div className={css.user}>
                      <div>
                        {firstName} {lastName}
                      </div>
                      <small>{email}</small>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="opportunities">
          <Accordion.Control icon={<IconInfoSquareRounded size={20} stroke={1.5} />}>
            <Group>
              Opportunities
              <Badge radius="md" color="teal">
                {opportunitiesCount}
              </Badge>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <ul className={css.list}>
              {Array.from(opportunities).map(({ _key, status, createdAt }: any) => {
                return (
                  <li key={`order-${_key}`}>
                    {formatDistance(createdAt, new Date(), { addSuffix: true })}
                    <Link to={`/opportunities/${_key}`}>
                      <Button size="compact-xs" variant="outline" color="var(--teal-600)">
                        Open {status}
                      </Button>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </Accordion.Panel>
        </Accordion.Item>

        {/* <Accordion.Item value="orders">
          <Accordion.Control icon={<IconInfoSquareRounded size={20} stroke={1.5} />}>
            <Group>
              Orders
              <Badge radius="md" color={ordersCount ? 'orange' : 'gray'}>
                {ordersCount}
              </Badge>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <ul className={css.list}>
              {Array.from(orders).map((o: any) => {
                return (
                  <li key={`order-${o._key}`}>
                    {formatDistance(o.createdAt, new Date(), { addSuffix: true })}
                    <Button component={Link} to={`/o2c/orders/${o._key}`} size="compact-xs" variant="outline" color="var(--teal-600)">
                      Open {o.status}
                    </Button>
                  </li>
                )
              })}
            </ul>
          </Accordion.Panel>
        </Accordion.Item> */}
      </Accordion>
    </Preload>
  )
})

export default CompanyAside
