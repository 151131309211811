import { PageHeader } from '../../components/PageHeader/PageHeader'

import EnquiryForm from './Form/Form'

const Enquiry = () => {
  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Enquiry' },
  ]

  return (
    <div className="container">
      <PageHeader title="Enquiry" breadcrumbs={breadcrumbs} />
      <EnquiryForm />
    </div>
  )
}

export default Enquiry
