import http, { client } from 'api/http'

class Prompt {
  constructor() {}

  getUser = (id) => client.get(`/admin/users/${id}`)
  updateUser = (id, body) => client.put(`/admin/users/${id}`, body)

  async getMyPrompts() {
    return client.get('/prompts/my')
  }

  async getPrompt(id) {
    return client.get(`/prompts/${id}`)
  }

  async executePrompt(executePromptCommand) {
    if (http.hasValidToken()) {
      return client.post('/prompts/execute', executePromptCommand)
    }
    if (executePromptCommand.public) {
      return client.post('/prompts/executePublicPrompt', executePromptCommand)
    } else {
      global.location.replace(`${global.location.origin}/login?target=/p/${executePromptCommand.promptId}`)
    }
  }
}

export default new Prompt()
