import React, { useState, useRef, useEffect } from 'react'
import { Button, Group, Box } from '@mantine/core'

interface DelayedActionButtonProps {
  children: React.ReactNode
  onAction: () => void
  delayInSeconds: number
  className?: string
}

export const DelayedActionButton: React.FC<DelayedActionButtonProps> = ({ children, onAction, delayInSeconds, className }) => {
  const [isPending, setIsPending] = useState(false)
  const [countdown, setCountdown] = useState(delayInSeconds)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const clearTimers = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    if (intervalRef.current) clearInterval(intervalRef.current)
  }

  const handleCancel = () => {
    clearTimers()
    setIsPending(false)
  }

  const handleAction = () => {
    setIsPending(true)
    setCountdown(delayInSeconds)
    timeoutRef.current = setTimeout(() => {
      onAction()
      setIsPending(false)
    }, delayInSeconds * 1000)

    intervalRef.current = setInterval(() => {
      setCountdown((prev) => {
        const newValue = prev - 0.05
        return newValue > 0 ? +newValue.toFixed(2) : 0
      })
    }, 50)
  }

  useEffect(() => () => clearTimers(), [])

  return (
    <Box className={className}>
      <Group mt="2" gap={2} color="orange.0">
        <Box onClick={!isPending ? handleAction : undefined}>{children}</Box>
        {isPending ? (
          //   <Text bg="orange.0" p="xs" c="dark" size="xs" p={3} m={1}>
          //     Action confirmed in {countdown.toFixed(0)}s unless cancelled
          //   </Text>
          <Button p={2} variant="filled" color="orange.0" size="xs" onClick={handleCancel}>
            Undo {countdown.toFixed(0)}s
          </Button>
        ) : null}
      </Group>
    </Box>
  )
}

export default DelayedActionButton
