import { useParams } from 'react-router-dom'

import { PageHeader } from 'components/PageHeader/PageHeader'
import Preload, { usePreload } from 'components/PreLoad/PreLoad'

import emailTemplates from 'api/emailTemplates'

const EmailsPage = () => {
  const { name } = useParams()
  const resolve = usePreload(() => emailTemplates.getOne(name))

  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Email templates', href: '/admin/emails' },
    { label: name },
  ]

  const { data } = resolve
  return (
    <Preload {...resolve}>
      <PageHeader title="Email" breadcrumbs={breadcrumbs} />

      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <div dangerouslySetInnerHTML={{ __html: data?.body }}></div>
    </Preload>
  )
}

export default EmailsPage
