import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Text, Tabs, Group, Button } from '@mantine/core'
import { useSearchParams } from 'react-router-dom'
import { OpportunityStore } from '../../../stores/OpportunityStore'
import StatusSections from 'components/StatusSections/StatusSections'

// @ts-ignore
import css from './OpportunitiesPage.module.css'

import BasketStore from 'stores/BasketStore'
import Spinner from 'components/Spinner/Spinner'

import Basket from './Basket/Basket'
import TermsView from './Terms/View/TermsView'
import TermsForm from './Terms/Form/TermsForm'

import Address from './Address/Address'
import Thread from './Thread/Thread'
import PdfPreview from './PdfPreview/PdfPreview'

import Files from './Files/Files'

const statusList = [
  { status: 'new', label: 'New Inquiry' },
  { status: 'quotation', label: 'Quotation' },
  { status: 'orderCreated', label: 'Order created' },
  { status: 'signed', label: 'Signed' },
  { status: 'shipping', label: 'Shipping' },
  { status: 'completed', label: 'Completed' },
]

const OpportunityContent = observer(({ opportunityStore, urlKey }: { opportunityStore: OpportunityStore; urlKey?: string }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get('tab') || 'thread'

  if (!opportunityStore || opportunityStore.isLoading) return <Spinner />

  const opportunity = opportunityStore.opportunity
  const { status, quote, order, customer, settings } = opportunity as any

  const isOrder = !!order
  const availableTabs = [isOrder ? 'order' : 'quote', 'thread', 'terms', 'address', 'pdf', 'dataRoom']
  let activeTab = isOrder ? 'order' : 'thread'
  if (availableTabs.includes(tab)) {
    activeTab = tab
  }

  const current = isOrder ? order : quote

  return (
    <section className={css.Section}>
      <div className={css.header}>
        <StatusSections list={statusList} status={status || 'draft'} />
      </div>

      <div className={css.content}>
        <Tabs keepMounted={false} color="teal" defaultValue={activeTab} onChange={(tab) => setSearchParams({ tab: tab || 'thread' })}>
          <Tabs.List>
            <Tabs.Tab value="thread">Conversation thread</Tabs.Tab>
            <Tabs.Tab value="address" ml="auto">
              Company info
            </Tabs.Tab>
            <Tabs.Tab value="dataRoom">DataRoom</Tabs.Tab>
            {!isOrder && <Tabs.Tab value="terms">Terms</Tabs.Tab>}

            {current?.basket && <Tabs.Tab value="pdf">Documents</Tabs.Tab>}

            {quote?.basket && !order && (
              <Tabs.Tab value="quote" disabled={!quote?.basket?.length}>
                Quote ({quote?.basket?.length} products)
              </Tabs.Tab>
            )}

            {isOrder && <Tabs.Tab value="order">Order ({order?.basket?.length} items)</Tabs.Tab>}
          </Tabs.List>

          <Tabs.Panel value="address">
            <h4>Company info</h4>
            <Address quote={quote} customer={customer} readOnly />
          </Tabs.Panel>

          {quote?.basket && !order && (
            <Tabs.Panel value="quote">
              <Basket basketStore={BasketStore.fromSnapshot(quote)} label="Quote" canEdit={false} />
            </Tabs.Panel>
          )}

          {isOrder && (
            <Tabs.Panel value="order">
              <Basket basketStore={BasketStore.fromSnapshot(order)} label="Order" canEdit={false} />
              <TermsView terms={quote?.terms} />
            </Tabs.Panel>
          )}

          <Tabs.Panel value="terms">
            <TermsView terms={current?.terms} />
            <TermsForm onSubmit={(selectedTerms) => console.log({ selectedTerms })} list={settings?.availableTerms} terms={quote?.terms}>
              <Group justify="center" mt="xl">
                <Button type="submit" variant="outline" color="blue" size="sm" radius="sm">
                  <Text size="xs">Submit</Text>
                </Button>
              </Group>
            </TermsForm>
          </Tabs.Panel>

          <Tabs.Panel value="thread">
            <Thread opportunityStore={opportunityStore} key={opportunityStore.opportunity?._key || ''} />
          </Tabs.Panel>

          <Tabs.Panel value="pdf">
            {/* @ts-ignore */}
            <PdfPreview urlKey={urlKey} type={isOrder ? 'order' : 'quote'} opportunityStore={opportunityStore} {...opportunity} />
            {/* <PdfPreview {...data} onRetry={onRetry} urlKey={urlKey} type={isOrder ? 'order' : 'quote'} /> */}
          </Tabs.Panel>

          <Tabs.Panel value="dataRoom">
            <Files {...opportunity} urlKey={urlKey} />
          </Tabs.Panel>
        </Tabs>
      </div>
    </section>
  )
})

export default OpportunityContent
