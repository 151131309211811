import { PageHeader } from 'components/PageHeader/PageHeader'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'

import ProductsTable from './ProductsTable'

import sales from 'api/sales'

const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Products' }]
const Products = (props) => {
  const resolve = usePreload(sales.getProducts)

  const { data } = resolve
  const searchOptions = {
    key: 'id',
    indexColumns: ['Description', 'Brand', 'Name'],
    minSearchLength: 3
  }
  return (
    <div style={{ height: 'calc(100vh - 10px)', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <PageHeader title="Products" breadcrumbs={breadcrumbs}></PageHeader>
      <div style={{ flex: 1 }}>
        <Preload {...resolve}>{data && <ProductsTable {...props} {...data} limit={100} searchOptions={searchOptions} />}</Preload>
      </div>
    </div>
  )
}

export default Products
