export class Settings {
  requestCategories() {
    return {
      rfqAttributes: {
        paymentTerms: {
          id: 'paymentTerms',
          description: 'Payment Terms',
          defaultValue: 'cod',
          options: [
            {
              label: 'Upfront Payment',
              value: '0',
              units: 'days',
              description: 'Full payment is required before the goods entering manufacturing or processing.',
            },
            {
              label: 'COD - Cash on Delivery',
              value: 'cod',
              units: 'days',
              description: 'Full payment is required before the goods or services are delivered.',
            },
            {
              label: 'Net 30',
              value: '30',
              units: 'days',
              description: 'Payment due within 30 days of invoice.',
            },
            {
              label: 'Net 45',
              value: '45',
              units: 'days',
              description: 'Payment due within 30 days of invoice.',
            },
            {
              label: 'Net 60',
              value: '60',
              units: 'days',
              description: 'Payment due within 60 days of invoice.',
            },
          ],
        },
        deliveryAndShippingTerms: {
          id: 'deliveryAndShippingTerms',
          description: 'Delivery and shipping terms',
          defaultValue: 'standard',
          options: [
            {
              label: 'Customer Pickup',
              value: 'customerPickup',
              description: 'Customer will pick up the order at the designated location specified by the seller.',
            },
            {
              label: 'Standard national delivery',
              value: 'standard',
              description: 'National shipment and delivery of goods in 5-10 business days.',
            },
            {
              label: 'FOB (Free on Board)',
              description: "Buyer is responsible for shipping costs and risks once goods leave the seller's facility.",
              value: 'fob',
            },
            {
              label: 'Ex Works (EXW)',
              value: 'exw',
              description: 'Buyer is responsible for all shipping and handling costs from the seller’s location.',
            },
            {
              label: 'CIF (Cost, Insurance, and Freight)',
              value: 'cif',
              description: "Seller covers the cost, insurance, and freight to the buyer's destination.",
            },
            {
              label: 'DDP (Delivered Duty Paid)',
              value: 'ddp',
              description: 'Seller assumes all responsibility for delivery, including shipping, insurance, and duties.',
            },
          ],
        },
        validityPeriodForRfq: {
          id: 'validityPeriodForRfq',
          description: 'Validity terms for RFQ',
          defaultValue: '10',
          options: [
            {
              label: '10 Days',
              description: 'The quote is valid for 10 days from the date of issuance.',
              value: '10',
              units: 'days',
            },
            {
              label: '30 Days',
              description: 'The quote is valid for 30 days from the date of issuance.',
              value: '30',
              units: 'days',
            },
            {
              label: '60 Days',
              description: 'The quote is valid for 60 days from the date of issuance.',
              value: '60',
              units: 'days',
            },
            {
              label: '90 Days',
              description: 'The quote is valid for 90 days from the date of issuance.',
              value: '90',
              units: 'days',
            },
            {
              label: 'Custom Period',
              value: 'custom',
              units: 'days',
              description: 'The validity period can be customized based on specific requirements.',
            },
          ],
        },
        manufacturingAndDeliveryLeadTime: {
          id: 'manufacturingAndDeliveryLeadTime',
          description: 'Manufacturing and delivery lead time',
          defaultValue: '2',
          options: [
            {
              label: 'Immediately',
              value: '0',
              units: 'days',
              description: 'Goods or services are available immediately.',
            },
            {
              label: '2 Weeks',
              description: 'Goods or services will be manufactured and delivered within 2 weeks.',
              value: '2',
              units: 'weeks',
            },
            {
              label: '4 Weeks',
              description: 'Goods or services will be manufactured and delivered within 4 weeks.',
              value: '4',
              units: 'weeks',
            },
            {
              label: '6 Weeks',
              description: 'Goods or services will be manufactured and delivered within 6 weeks.',
              value: '6',
              units: 'weeks',
            },
            {
              label: '8+ Weeks',
              description: 'Lead time may exceed 8 weeks, especially for custom or complex orders.',
              value: '8',
              units: 'weeks',
            },
          ],
        },
        discountsAndInclusions: {
          id: 'discountsAndInclusions',
          description: 'Discounts and inclusions',
          defaultValue: 'noDiscount',
          options: [
            {
              label: 'No Discount',
              discountPercentage: 0,
              value: 'noDiscount',
              conditions: 'No discount is applicable.',
              description: 'The offer is valid for its full price.',
              units: 'days',
            },
            {
              label: 'First Time Buyer Discount',
              discountPercentage: 5,
              value: 'firstTime5',
              conditions: 'Applicable for first-time customers.',
              description: '5% discount for first-time buyers.',
            },
            {
              label: 'Volume Discount',
              discountPercentage: 10,
              value: 'volume10',
              conditions: 'For orders above 100 units.',
              description: '10% discount on orders exceeding 100 units.',
            },
            {
              label: 'Loyalty Discount',
              discountPercentage: 5,
              value: 'loyalty5',
              conditions: 'For returning customers.',
              description: '5% discount for returning customers.',
            },
            {
              label: 'Promotional Discount',
              discountPercentage: 15,
              value: 'promotional15',
              conditions: 'Valid during the promotional period.',
              description: '15% discount during our seasonal promotion.',
            },
            {
              label: 'Early Payment Discount',
              discountPercentage: 4,
              value: 'earlyPayment4',
              conditions: 'If payment is made within 10 days.',
              units: 'days',
              description: '2% discount if payment is made within 10 days.',
            },
          ],
        },
        warrantyAndReturnPolicy: {
          id: 'warrantyAndReturnPolicy',
          description: 'Warranty and return policy',
          defaultValue: '1',
          options: [
            {
              label: 'Standard Warranty',
              description: 'Product is covered under warranty for 1 year from the date of delivery.',
              value: '1',
              units: 'year',
            },
            {
              label: 'Extended Warranty',
              value: '2',
              units: 'years',
              description: 'Product is covered under warranty for 2 years from the date of delivery.',
            },
            {
              label: 'No Warranty',
              value: '0',
              description: 'No warranty is provided for this product.',
            },
          ],
        },
      },
      paymentMethods: {
        id: 'paymentMethods',
        description: 'Payment methods',
        options: [
          {
            label: 'Bank Transfer',
            description: 'Payment via electronic bank transfer.',
            value: 'bankTransfer',
          },
          {
            label: 'International Bank Transfer',
            description: 'Payment via international electronic bank transfer.',
            value: 'international_bank_transfer',
          },
          {
            label: 'BACS',
            description: "Payment via BACS (Bankers' Automated Clearing System).",
            value: 'bacs',
          },
          {
            label: 'Debit and Credit Card',
            description: 'Payment via debit or credit card.',
            value: 'debitAndCreditCard',
          },
          {
            label: 'Amex',
            description: 'Payment via American Express card.',
            value: 'amex',
          },
          {
            label: 'ACH Bank Payments',
            description: 'Payment via ACH (Automated Clearing House) bank payments.',
            value: 'achBankPayments',
          },
          {
            label: 'Stripe',
            description: 'Payment via Stripe payment platform.',
            value: 'stripe',
          },
          {
            label: 'Adyen',
            description: 'Payment via Adyen payment platform.',
            value: 'adyen',
          },
          {
            label: 'PayPal',
            description: 'Payment via PayPal.',
            value: 'paypal',
          },
          {
            label: 'Venmo',
            description: 'Payment via Venmo.',
            value: 'venmo',
          },
          {
            label: 'JCS',
            description: 'Payment via JCS (Japan Credit Bureau) card.',
            value: 'jcs',
          },
        ],
      },
    }
  }

  get = (name, value) => {
    const { rfqAttributes } = this.requestCategories()
    const item = rfqAttributes[name]
    if (item) {
      const selected = item.options.find((v) => v.value === value)
      return { ...item, selected }
    }

    return {}
  }
}

const settings = new Settings()
export default settings
