import { Modal, Button, Group } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconLibraryPlus } from '@tabler/icons-react'

import Form from 'components/Form/Form'

export type CreateType = {
  label?: string
  form?: any[]
  onSubmit?: (values: any) => any
}

const Create = ({ label, form, onSubmit = () => null }: CreateType) => {
  const [opened, { open, close }] = useDisclosure(false)

  return (
    <div>
      <Group>
        <Button color="teal" rightSection={<IconLibraryPlus stroke={1} />} variant="subtle" size="xs" onClick={open}>
          {label}
        </Button>
      </Group>

      <Modal size="lg" opened={opened} onClose={close} title={label}>
        <Form
          inputs={form}
          data={{}}
          onSubmit={(values) => {
            onSubmit(values)
            close()
          }}
        >
          <Group mt="lg">
            <Button type="submit" size="xs">
              Save
            </Button>
            <Button variant="outline" onClick={close} size="xs">
              Cancel
            </Button>
          </Group>
        </Form>
      </Modal>
    </div>
  )
}

export default Create
