import { PageHeader } from 'components/PageHeader/PageHeader'
import { useEffect } from 'react'
import { observer } from 'mobx-react'
import opportunitiesStore from '../../stores/opportunitiesStore'

import OpportunitiesTable from './OpportunitiesTable'

const Opportunities = () => {
  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Opportunities' }]
  useEffect(() => {
    opportunitiesStore.fetchOpportunities()
  }, [])

  return (
    <div>
      <PageHeader title="Opportunities" breadcrumbs={breadcrumbs} />
      <OpportunitiesTable />
    </div>
  )
}

export default observer(Opportunities)
