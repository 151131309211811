import settings from 'api/settings'

import css from './TermsView.module.css'

const TermsView = ({ terms }) => {
  if (terms) {
    return (
      <div>
        <h4>Delivery, payment and terms</h4>
        <div className={css.terms}>
          <table>
            <tbody>
              {terms.map(({ label, name, selected }) => {
                return (
                  <tr key={name}>
                    <td>
                      <b>{label}</b>
                    </td>
                    <td>
                      <div>
                        {selected?.label} {selected?.units}
                      </div>
                      <div className={css.desc}>{selected?.description}</div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default TermsView
