import { useForm } from '@mantine/form'
import { TextInput, Switch, ActionIcon, Text, Button } from '@mantine/core'
import { randomId } from '@mantine/hooks'
import { IconTrash } from '@tabler/icons-react'

import css from './Account.module.css'

const EditTerms = ({ item, onCancel, onSubmit }) => {
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      ...item,
      options: item.options.map((option) => ({ ...option, key: randomId(), defaultValue: item.defaultValue === option.value })),
    },
  })

  const onSave = () => {
    const values = form.getValues()
    const defaultItem = values.options.find(({ defaultValue }) => defaultValue)
    values.defaultValue = defaultItem?.value
    values.options.forEach((option) => {
      delete option.key
      delete option.defaultValue
    })

    onSubmit(values)
  }

  const fields = form.getValues().options.map((item, index) => (
    <div className={css.Section} key={item.key}>
      <div className={css.Inputs}>
        <TextInput
          label="Label"
          placeholder="Label"
          withAsterisk
          key={form.key(`options.${index}.label`)}
          {...form.getInputProps(`options.${index}.label`)}
        />

        <TextInput
          label="Description"
          placeholder="Description"
          withAsterisk
          key={form.key(`options.${index}.description`)}
          {...form.getInputProps(`options.${index}.description`)}
        />

        <div className="flex-1">
          <TextInput
            label="Value"
            placeholder="Value"
            withAsterisk
            key={form.key(`options.${index}.value`)}
            {...form.getInputProps(`options.${index}.value`)}
          />

          <TextInput
            label="Units"
            placeholder="Units"
            key={form.key(`options.${index}.units`)}
            {...form.getInputProps(`options.${index}.units`)}
          />

          <div className={css.DefaultValue}>
            <Switch
              label="Default"
              key={form.key(`options.${index}.defaultValue`)}
              {...form.getInputProps(`options.${index}.defaultValue`, { type: 'checkbox' })}
            />
          </div>
        </div>
      </div>
      <ActionIcon color="red" onClick={() => form.removeListItem('options', index)}>
        <IconTrash size={16} />
      </ActionIcon>
    </div>
  ))

  return (
    <div className={css.Content}>
      {fields.length === 0 && (
        <Text c="dimmed" ta="center">
          No one here...
        </Text>
      )}

      <div>
        <TextInput label="Label" placeholder="Label" withAsterisk key={form.key(`label`)} {...form.getInputProps(`label`)} />
        <TextInput label="Name" placeholder="Name" withAsterisk key={form.key(`name`)} {...form.getInputProps(`name`)} />
      </div>

      <h4>Options</h4>
      {fields}

      <div className={css.AddOption}>
        <Button
          variant="subtle"
          onClick={() =>
            form.insertListItem('options', { value: undefined, label: '', description: '', units: undefined, key: randomId() })
          }
        >
          Add option
        </Button>
      </div>

      <div className={css.Actions}>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onSave}>Save</Button>
      </div>
    </div>
  )
}

export default EditTerms
