import FormItem from '../FormItem'

import css from '../Form.module.css'

const Group = ({ label, form, items, readOnly, className }) => {
  return (
    <div className={css.Group}>
      <h4 onDoubleClick={() => console.log(label, { items, className, readOnly })}>{label}</h4>
      <div className={className}>
        {items?.map((item, key) => (
          <FormItem form={form} {...item} key={key} readOnly={readOnly} />
        ))}
      </div>
    </div>
  )
}

export default Group
