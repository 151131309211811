import get from 'lodash.get'
import camelCase from 'lodash.camelcase'

import getDom from './jsdom'

import text from './actions/text'
import showIf from './actions/showIf'
import each from './actions/each'
import value from './actions/value'
import when from './actions/when'
import whenDefault from './actions/whenDefault'
import whenNot from './actions/whenNot'
import signature from './actions/signature'
import href from './actions/href'
import src from './actions/src'
import amend from './actions/amend'

class Dragon2 {
  constructor(source, answers, options) {
    this.source = source

    const { body, lang, label } = source

    this.template = body || source
    this.label = label || 'Default document'
    this.lang = lang || 'en'

    this.document = getDom(this.template)

    this.answers = answers
    this.options = options || {}

    console.time('--------- DRAGON #2 ---------')
    this.initCss()
    this.setRootData()

    this.html = this.create(this.template)

    console.timeEnd('--------- DRAGON #2 ---------')
  }

  signatures = []

  //------------------------------------------------------------------------------------------------------------------
  prefix = 'dr'
  availableAttributes = [
    'amend',
    'text',
    'secondary',
    'if',
    'when',
    'when-not',
    'field-name',
    'is',
    'data',
    'default',
    'value',
    'format',
    'signature',
    'behalf',
    'math',
    'href',
    'src',
    'each',
  ]
  root = {}
  amend = {}

  act = [showIf, value, whenDefault, when, whenNot, each, text, signature, href, src, amend]

  //------------------------------------------------------------------------------------------------------------------

  create(template) {
    const el = this.createEl('div', template)
    el.root = true
    el.$obj = { children: [], root: true }

    this.parse(el)
    this.renderSignature(true)

    return el
  }

  initCss() {
    const { style, pageStyle } = this.source

    this.pageStyle = pageStyle || { fontFamily: 'Arial', lineHeight: '1.2' }

    const { fontFamily, lineHeight } = this.pageStyle
    this.css = `.doc-style { 
      font-family: ${fontFamily}; 
      line-height: ${lineHeight}; 
    }`

    this.css += style && style.body
  }

  //------------------------------------------------------------------------------------------------------------------

  parse(el) {
    this.prepare(el)

    delete el.$obj.signature
    el.$obj.children.forEach((item) => this.init(item.el))
    return el
  }

  prepare(html) {
    const selector = this.act.map(({ name }) => `[${this.prefix}-${name}]`).join(', ')

    const foundElements = html.querySelectorAll(selector)

    foundElements.forEach((el) => {
      const parent = this.findFirstParent(el) || html
      const attributes = this.getAttributes(el)

      const { data, each } = attributes
      const name = data || each

      const obj = { el, parent: parent.$obj, children: [], attributes, name }

      el.$obj = obj
      parent.$obj.children.push(obj)
    })
  }

  //------------------------------------------------------------------------------------------------------------------
  // Recursive
  init(el) {
    if (el && el.$obj) {
      const { $obj } = el
      const { attributes = {}, children } = $obj

      const commentBody = Object.entries($obj.attributes).map(([name, val]) => `${name}=${val}`)
      const comment = this.document.createComment(' ' + commentBody.join(', ') + ' ')

      comment.$obj = $obj

      $obj.copy = el.cloneNode(true)
      $obj.comment = comment

      el.before(comment)

      this.act.forEach(({ name, onInit }) => onInit && attributes[name] !== undefined && onInit(el, this))
      this.act.find(({ name, render }) => attributes[name] !== undefined && render($obj.el, this))

      if (children && $obj.children === children) {
        $obj.children.forEach((item) => this.init(item.el))
      }
    }
  }

  rerender(el) {
    if (el && el.$obj) {
      const { attributes = {}, children } = el.$obj

      this.act.find(({ name, render }) => attributes[name] !== undefined && render(el, this))

      if (children && el.$obj.children === children) {
        children.forEach((item) => this.rerender(item.el))
      }
    }
  }

  update(values) {
    // console.time('UPDATE')
    this.answers = values
    this.signatures = []
    this.rerender(this.html)

    this.renderSignature()
    // console.timeEnd('UPDATE')
  }

  //------------------------------------------------------------------------------------------------------------------
  renderSignature(negotiation) {
    const bySearchStr = {}
    const byFieldName = {}

    if (typeof negotiation === 'object') {
      this.negotiation = negotiation
    }

    const haveChanges = !!this.signatures.find((item) => item.haveChanges)

    if (haveChanges || negotiation) {
      this.signatures.forEach((item) => {
        let { fieldName, searchStr } = item.signature
        fieldName = fieldName.slice(0, 40)
        byFieldName[fieldName] = byFieldName[fieldName] || []
        byFieldName[fieldName].push(item)

        bySearchStr[searchStr] = bySearchStr[searchStr] || []
        bySearchStr[searchStr].push(item)
      })

      Object.values(byFieldName).forEach((items) => {
        if (items.length > 1) {
          items.forEach(({ signature }, key) => {
            signature.fieldName += ' #' + (key + 1)
          })
        }
      })

      Object.values(bySearchStr).forEach((items) => {
        if (items.length > 1) {
          items.forEach(({ signature }, key) => {
            signature.searchStr += '#' + (key + 1)
          })
        }
      })

      this.signatures.forEach((item) => item.render())
    }
  }
  //------------------------------------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------------------------------------
  setRootData() {
    const negotiation = this.options.negotiation || this.options
    const { myParty, cpParty } = negotiation

    this.negotiation = negotiation
    this.rootData = { ...negotiation }

    if (myParty) {
      this.rootData[myParty.position] = myParty
    }

    if (cpParty) {
      this.rootData[cpParty.position] = cpParty
    }
  }

  //------------------------------------------------------------------------------------------------------------------
  createEl(tagName, content) {
    const el = this.document.createElement(tagName)
    el.innerHTML = content
    return el
  }

  //------------------------------------------------------------------------------------------------------------------
  findFirstParent(el) {
    const parent = el.parentNode

    if (parent.$obj) {
      return parent
    }

    return parent && this.findFirstParent(parent)
  }

  //------------------------------------------------------------------------------------------------------------------
  getAttributes = (el) => {
    const attributes = {}
    const { clearAttributes, once } = this.options

    this.availableAttributes.forEach((name) => {
      const attrName = this.prefix + '-' + name

      if (el.attributes[attrName]) {
        attributes[camelCase(name)] = el.getAttribute(attrName)

        if (clearAttributes || once) {
          el.removeAttribute(attrName)
        }
      }
    })
    return attributes
  }

  //------------------------------------------------------------------------------------------------------------------
  getSignatures() {
    // this.signatures = this.signatures.filter((item) => document.body.contains(item.el))
    return this.signatures.map(({ signature }) => signature)
  }

  //------------------------------------------------------------------------------------------------------------------
  // Recursive
  getName(obj, names = []) {
    const { name, parent } = obj
    if (name) {
      names.unshift(name)
    }

    return parent ? this.getName(parent, names) : names
  }

  //------------------------------------------------------------------------------------------------------------------
  getValue(obj, attr, val) {
    if (attr) {
      let name = obj.attributes[attr]

      if (name) {
        if (name[0] === '.') {
          const parentName = this.getName(attr === 'each' ? obj.parent : obj)

          if (parentName) {
            name = parentName.join('.').replace('..', '.') + (name === '.' ? '' : name)
          }
        }

        if (val) {
          name += '.' + val
        }

        const data = { ...this.answers, _: this.rootData }

        const value = name[0] === '$' ? get(obj.parent, name) : get(data, name)

        return value
      }
    }
  }
}

export default Dragon2
