import { useParams } from 'react-router-dom'

import { PageHeader } from 'components/PageHeader/PageHeader'
import { Preload, usePreload } from 'components/PreLoad/PreLoad'

import admin from 'api/admin'
import sales from 'api/sales'

import OnboardingSteps from './OnboardingSteps'

const breadcrumbs = [
  { label: 'Home', href: '/' }, //
  { label: 'Onboarding' },
]

const Onboarding = ({ role }) => {
  const { uid } = useParams()

  let getData = async () => {
    const account = await sales.getMyAccount()
    return { account }
  }

  let update = (data) => sales.updateMyAccount(data)

  if (role === 'admin') {
    getData = () => admin.getUser(uid)
    update = (data) => admin.updateAccount(uid, data)
  }

  const resolve = usePreload(getData)
  const { data } = resolve

  const onSave = (data) => {
    console.log(data)
    return update(data)
  }

  return (
    <div>
      <PageHeader title="Onboarding" breadcrumbs={breadcrumbs} />

      <Preload {...resolve}>
        <OnboardingSteps data={data} onSave={onSave} getData={getData} />
      </Preload>
    </div>
  )
}

export default Onboarding
