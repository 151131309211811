import { useState } from 'react'
import { Button, Text, Select, Modal, TextInput, Switch, Group } from '@mantine/core'
import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import BookModal from '../../Books/BookModal'
import css from './ExcelView.module.css'
import sales from 'api/sales'
import backend from 'api/backend'

const validate = {
  float: (value) => !isNaN(parseFloat(value)),
}

const ExcelView = ({ data, onSubmit, onClose, booksStore }) => {
  const [selected, setSelected] = useState({})
  const [invalidCol, setInvalidCol] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [bookType, setBookType] = useState('')
  const resolve = usePreload(sales.getProductLabels)
  const resolveBooks = usePreload(backend.getPriceAndCostBooks)

  if (resolve.isLoading || resolveBooks.isLoading) return

  const labelsNewBooks = [
    ...(resolveBooks.data || []).map((b) => {
      const isPricebook = b.isPricebook || b.type == 'Pricebook2'
      const value = ['book', isPricebook ? 'price' : 'cost', b._key].join('_')
      return {
        required: false,
        type: 'float',
        value,
        isCostRelated: b.isCostbook || false,
        isPriceRelated: b.isPricebook,
        bookId: b._id,
        bookKey: b._key,
        label: b.name || b.Name,
      }
    }),
  ]

  const allLabels = [...(resolve.data || []), ...labelsNewBooks]

  const sortedLabels = allLabels.sort((a, b) => a.value.localeCompare(b.value))
  const itemsObj = sortedLabels.reduce((buff, item) => {
    buff[item.value] = item
    return buff
  }, {})

  const required = sortedLabels.filter(({ required, isPriceRelated, isCostRelated }) => required && !isPriceRelated && !isCostRelated)
  const optional = sortedLabels.filter(({ required, isPriceRelated, isCostRelated }) => !required && !isPriceRelated && !isCostRelated)
  const pricingLabels = sortedLabels.filter(({ isPriceRelated }) => isPriceRelated)
  const costingLabels = sortedLabels.filter(({ isCostRelated }) => isCostRelated)

  const list = data.slice(0, 15)
  const labels = Object.keys(data[0])

  const onSelect = (name, label) => {
    if (name === 'newPricebook' || name === 'newCostbook') {
      setBookType(name === 'newPricebook' ? 'price' : 'cost')
      setModalOpen(true)
      return
    }

    let newSelected = Object.fromEntries(Object.entries(selected).filter(([n, v]) => v !== label))

    if (name) {
      const labelObj = sortedLabels.find(({ value }) => value === name)

      const validateFn = validate[labelObj.type]

      if (validateFn) {
        const totalInvalid = data.filter((row) => !validateFn(row[label]))
        setInvalidCol({ ...invalidCol, [label]: totalInvalid.length })
      }

      newSelected[name] = label
    } else {
      setInvalidCol({ ...invalidCol, [label]: false })
    }

    setSelected(newSelected)
  }

  const getItems = (items, currentLabel) =>
    items.map(({ value, label }) => ({ value, label, disabled: selected[value] && selected[value] !== currentLabel }))

  const isDisabled = () => {
    const sel = Object.keys(selected)
    const hasInvalid = Object.values(invalidCol).find((v) => v)

    return !!required.find(({ value }) => !sel.includes(value)) || hasInvalid
  }

  const refreshLabels = async () => {
    await resolve.reload()
  }

  return (
    <Preload {...resolveBooks}>
      <Preload {...resolve}>
        <div className={css.container}>
          <table className={css.table}>
            <thead>
              <tr>
                {labels.map((currentLabel, key) => (
                  <th
                    key={key}
                    onDoubleClick={() =>
                      console.log(123, {
                        currentLabel,
                        mandatory: getItems(required, currentLabel),
                        optional: getItems(optional, currentLabel),
                      })
                    }
                  >
                    <Select
                      label={currentLabel}
                      searchable
                      error={invalidCol[currentLabel] && invalidCol[currentLabel] + ' invalid rows'}
                      placeholder="Select attribute"
                      onChange={(value) => onSelect(value, currentLabel)}
                      data={[
                        {
                          group: 'Mandatory',
                          items: getItems(required, currentLabel),
                        },
                        {
                          group: 'Price books',
                          items: getItems(pricingLabels, currentLabel),
                        },
                        {
                          group: 'Cost books',
                          items: getItems(costingLabels, currentLabel),
                        },
                        {
                          group: 'Optional',
                          items: getItems(optional, currentLabel),
                        },
                      ]}
                      maxDropdownHeight={400}
                      // styles={{ dropdown: { width: '200px' } }}
                      comboboxProps={{ withinPortal: false, position: 'bottom-start', shadow: 'md', width: 300 }}
                      size="xs"
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {list.map((item, key) => (
                <tr key={key}>
                  {labels.map((label, i) => (
                    <td key={i} className={Object.values(selected).includes(label) ? css.selected : ''}>
                      {item[label]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <Text p=".5rem" size="sm">
            Total: {data.length}
          </Text>
        </div>
        <div className="gap-05">
          <Button
            onClick={() => {
              const labels = {}
              for (let name in selected) {
                const value = selected[name]
                labels[value] = itemsObj[name]
              }
              onSubmit(labels)
            }}
            color="blue"
            variant="outline"
            size="sm"
            radius="sm"
            disabled={isDisabled()}
          >
            Upload
          </Button>
          <Button onClick={onClose} size="sm" radius="sm" variant="default">
            Close
          </Button>
        </div>

        <BookModal
          opened={modalOpen}
          onClose={() => setModalOpen(false)}
          bookType={bookType}
          onSuccess={refreshLabels}
          booksStore={booksStore}
        />
      </Preload>
    </Preload>
  )
}

export default ExcelView
