import { PageHeader } from '../../components/PageHeader/PageHeader'

const Workflows = () => {
  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Documentation', href: '/docs' },
    { label: 'Workflows' },
  ]

  return (
    <div className="container2">
      <PageHeader title="Workflows" breadcrumbs={breadcrumbs} />
    </div>
  )
}

export default Workflows
