import { Button } from '@mantine/core'
import { useState } from 'react'
import { IconBriefcase, IconScale, IconTargetArrow, IconBallpen, IconWand } from '@tabler/icons-react'
import { SimpleGrid, Stack, Text } from '@mantine/core'
import { Page } from 'components/page'
import { PageHeader } from 'components/PageHeader/PageHeader'
import { MetricsAndBarChart } from './metrics-and-bar-chart'
import { MetricsAndIcons } from './metrics-and-icons'
import { MetricsAndListAndChart } from './metrics-and-list-and-chart'
import { MetricsAndProgressBar } from './metrics-and-progress-bar'
import './Dashboard.scss'

const breadcrumbs = [{ label: 'Metrics', href: '/' }, { label: 'KPIs' }]

const Dashboard = () => {
  return (
    <Page title="Order to cash metrics">
      <PageHeader title="Order to cash" breadcrumbs={breadcrumbs} />

      <Stack gap="sm" mb="xl">
        <SimpleGrid cols={{ base: 1, sm: 3, xl: 5 }}>
          <MetricsAndBarChart />
        </SimpleGrid>
      </Stack>

      <Stack gap="sm" mb="xl">
        <SimpleGrid cols={{ base: 1, sm: 4, xl: 6 }}>
          <MetricsAndProgressBar />
        </SimpleGrid>
      </Stack>

      <Stack gap="sm" mb="xl">
        <Text>Metrics + List + Chart</Text>
        <SimpleGrid cols={{ base: 1, sm: 2, xl: 2 }}>
          <MetricsAndListAndChart />
        </SimpleGrid>
      </Stack>
    </Page>
  )
}

export default Dashboard
