import { formatDistance } from 'date-fns'

// @ts-ignore
import css from './Account.module.css'

const AccountView = ({ data }) => {
  const {
    companyType,
    employeeRange,
    foundationDate,
    createdAt,
    website,
    linkedinProfile,
    hqLocation,
    industry,
    businessPhoneNumber,
    businessWhatsapp,
  } = data
  return (
    <section>
      <div>
        <ul className={css.list}>
          <li>
            Website:{' '}
            <a href={website} target="_blank" rel="noreferrer">
              {website}
            </a>
          </li>
          <li>
            Industry: <b>{industry}</b>
          </li>
          <li>
            Company type: <b>{companyType}</b>
          </li>
          <li>
            Employees: <b>{employeeRange}</b>
          </li>
          <li>
            Foundation Date: <b>{foundationDate}</b>
          </li>
          <li>
            Known since: <b>{createdAt && formatDistance(new Date(createdAt), new Date(), { addSuffix: true })}</b>
          </li>
        </ul>
      </div>
      <div>
        <ul className={css.list}>
          <li>
            Address: <b>{hqLocation}</b>
          </li>
          <li>
            Phone Number: <b>{businessPhoneNumber}</b>
          </li>
          <li>
            Whatsapp: <b>{businessWhatsapp}</b>
          </li>
          <li>
            LinkedIn: <b>{linkedinProfile}</b>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default AccountView
