import { useState } from 'react'
import FileSaver from 'file-saver'

import Dragon from 'components/Dragon'

import quote from 'htmlTemplates/quote'
import order from 'htmlTemplates/order'

import artifacts from 'api/artifacts'

const tpls = {
  quote,
  order,
}

const getFooter = (title) => {
  return (
    title &&
    `<div style="width: 100%; font-size: 10px; margin: 0 30px; display: flex; justify-content: space-between;">
        <span style="font-weight: 300;">broadn.io</span>
        <span>${title}</span>
        <span>
          <span class="pageNumber"></span>/<span class="totalPages"></span>
        </span>
      </div>`
  )
}

export const usePdf = () => {
  const [isLoading, setIsLoading] = useState(false)

  const getTemplate = (name = 'invoice') => {
    const tpl = tpls[name]
    return tpl
  }

  const download = async (data, name = 'quote', { title }) => {
    setIsLoading(true)
    const tpl = getTemplate(name)
    const bodyHtml = new Dragon(tpl, { ...data, title })
    const footerHtml = getFooter(title)

    const fileData = await artifacts.downloadPdf({ bodyHtml: bodyHtml.html.innerHTML, footerHtml })
    FileSaver.saveAs(fileData, `${name}-${data._key}.pdf`)

    setIsLoading(false)
  }

  const getCompiled = (data, name = 'invoice') => {
    const tpl = getTemplate(name)
    const bodyHtml = new Dragon(tpl, data)
    return bodyHtml.html
  }

  return { download, getCompiled, getTemplate, isLoading }
}
