import { useState } from 'react'
import { Text, Button, NumberFormatter, Switch, Group, Stack } from '@mantine/core'
import { observer } from 'mobx-react'

import Table from 'components/Table/Table'
import Modal from 'components/Modal/Modal'

import ProductsModal from './ProductsModal'

import AddToBasket from './AddToBasket'
import Spinner from 'components/Spinner/Spinner'
import BasketStore from '@/stores/BasketStore'


const SuggestedProducts = ({ basketStore, label, isCustomerView = true }: { basketStore: BasketStore, label: string, isCustomerView: boolean }) => {
  const [showModal, setShowModal] = useState(false)
  const [showCostAndMargins, setShowCostAndMargins] = useState(!isCustomerView)
  if (!basketStore || !basketStore.booksStore || basketStore.booksStore.isLoading) return <Spinner />

  const columns = (canEdit) => [
    {
      title: 'Product Code',
      render: ({ product: { ProductCode } }) => <small className="rounded outline w-400">{ProductCode}</small>,
    },
    {
      title: 'Name',
      render: ({ product: { Name, Description, brand } }) => (
        <div>
          <div>{Name}</div>
          <div>
            {brand && <small>{brand}</small>}
            {Description && <small>{Description}</small>}
          </div>
        </div>
      ),
    },
    {
      title: 'Unit Price',
      textAlign: 'right',
      style: { textWrapMode: 'nowrap' },
      render: ({ product: { sellingPrice, currency = '$' }, basketLine: { unitPrice } }) => (
        <NumberFormatter prefix={`${currency} `} value={unitPrice} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
      ),
    },
    {
      title: 'Unit Cost',
      textAlign: 'right',
      costsAndMargins: true,
      style: { backgroundColor: 'var(--mantine-color-blue-light)', textWrapMode: 'nowrap' },
      render: ({ product: { currency = '$' }, basketLine: { unitCost } }) => (
        <NumberFormatter prefix={`${currency} `} value={unitCost} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
      ),
    },
    {
      title: 'Quantity',
      textAlign: 'right',
      render: ({ product, basketLine: { quantity } }) => <AddToBasket quantity={quantity} canEdit={canEdit} onUpdateQuantity={(q: number) => basketStore.updateProductQuantity(product, q)} />,
    },
    {
      title: 'Total price',
      textAlign: 'right',
      style: { textWrapMode: 'nowrap' },
      render: ({ product: { currency = '$' }, basketLine: { totalPrice } }) => {
        return <NumberFormatter prefix={`${currency} `} value={totalPrice} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
      },
    },
    {
      title: 'Margin [%]',
      costsAndMargins: true,
      textAlign: 'right',
      style: { backgroundColor: 'var(--mantine-color-blue-light)' },
      render: ({ product: { currency = '$' }, basketLine: { marginPercent } }) => {
        return <NumberFormatter prefix={`% `} value={marginPercent} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
      },
    },
    {
      title: 'Total Margin',
      costsAndMargins: true,
      textAlign: 'right',
      style: { backgroundColor: 'var(--mantine-color-blue-light)', textWrapMode: 'nowrap' },
      render: ({ product: { currency = '$' }, basketLine: { totalMargin } }) => {
        return <NumberFormatter prefix={`${currency} `} value={totalMargin} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
      },
    },
  ].filter((col) => {
    if (!col.costsAndMargins) return true
    return showCostAndMargins && col.costsAndMargins
  })

  return (
    <div>
      <Group justify="space-between" grow>
        <Text size="md" fw={500}>
          {label}
        </Text>
        <Group justify="flex-end">
          <Button variant="light" onClick={() => setShowModal(true)}>
            Add new product
          </Button>
          {!isCustomerView && <Switch
            checked={showCostAndMargins}
            defaultChecked
            color="#036672"
            label="Cost & margins"
            onChange={(checked) => {
              setShowCostAndMargins(!showCostAndMargins)
            }}
          />}
        </Group>
      </Group>

      <Table data={basketStore.basketProducts} columns={columns(true)} />
      {showModal && (
        <Modal>
          <ProductsModal basketStore={basketStore} onAddToBasket={(product: any, units: number) => basketStore.updateProductQuantity(product, units)} setShowModal={setShowModal} />
        </Modal>
      )}
    </div>
  )
}

export default observer(SuggestedProducts)
