import { TextInput, Textarea, Select, NumberInput, MultiSelect, Checkbox, SegmentedControl, FileInput } from '@mantine/core'
import { MIME_TYPES } from '@mantine/dropzone'

import Group from './Inputs/Group'
import DateTime from './Inputs/DateTime'
import SelectInput from './Inputs/Select'
import MultiCheckbox from './Inputs/MultiCheckbox'

import View from './View/View'
import Header from './View/Header'

import css from './Form.module.css'

const formInputs = {
  string: TextInput,
  TextInput, //
  textarea: Textarea,
  Textarea,
  picklist: Select,
  select: SelectInput,
  Select,
  MultiSelect,
  multiSelect: MultiSelect,
  double: NumberInput,
  Header,
  boolean: Checkbox,
  Checkbox: MultiCheckbox,
  MultiCheckbox,
  address: Group,
  group: Group,
  datetime: DateTime,
  SegmentedControl,
  header: Header,
  file: FileInput,
}

const formView = {
  string: View,
  TextInput: View, //
  textarea: View,
  Textarea: View,
  double: View,
  datetime: View,
  picklist: View,
  View,
}

const FormItem = ({
  form,
  name,
  label,
  picklistValues,
  description,
  placeholder,
  type,
  optional,
  data,
  disabled,
  items,
  color,
  readOnly,
  className,
  size = 'xs',
  accept,
}) => {
  const Input = (readOnly ? formView[type] || formInputs[type] : formInputs[type]) || TextInput

  const inputProps = name ? form.getInputProps(name) : {}
  inputProps.checked = !!inputProps.defaultValue

  return (
    <div className={css.row}>
      <Input
        {...inputProps}
        key={form.key(name)}
        required={!optional} //
        label={label}
        description={description}
        data={data || picklistValues}
        disabled={disabled}
        placeholder={placeholder || label}
        name={name}
        form={form}
        items={items}
        color={color}
        readOnly={readOnly}
        className={className}
        size={size}
        accept={accept?.map((name) => MIME_TYPES[name])}
      />
    </div>
  )
}

export default FormItem
