import { Navigate } from 'react-router-dom'

import Billing from './Billing/Billing'

import EditConfig from './Admin/EditConfig/EditConfig'
import Dashboard from './Dashboard/Dashboard'

import UserProfile from './User/Profile/Profile'
import UserAccount from './User/Account/Account'

import BattleCardList from './BattleCard/List/List'
import BattleCard from './BattleCard/BattleCard'

import Leads from './Leads/Leads'
import Customers from './Customers/Customers'
import CustomersPage from './Customers/Page/CustomersPage'

import Products from './Products/Products'
import ProductsPage from './Products/Page/ProductsPage'

import Bundles from './Bundles/Bundles'

import Workflows from './Workflows/Workflows'
import Integrations from './Integrations/Integrations'

import Enquiry from './Enquiry/Enquiry'
import Settings from './Settings/Settings'
import Onboarding from './User/Onboarding/Onboarding'

import Opportunities from './Opportunities/Opportunities'
import OpportunitiesPage from './Opportunities/Page/OpportunitiesPage'

import adminRoutes from './Admin/AdminRoutes'

const screenRoutes = [
  {
    path: '/',
    element: <Dashboard />,
    permissions: 'user',
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    permissions: 'user',
  },
  {
    path: '/enquiry',
    element: <Enquiry />,
    permissions: 'user',
  },

  // Opportunities
  {
    path: '/opportunities',
    element: <Opportunities />,
    permissions: 'user',
  },

  {
    path: '/opportunities/:id',
    element: <OpportunitiesPage />,
    permissions: 'user',
  },

  {
    path: '/leads',
    element: <Leads />,
    permissions: 'user',
  },

  {
    path: '/products',
    element: <Products />,
    permissions: 'user',
  },

  {
    path: '/products/:id',
    element: <ProductsPage />,
    permissions: 'user',
  },

  {
    path: '/customers',
    element: <Customers />,
    permissions: 'user',
  },
  {
    path: '/customers/:id',
    element: <CustomersPage />,
    permissions: 'user',
  },

  {
    path: '/products',
    element: <Products />,
    permissions: 'user',
  },
  {
    path: '/bundles',
    element: <Bundles />,
    permissions: 'user',
  },
  {
    path: '/workflows',
    element: <Workflows />,
    permissions: 'user',
  },
  {
    path: '/integrations',
    element: <Integrations />,
    permissions: 'user',
  },

  {
    path: '/test',
    element: <EditConfig />,
    permissions: 'user',
  },
  {
    path: '/billing',
    element: <Billing />,
    permissions: 'user',
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    permissions: 'user',
  },
  {
    path: '/battle-cards',
    element: <BattleCardList />,
    permissions: 'user',
  },
  {
    path: '/battle-cards/:id',
    element: <BattleCard />,
    permissions: 'user',
  },

  {
    path: '/user/profile',
    element: <UserProfile />,
    permissions: 'user',
  },
  {
    path: '/user/account',
    element: <UserAccount />,
    permissions: 'user',
  },

  {
    path: '/admin/config',
    element: <EditConfig />,
    permissions: 'admin',
  },
  {
    path: '/settings',
    element: <Settings />,
    permissions: 'user',
  },
  {
    path: '/',
    element: <Onboarding />,
    permissions: 'onboarding',
  },
  {
    path: '/onboarding/:uid',
    element: <Onboarding role="admin" />,
    permissions: 'admin',
  },

  {
    path: '/',
    element: <Navigate to="/login" />,
  },
  ...adminRoutes,
]

export default screenRoutes
