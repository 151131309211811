import { ActionIcon, Avatar, Badge, Button, Group, Stack } from '@mantine/core'
import { IconPencil, IconTrashX } from '@tabler/icons-react'
import { formatRelative } from 'date-fns'

import Table from 'components/Table/Table'
import Form from 'components/Form/Form'

import auth from 'api/auth'
import accountApi from 'api/account'
import adminApi from 'api/admin'

type AccountUsersProps = {
  users: any[]
  onRetry: (cb: () => any) => any
  role?: 'user' | 'admin'
  accountId?: string
}

const AccountUsers = ({ users, onRetry, role = 'user', accountId }: AccountUsersProps) => {
  const { isAccountOwner, user } = auth as any
  const isAdmin = role === 'admin'

  const countOwners = users?.filter(({ accountRoles }) => accountRoles?.includes('owner')).length

  let onInvite = (values) => accountApi.createUser(values)
  let onUpdate = (userId, values) => accountApi.updateUser(userId, values)
  let onDelete = (userId) => accountApi.deleteUser(userId)

  if (isAdmin) {
    onInvite = (values) => adminApi.createUser({ ...values, accountId })
    onUpdate = (userId, values) => adminApi.updateUser(userId, values)
    onDelete = (userId) => adminApi.unassingFromAccount(userId)
  }

  const columns = [
    {
      title: 'Name',
      render: ({ profileImageUrl, firstName, lastName }) => (
        <div className="flex">
          <Avatar variant="outline" radius="xl" size="sm" color="orange" src={profileImageUrl} alt={firstName + ' ' + lastName} />
          {firstName + ' ' + lastName}
        </div>
      ),
    },
    { title: 'Email', name: 'email' },
    {
      title: 'Roles',
      render: ({ accountRoles }) => (
        <Group gap="xs">
          {accountRoles?.map((role) => (
            <Badge size="xs" variant="outline" key={role} color="teal">
              {role}
            </Badge>
          ))}
        </Group>
      ),
    },
    { title: 'Created at', render: ({ createdAt }) => formatRelative(createdAt, new Date()) },
    {
      textAlign: 'right',
      ownerOnly: true,
      render: ({ _key }, { showContent }) => (
        <Button size="xs" variant="subtle" leftSection={<IconPencil stroke={1} size={18} />} onClick={() => showContent((v) => !v)}>
          Edit
        </Button>
      ),
      content: ({ _key, accountRoles }, { close }) => {
        const formInputs = [
          {
            label: 'Roles',
            name: 'accountRoles',
            type: 'Checkbox',
            data: [
              { label: 'Owner', value: 'owner', disabled: accountRoles?.includes('owner') && countOwners === 1 },
              { label: 'User', value: 'user' },
              { label: 'Viewer', value: 'viewer' },
              { label: 'Approver', value: 'approver' },
              { label: 'Signatory', value: 'signatory' },
            ],
          },
        ]
        return (
          <Stack p="sm">
            <Form
              data={{ accountRoles }}
              inputs={formInputs}
              onSubmit={(values) =>
                onRetry(async () => {
                  await onUpdate(_key, values)
                  close()
                })
              }
            >
              <Group mt="1rem">
                <Button size="xs" variant="light" type="submit">
                  Save
                </Button>
                <Button size="xs" variant="subtle" onClick={close}>
                  Cancel
                </Button>
              </Group>
            </Form>
          </Stack>
        )
      },
    },
    {
      style: { width: '50px' },
      ownerOnly: true,
      render: ({ _key }, { showConfirm }) =>
        _key && (
          <ActionIcon
            disabled={user?._key === _key}
            variant="light"
            color="red"
            onClick={() => showConfirm(() => onRetry(() => onDelete(_key)))}
          >
            <IconTrashX size={18} stroke={1.5} />
          </ActionIcon>
        ),
    },
  ].filter((item) => isAccountOwner || !item.ownerOnly || isAdmin)

  const create = (isAccountOwner || isAdmin) && {
    label: 'Invite user',
    form: [
      { label: 'Email', name: 'email' }, //
      { label: 'First name', name: 'firstName' },
      { label: 'LastName', name: 'lastName' },
      {
        label: 'Roles',
        name: 'accountRoles',
        type: 'Checkbox',
        data: [
          { label: 'Owner', value: 'owner' },
          { label: 'User', value: 'user' },
          { label: 'Viewer', value: 'viewer' },
          { label: 'Approver', value: 'approver' },
          { label: 'Signatory', value: 'signatory' },
        ],
      },
    ],
    onSubmit: (values) => onRetry(() => onInvite(values)),
  }

  return <Table data={users} columns={columns} create={create} label="Users" />
}

export default AccountUsers
