const tpl = /*html*/ `
<!DOCTYPE html>
<html>

<head>
  <title dr-text="title">[Title]</title>
  <link href="https://fonts.googleapis.com/css2?family=Ephesis&family=Ubuntu&display=swap" rel="stylesheet">

</head>

<body>
  <div class="page-container">
    <style>
      * {
        box-sizing: border-box;
      }

      .page-container {
        --gray-300: #d1d5db;
        --gray-400: #9ca3af;

        font-family: Arial, sans-serif;
        font-size: 12px;
        /* width: 900px; */
        padding: 1px;
      }

      .flex__8Henc {
        display: flex;
        align-items: center;
        gap: 1em;
      }

      .avatar__8Henc {
        border-radius: 50vh;
      }

      .PdfPreview_page__8Henc table {
        width: 100%;
        border-collapse: collapse;
        border: none;
        margin: 2em 0;
        page-break-inside: avoid;

        font-family: Arial, sans-serif;
        font-size: 12px;
        line-height: 1.6;
        color: #333;
      }

      .PdfPreview_page__8Henc td,
      .PdfPreview_page__8Henc th {
        border: solid 1px var(--gray-300);
        padding: 8px;
      }

      .PdfPreview_page__8Henc th {
        background-color: #f2f2f2;
        font-weight: bold;
        text-align: left;
      }

      .PdfPreview_page__8Henc b {
        font-weight: 600;
      }

      .PdfPreview_page__8Henc .PdfPreview_productCode__wSP1u {
        background: white;
        border: solid 1px var(--gray-300);
        border-radius: 50vh;
        display: inline-flex;
        padding: 0 0.75em;
        line-height: 1rem;
        margin: 0 .5rem;
      }

      .PdfPreview_photos__wSP1u {
        display: flex;
        gap: .5rem;
      }

      .PdfPreview_photos__wSP1u img {
        height: 100px;
        border-radius: 5px;
        display: block;
      }

      .PdfPreview_page__8Henc ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      .PdfPreview_desc__xyygz {
        color: var(--gray-400);
      }

      .ephesis {
        font-family: "Ephesis", cursive;
        font-weight: 400;
        font-style: normal;
        display: inline-flex;
        font-size: 3em;
        padding: 0 .5em;
      }
    </style>
    <div class="PdfPreview_page__8Henc">
      <div class="flex__8Henc" style="justify-content: space-between;">
        <div>
          <div dr-text="account.name" style="font-size: 2rem;">[Company name]</div>
          <div dr-text="account.description" class="PdfPreview_desc__xyygz" style="padding: 2px;"></div>
        </div>
        <img dr-src="account.logo" alt="logo" />
      </div>

      <hr />
      <h2>Order: #<span dr-text="order._key">[Order Id]</span></h2>

      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Order number</th>
            <th>#<span dr-text="order._key">[Order Id]</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Order Date</td>
            <td><span dr-text="order.createdAt" dr-format="date">[Order date]</span></td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Buyer Information</th>
            <th>Seller Information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="vertical-align: top;">
              <ul>
                <li class="flex__8Henc">

                  <img class="avatar__8Henc" dr-if="customer.logo" dr-src="customer.logo" alt="Logo">
                  <b dr-text="customer.name">[Customer name]</b>

                </li>

                <li>Client ID: <span dr-text="customer._key">[Client ID]</span></li>
                <li dr-if="customer.billingAddress">
                  <span dr-text="customer.billingAddress.ShippingStreet">[billingAddress.line1]</span>
                </li>
                <li dr-if="customer.billingAddress">
                  <span dr-text="customer.billingAddress.BillingCity">[city]</span>, <span
                    dr-text="customer.billingAddress.BillingState">[state]</span>,
                  <span dr-text="customer.billingAddress.BillingPostalCode">[postalCode]</span>
                </li>
                <li dr-if="customer.billingAddress">
                  <span dr-text="customer.billingAddress.BillingCountry">[country]</span>
                </li>
                <li>Contact: <span dr-text="createdBy.firstName">[first name]</span> <span
                    dr-text="createdBy.lastName">[last name]</span>
                </li>
                <li>Email: <span dr-text="createdBy.email">[email]</span></li>
                <li dr-if="customer.website">Website: <span dr-text="customer.website">[website]</span></li>
              </ul>
            </td>
            <td style="vertical-align: top;">
              <ul>
                <li class="flex__8Henc">
                  <b dr-text="account.name">[Account name]</b>
                </li>

                <li dr-if="account.hqLocation"><span dr-text="account.hqLocation">[HQ Location]</span></li>

                <li>Contact: <span dr-text="createdBy.firstName">[first name]</span> <span
                    dr-text="createdBy.lastName">[last name]</span>
                </li>
                <li>Phone: <span dr-text="account.businessPhoneNumber">[Phone]</span></li>
                <li>Website: <span dr-text="account.website">[website]</span></li>
              </ul>

            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Buyer's Delivery Address </th>
            <th>Buyer's Billing Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                <li class="flex"><span dr-text="customer.name">[Customer name]</span></li>
                <li><span dr-text="quote.shippingAddress.ShippingStreet">[Line 1]</span></li>
                <li>
                  <span dr-text="quote.shippingAddress.ShippingCity">[City]</span>,
                  <span dr-text="quote.shippingAddress.ShippingState">[State]</span>,
                  <span dr-text="quote.shippingAddress.ShippingPostalCode">[PostalCode]</span>
                </li>
                <li><span dr-text="quote.shippingAddress.ShippingCountry">[Country]</span></li>
              </ul>
            </td>
            <td>
              <ul>
                <li class="flex"><span dr-text="customer.name">[Customer name]</span></li>
                <li><span dr-text="quote.billingAddress.BillingStreet">[Line 1]</span></li>
                <li>
                  <span dr-text="quote.billingAddress.BillingCity">[City]</span>,
                  <span dr-text="quote.billingAddress.BillingState">[State]</span>,
                  <span dr-text="quote.billingAddress.BillingPostalCode">[PostalCode]</span>
                </li>
                <li><span dr-text="quote.billingAddress.BillingCountry">[Country]</span></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th>Product Code</th>
            <th>Name</th>
            <th>Unit Price</th>
            <th style="text-align: center;">Quantity</th>
            <th style="text-align: right;">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr dr-each="basket.products">
            <td>
              <div class="PdfPreview_productCode__wSP1u" dr-text=".ProductCode">[Product Code]</div>
            </td>
            <td>
              <div dr-text=".Name">[Name]</div>
              <div><small dr-text=".Description"></small></div>
            </td>
            <td>
              <div style="text-align: right;">
                $ <span dr-text=".unitPrice">[Unit Price]</span>
              </div>
            </td>
            <td>
              <div style="text-align: right;" dr-text=".quantity">[Quantity]</div>
            </td>
            <td>
              <div style="text-align: right;">$ <span dr-text=".total" dr-format="number">[Total]</span></div>
            </td>
          </tr>

        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" style="text-align: right;"><b>Gross Amount</b></td>
            <td style="text-align: right;"><b>$ <span dr-text="basket.totalDetails.subTotal" dr-format="number">[Gross]</span></b></td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: right;">Tax 15%</td>
            <td style="text-align: right;">$ <span dr-text="basket.totalDetails.tax" dr-format="number">[Tax]</span></td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: right;"><b>Total Amount</b></td>
            <td style="text-align: right;"><b>$ <span dr-text="basket.totalDetails.total" dr-format="number">[Total]</span></b></td>
          </tr>
        </tfoot>
      </table>

      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Delivery, payment and terms</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          <tr dr-each="quote.terms">
            <td>
              <div dr-text=".label">[Term]</div>
            </td>
            <td>
              <div dr-text=".selected.label"><i class="PdfPreview_desc__xyygz">-- Not selected --</i></div>
              <div class="PdfPreview_desc__xyygz" dr-text=".selected.description"></div>
            </td>
          </tr>

          <tr>
            <td>General Terms and Conditions</td>
            <td><a href="https://www.precisionmanufacturing.com/terms">https://www.precisionmanufacturing.com/terms</a>
            </td>
          </tr>
          <tr>
            <td>Return Policy</td>
            <td><a
                href="https://www.precisionmanufacturing.com/returns">https://www.precisionmanufacturing.com/returns</a>
            </td>
          </tr>
          <tr>
            <td>Warranty Details</td>
            <td><a
                href="https://www.precisionmanufacturing.com/warranty">https://www.precisionmanufacturing.com/warranty</a>
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Buyer Signature</th>
            <th>Seller Signature</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div dr-text="signedBy.customer.signatureBody" class="signature__8Henc">
                <br />
                <br />
                <div>Name:
                  <b dr-text="createdBy.firstName"></b>
                  <b dr-text="createdBy.lastName">______________________</b>
                </div>
                <div>Title: <b dr-text="createdBy.title">______________________</b></div>

                <br><br>
                <div>Signature: <span id="customerSignature"></span></div>
                <br><br><br>
                <div>Date: </div>
              </div>
            </td>

            <td>
              <div dr-text="signedBy.account.signatureBody" class="signature__8Henc">
                <br />
                <div>Name: <b dr-text="me.displayName">______________________</b></div>
                <div>Title: <b dr-text="me.title">______________________</b></div>
                <br><br><br>
                <div>Signature: <span id="accountSignature"></span></div>
                <br><br><br>
                <div>Date: </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table>

        <tbody dr-each="basket.products">

          <tr dr-if=".specs">
            <th colspan="2" style="font-weight: 400;">
              <div>
                <b dr-text=".Name">[Product Name]</b>
                <small class="PdfPreview_productCode__wSP1u" dr-text=".ProductCode">[Product Code]</small>
              </div>
              <div><small dr-text=".Description"></small></div>
            </th>
          </tr>

          <tr dr-if=".photos">
            <td colspan="2">
              <div class="PdfPreview_photos__wSP1u">
                <div dr-each=".photos">
                  <img dr-src="." alt="Product" />
                </div>
              </div>
            </td>
          </tr>

          <tr dr-each=".specs">
            <td>
              <b dr-text=".label">[Spec label]</b>
            </td>
            <td>
              <div dr-text=".value"><i class="PdfPreview_desc__xyygz">-- Not selected --</i></div>
            </td>
          </tr>

          <tr dr-if=".specs">
            <td colspan="2" style="border: none;"></td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</body>

</html>
`

export default tpl
