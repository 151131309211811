import get from 'lodash.get'
import set from 'lodash.set'
import { useForm, isEmail, isInRange, isNotEmpty } from '@mantine/form'

import FormItem from './FormItem'

type FormProps = {
  data: any
  inputs: any
  onSubmit: (values: any) => any
  children?: any
  className?: any
  readOnly?: boolean
}

/*

inputs = [
  {
    name: 'billingAddress.city',
    label: 'City'
    type: 'TextInput'
    optional: true,
    validate: {
      type: "isNotEmpty",
      message: "Should not be empty!"
    }
  }
]

*/

//---------------------------------------------------------------------------
const formValidators = { isInRange, isEmail, isNotEmpty }

//---------------------------------------------------------------------------
const Form = ({ data, inputs, onSubmit, children, className, readOnly }: FormProps) => {
  let initialValues = {}
  let validatation = {}

  inputs = inputs || []

  //-------------------------------------------------------------------------
  const compoundFields = inputs.reduce((buf, item) => {
    if (item.compoundFieldName && item.compoundFieldName !== item.name) {
      buf[item.compoundFieldName] = buf[item.compoundFieldName] || { items: [] }
      buf[item.compoundFieldName].items.push({ ...item, name: item.compoundFieldName + '.' + item.name })
    }
    if (buf[item.name]) {
      buf[item.name] = { ...item, ...buf[item.name] }
    }
    return buf
  }, {})

  //-------------------------------------------------------------------------
  inputs = inputs
    .map((item) => {
      const compound = compoundFields[item.name]
      return compound ? compound : item
    })
    .filter(({ name, compoundFieldName }) => !compoundFieldName || compoundFieldName === name)

  //-------------------------------------------------------------------------
  const setValidation = ({ name, validate, items }: any) => {
    if (validate && !items) {
      const { type, message } = validate
      const validateFn = formValidators[type]

      if (validateFn) set(validatation, name, validateFn(message))
    }
  }

  inputs.forEach(({ name, validate, items }) => {
    if (name) {
      const value = get(data, name)
      set(initialValues, name, value || undefined)

      setValidation({ name, validate })

      if (items) {
        items.forEach((child) => {
          const value = get(data, child.name)
          set(initialValues, child.name, value || undefined)
          setValidation(child)
        })
      }
    }
  })

  //-------------------------------------------------------------------------
  // console.log(123, { inputs, compoundFields, initialValues, validatation })

  const form = useForm({
    mode: 'uncontrolled',
    initialValues,
    validate: validatation,
  })

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <div className={className}>
        {inputs.map((item, key) => (
          <FormItem form={form} {...item} key={key} readOnly={readOnly} />
        ))}
      </div>
      {children}
    </form>
  )
}

//---------------------------------------------------------------------------
export default Form
