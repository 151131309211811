import '@mantine/dates/styles.css'

import { DateTimePicker } from '@mantine/dates'

const DateTime = ({ label, description, placeholder, form, name, value }) => {
  const inputProps = form.getInputProps(name)

  inputProps.defaultValue = inputProps.defaultValue && new Date(inputProps.defaultValue)

  return <DateTimePicker size="md" label={label} clearable description={description} placeholder={placeholder || label} {...inputProps} />
}

export default DateTime
