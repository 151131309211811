import mixpanel from 'mixpanel-browser'

// Event categories
export const EventCategories = {
  QUESTIONNAIRE: 'EVQuestionnaire',
}

// Event actions
export const EventActions = {
  ANSWER_QUESTION: 'answer_question',
  FORM_SUBMISSION: 'form_submission',
}

// Interface for question answer event data
export interface QuestionAnswerEvent {
  questionId: string
  questionName: string
  questionGroup: string
  questionNumber: number
  sessionId: string
}

// Interface for form submission event data
export interface FormSubmissionEvent {
  email: string
  name: string
  sessionId: string
}

const MixpanelAnalytics = {
  /**
   * Gets or creates a session ID for tracking
   * Uses the same session ID generation as GA to keep consistency
   * @returns Session ID string
   */
  getOrCreateSessionId(): string {
    let sessionId = localStorage.getItem('ev_questionnaire_session')
    if (!sessionId) {
      sessionId = `session_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`
      localStorage.setItem('ev_questionnaire_session', sessionId)
    }
    return sessionId
  },

  /**
   * Initializes Mixpanel tracking with the given token
   * @param token Mixpanel project token
   * @returns MixpanelAnalytics instance with initialization status
   */
  init(token: string): { initialized: boolean; sessionId: string } {
    try {
      if (!token) {
        return { initialized: false, sessionId: '' }
      }

      const sessionId = this.getOrCreateSessionId()
      const isLocalhost = window.location.hostname.includes('localhost')

      mixpanel.init(token, {
        debug: isLocalhost,
        track_pageview: true,
        persistence: 'localStorage',
      })

      mixpanel.identify(sessionId)

      mixpanel.track('Page View', {
        page: window.location.pathname,
        title: document.title,
      })

      return { initialized: true, sessionId }
    } catch (error) {
      console.error('Failed to initialize Mixpanel:', error)
      return { initialized: false, sessionId: '' }
    }
  },

  /**
   * Tracks a question answer event in Mixpanel
   * @param data Question answer event data
   */
  trackQuestionAnswer(data: QuestionAnswerEvent): void {
    try {
      // Create the standard event name
      const answerEventName = `answer-${data.questionNumber}-${data.questionName}`
      mixpanel.track(answerEventName, {
        category: EventCategories.QUESTIONNAIRE,
        question_id: data.questionId,
        question_name: data.questionName,
        question_group: data.questionGroup,
        question_number: data.questionNumber,
        session_id: data.sessionId,
        is_dev: window.location.hostname.includes('localhost'),
      })

      // Additionally track questionnaire-start event for the first question
      if (data.questionNumber === 1) {
        mixpanel.track('questionnaire-start', {
          category: EventCategories.QUESTIONNAIRE,
          question_id: data.questionId,
          question_name: data.questionName,
          question_group: data.questionGroup,
          question_number: data.questionNumber,
          session_id: data.sessionId,
        })
      }
    } catch (error) {
      console.error('Failed to track question answer in Mixpanel:', error)
    }
  },

  /**
   * Tracks a form submission event in Mixpanel
   * @param data Form submission event data
   */
  trackFormSubmission(data: FormSubmissionEvent): void {
    try {
      // Set user properties
      mixpanel.people.set({
        $email: data.email,
        $name: data.name || '',
        session_id: data.sessionId,
      })

      // Track the event
      mixpanel.track(EventActions.FORM_SUBMISSION, {
        category: EventCategories.QUESTIONNAIRE,
        email: data.email,
        name: data.name || '',
        session_id: data.sessionId,
      })
    } catch (error) {
      console.error('Failed to track form submission in Mixpanel:', error)
    }
  },

  /**
   * Reset Mixpanel user identification and tracking
   */
  reset(): void {
    try {
      mixpanel.reset()
    } catch (error) {
      console.error('Failed to reset Mixpanel:', error)
    }
  },
}

export default MixpanelAnalytics
