import { useEffect, useState } from 'react'
import { Button } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'

import css from './Account.module.css'

import EditTerms from './EditTerms'

import sales from 'api/sales'

const ViewTerms = ({ availableTerms, onRetry }) => {
  const [terms, setTerms] = useState(availableTerms)
  const [active, setActive] = useState()

  const onSubmit = (values) => {
    const list = terms.map((item) => (item === active ? values : item))

    setActive(null)
    onRetry(() => sales.updateAccountSettings({ availableTerms: list }))
  }

  useEffect(() => {
    setTerms(availableTerms)
  }, [availableTerms])

  return (
    <div>
      <h4>Available terms</h4>

      {terms?.map((item, key) =>
        item === active ? (
          <EditTerms key={key} item={active} onCancel={() => setActive(null)} onSubmit={onSubmit} />
        ) : (
          <div key={key} className={css.ViewTerms}>
            <div>{item.label}</div>
            <div className={css.Options}>
              {item.options.map(({ label, description, value, units }) => (
                <div key={value} className={css.Option}>
                  {value === item.defaultValue && (
                    <div className={css.Active}>
                      <IconCheck stroke={'3px'} />
                    </div>
                  )}
                  <div>
                    <div>
                      {label} {units}
                    </div>
                    <div className={css.Desc}>{description}</div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <Button variant="outline" size="xs" onClick={() => setActive(item)}>
                Edit
              </Button>
            </div>
          </div>
        )
      )}

      <div className={css.AddOption}>
        <Button
          variant="subtle"
          onClick={() => {
            const newTerm = { name: '', options: [{ label: '', value: '' }] }
            setTerms([...(terms || []), newTerm])
            setActive(newTerm)
          }}
        >
          Add new
        </Button>
      </div>
    </div>
  )
}

export default ViewTerms
