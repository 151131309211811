import { useState } from 'react'
import { Badge, Title, Group, Select, Button, Input, TextInput, InputWrapper, MultiSelect, SegmentedControl } from '@mantine/core'

import Form from 'components/Form/Form'

const EditForm = ({ onCancel, onSubmit, ...user }) => {
  const formInputs = [
    {
      label: 'Account status',
      name: 'accountStatus',
      type: 'SegmentedControl',
      color: 'blue',
      data: [
        { label: 'Active', value: 'active' },
        { label: 'Payment Required', value: 'paymentRequired' },
        { label: 'Blocked', value: 'blocked' },
      ],
    },
    { label: 'First name', name: 'firstName' },
    { label: 'Last time', name: 'lastName' },
    { label: 'Title', name: 'title' },
    { label: 'Profile image', name: 'profileImageUrl' },
    { label: 'Email', name: 'email', disabled: true },
    {
      label: 'Roles',
      name: 'roles',
      type: 'MultiSelect',
      data: [
        { label: 'User', value: 'user', disabled: true },
        { label: 'Broadn', value: 'broadn' },
        { label: 'Admin', value: 'admin' },
      ],
    },
    {
      label: 'Account roles',
      name: 'accountRoles',
      type: 'MultiSelect',
      data: [
        { label: 'Owner', value: 'owner' },
        { label: 'User', value: 'user' },
        { label: 'Viewer', value: 'viewer' },
        { label: 'Approver', value: 'approver' },
        { label: 'Signatory', value: 'signatory' },
      ],
    },
  ]

  return (
    <div className="edit-user__form">
      <Form data={user} inputs={formInputs} onSubmit={onSubmit}>
        <div className="edit-user__form__actions">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </div>
      </Form>
    </div>
  )
}

export default EditForm
