import css from './Modal.module.css'

const Modal = ({ children, size = undefined }) => {
  return (
    <dialog className={`${css.dialog} ${css[size]}`} ref={(ref) => ref && ref.showModal()}>
      <div className={css.content}>{children}</div>
    </dialog>
  )
}

export default Modal
