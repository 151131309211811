import { Link } from 'react-router-dom'
import { Button } from '@mantine/core'
import { IconEye } from '@tabler/icons-react'

import { PageHeader } from 'components/PageHeader/PageHeader'
import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import emailTemplates from 'api/emailTemplates'

const Emails = () => {
  const resolve = usePreload(emailTemplates.getAll)

  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Admin', href: '/docs' },
    { label: 'Email templates' },
  ]

  const columns = [
    { label: 'Template name', id: 'name' },
    {
      textAlign: 'right',
      render: ({ name }) => (
        <Button component={Link} to={`/admin/emails/${name}`} leftSection={<IconEye size="1rem" stroke={1} />} variant="subtle" color="green" size="xs">
          view
        </Button>
      ),
    },
  ]

  const { data = [] } = resolve
  return (
    <Preload {...resolve}>
      <PageHeader title="Email" breadcrumbs={breadcrumbs} />

      <Table data={data} columns={columns} />
    </Preload>
  )
}

export default Emails
