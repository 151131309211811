import { Button } from '@mantine/core'

import Form from 'components/Form/Form'

const inputs = [
  { label: 'Company name', name: 'name' }, //
  { label: 'Description', name: 'description' },
  { label: 'Logo URL', name: 'logo' },
  { label: 'Website', name: 'website' },
  { label: 'Industry', name: 'industry' },

  { label: 'Foundation Date', name: 'foundationDate' },
  { label: 'Address', name: 'hqLocation' },
  { label: 'Phone Number', name: 'businessPhoneNumber' },
  { label: 'Whatsapp', name: 'businessWhatsapp' },
  { label: 'LinkedIn', name: 'linkedinProfile' },
  { label: 'Logo URL', name: 'logo' },
]

const AccountEdit = ({ data, onClose, onSubmit }) => {
  return (
    <Form inputs={inputs} data={data} className="columns-2" onSubmit={onSubmit}>
      <div className="gap-1">
        <Button type="submit" size="xs">
          Save
        </Button>
        <Button variant="outline" onClick={onClose} size="xs">
          Cancel
        </Button>
      </div>
    </Form>
  )
}

export default AccountEdit
