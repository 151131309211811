import { useParams } from 'react-router-dom'
import { useEffect, useState, FC } from 'react'
import { observer } from 'mobx-react-lite'

import { PageHeader } from 'components/PageHeader/PageHeader'
import CompanyAside from 'components/CompanyAside/CompanyAside'
import Preload from 'components/PreLoad/PreLoad'

import { OpportunityStore } from '../../../stores/OpportunityStore'
import OpportunityContent from './OpportunitiesContent'
// @ts-ignore
import css from './OpportunitiesPage.module.css'

const breadcrumbs = [
  { label: 'Home', href: '/' }, //
  { label: 'Opportunities', href: '/opportunities' },
  { label: 'Order' },
]

//---------------------------------------------------------------------------
const OpportunitiesPage = observer(() => {
  const params = useParams<{ id: string }>()
  const id = params.id || ''
  const [opportunityStore, setOpportunityStore] = useState<OpportunityStore | null>(null)

  useEffect(() => {
    if (!id) return;
    OpportunityStore.init({ id }).then(setOpportunityStore);
  }, [id])

  if (!opportunityStore) return null
  const opportunity = opportunityStore.opportunity

  if (!opportunity) return null

  return (
    <div>
      <PageHeader title={'Opportunity Preview - ID #' + id} breadcrumbs={breadcrumbs} />
      {/* @ts-ignore */}
      <Preload isLoading={opportunityStore.loading} error={opportunityStore.hasError} data={opportunity}>
        <div className={css.layout}>
          <aside>
            <CompanyAside opportunityStore={opportunityStore} />
          </aside>
          <OpportunityContent opportunityStore={opportunityStore} />
        </div>
      </Preload>
    </div>
  )
})

export default OpportunitiesPage 