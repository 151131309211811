import { PageHeader } from '../../components/PageHeader/PageHeader'
import ProductHierarchy from './ProductHierarchy'
import { Stack } from '@mantine/core'
import Preload from 'components/PreLoad/PreLoad'
import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { BundleState } from '../../stores/BundleState'

const Bundles = observer((props) => {
  const [bundleState, setBundleState] = useState<BundleState>(new BundleState())
  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Management', href: '/management' }, { label: 'Bundles' }]

  return (
    <Preload isLoading={bundleState.isLoading} data={{}} className="bundles">
      <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 60px)' }}>
        <Stack style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <PageHeader title="Bundles" breadcrumbs={breadcrumbs} />
          <ProductHierarchy bundleState={bundleState} {...props} />
        </Stack>
      </div>
    </Preload>
  )
})

export default Bundles
