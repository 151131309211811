const src = {
  name: 'src',

  render(el, model) {
    const obj = el && el.$obj

    const value = model.getValue(obj, 'src')

    if (obj.value !== value) {
      obj.value = value
      obj.el.setAttribute('src', value)
    }
  },
}

export default src
