import React, { useState, useEffect } from 'react'
import { formatDistance } from 'date-fns'
import { FcGoogle } from 'react-icons/fc'
import { FaSalesforce } from 'react-icons/fa6'
import {
  Container,
  Accordion,
  Text,
  Button,
  Group,
  Stack,
  TextInput,
  FileInput,
  Card,
  Switch,
  Paper,
  Grid,
  List,
  ThemeIcon,
} from '@mantine/core'
import { IconFileExport, IconPackage, IconMail, IconSeeding, IconFileTypeXls, IconApi } from '@tabler/icons-react'
import ApiKey from './ApiKey/ApiKey'
import ProductsSettings from './Products/Products'
import BooksSettings from './Books/BooksSettings'
import Preload, { usePreload } from '../../components/PreLoad/PreLoad'
import { PageHeader } from '../../components/PageHeader/PageHeader'
import './Settings.css'
import { SalesforceSyncButton } from 'components/SalesforceSync/SyncButton'

import backend from 'api/backend'
import sales from 'api/sales'
import { BooksStore } from '../../stores/BooksStore'

const Settings = () => {
  const [emailAddresses, setEmailAddresses] = useState([] as string[])
  const [newEmail, setNewEmail] = useState('')
  const [fileLeads, setFileLeads] = useState<File | null>(null)
  const [salesforceConfig, setSalesforceConfig] = useState({
    isProduction: false,
    clientId: '',
    clientSecret: '',
  })
  const [showSalesforceConfig, setShowSalesforceConfig] = useState(false)
  const [booksStore, setBookStore] = useState<BooksStore | null>(null)
  const resolveProducts = usePreload()
  const resolveLeads = usePreload()
  const resolveAccountInfo = usePreload(sales.getMyAccount)

  useEffect(() => {
    BooksStore.init().then(setBookStore)
  }, [])

  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Settings' }]

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const addEmail = () => {
    if (validateEmail(newEmail) && emailAddresses.length < 3 && !emailAddresses.includes(newEmail)) {
      setEmailAddresses([...emailAddresses, newEmail])
      setNewEmail('')
    }
  }

  const removeEmail = (email) => {
    setEmailAddresses(emailAddresses.filter((e) => e !== email))
  }

  const { settings, edge, productCount } = resolveAccountInfo.data || {} as any
  console.log('Settings', settings, edge)
  return (
    <Container size="xl" className="SettingsPage">
      <PageHeader title="Settings" breadcrumbs={breadcrumbs} />

      <Accordion multiple defaultValue={['products', 'leads', 'email', 'connectGmail', 'connectSalesforce', 'apiKey']}>
        <Accordion.Item value="leads">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconSeeding size={28} stroke="1" />
              <div>
                <Text size="sm">Manage price and cost books</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Create new, view and remove price and cost books. Use these books in your quotes and orders.
                </Text>
              </div>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <BooksSettings booksStore={booksStore} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="products">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconPackage size={28} stroke="1" />
              <div>
                <Text size="sm">Manage Products</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Sync your product inventory and adjust other product related settings.
                </Text>
              </div>
            </Group>
          </Accordion.Control>

          <Accordion.Panel>
            <ProductsSettings productCount={productCount} deleteBtn={true} exportBtn={true} booksStore={booksStore} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="leads">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconSeeding size={28} stroke="1" />
              <div>
                <Text size="sm">Manage Leads</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Sync your product inventory and adjust other product related settings.
                </Text>
              </div>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack gap="xs">
              <Group justify="space-between" align="flex-end">
                <FileInput
                  size="sm"
                  w={300}
                  leftSection={<IconFileTypeXls stroke="1" size={18} />}
                  label="Attach your XLSX file"
                  placeholder="XLSX file"
                  leftSectionPointerEvents="none"
                  onChange={setFileLeads}
                />
                <Button type="submit" variant="default" size="sm" radius="sm">
                  Upload
                </Button>

                <Button variant="default" leftSection={<IconFileExport size={12} />} size="sm" radius="sm">
                  <Text size="xs">Export Leads</Text>
                </Button>
              </Group>
              <Preload {...resolveProducts} className="container2">
                {resolveLeads.data && (resolveLeads.data as any).leads && (
                  <Text size="xs" c="dimmed">
                    Leads imported: <b>{(resolveLeads.data as any).leads.length}</b>
                  </Text>
                )}
              </Preload>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="connectGmail">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconMail size={28} stroke="1" />
              <Stack>
                <Text size="sm">Gmail Integration</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Sync your Gmail Inbox to read incoming request messages and send emails.
                </Text>
              </Stack>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Preload {...resolveAccountInfo}>
              <Stack gap="xs">
                <Stack gap="xs">
                  <Text size="sm">Select your Google Mail account</Text>
                  <Text size="xs" c="dimmed">
                    Select the Gmail account you use to receive quotes, orders and inbound messages from your users. We'll use the same
                    account to respond to client queries and send follow up documentation.
                  </Text>
                </Stack>
                <Group align="center" justify="space-around">
                  <Button
                    variant="default"
                    size="sm"
                    radius="sm"
                    leftSection={<FcGoogle size={12} stroke="1" />}
                    onClick={() => backend.initiateGAuthForGmail()}
                  >
                    Connect your Gmail
                  </Button>
                  {settings && settings.gmailAuth ? (
                    <Card shadow="sm" padding="lg" radius="md" withBorder w={300}>
                      <Group>
                        <Stack gap={0}>
                          <FcGoogle size={32} stroke="1" />
                          <Text size="xs" c="dimmed">
                            OAuth2
                          </Text>
                        </Stack>
                        <Stack gap="sm">
                          <Text size="xs" fw={500}>
                            {settings.gmailAuth.emailAccount}
                          </Text>
                          <Text size="xs" fw={500} c="green.8">
                            Connected
                          </Text>
                          <Text size="xs" c="dimmed">
                            {formatDistance(new Date(settings.gmailAuth.lastModified), new Date(), { addSuffix: true })}
                          </Text>
                        </Stack>
                      </Group>
                    </Card>
                  ) : null}
                </Group>
              </Stack>
            </Preload>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="connectSalesforce">
          <Accordion.Control>
            <Group wrap="nowrap">
              <FaSalesforce size={28} stroke="1" fill="#00a1e1" />
              <Stack>
                <Text size="sm">Salesforce Integration</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Start syncing your Salesforce account.
                </Text>
              </Stack>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Preload {...resolveAccountInfo}>
              <Stack gap="xs">
                <Stack gap="xs">
                  <Text size="sm" fw={500}>
                    Select your Salesforce account
                  </Text>
                  <Text size="xs" c="dimmed">
                    Select the Salesforce account you use to create accounts, opportunities, quotes and orders. We'll keep everything in
                    sync.
                  </Text>
                </Stack>
                <Group align="center" justify="space-around">
                  {!showSalesforceConfig ? (
                    <Button
                      variant="default"
                      size="sm"
                      radius="sm"
                      leftSection={<FaSalesforce size={12} stroke="1" fill="#00a1e1" />}
                      onClick={() => setShowSalesforceConfig(true)}
                    >
                      Connect to Salesforce
                    </Button>
                  ) : (
                    <Card shadow="sm" padding="lg" radius="md" withBorder w={300}>
                      <Stack gap="xs">
                        <Switch
                          label={salesforceConfig.isProduction ? 'Production app' : 'Sandbox / test app'}
                          checked={salesforceConfig.isProduction}
                          onChange={(event) =>
                            setSalesforceConfig({
                              ...salesforceConfig,
                              isProduction: event.currentTarget.checked,
                            })
                          }
                          prefix="Sandbox"
                          size="sm"
                          required
                        />
                        <TextInput
                          label="Client ID"
                          value={salesforceConfig.clientId}
                          autoComplete="clientId"
                          onChange={(event) =>
                            setSalesforceConfig({
                              ...salesforceConfig,
                              clientId: event.currentTarget.value,
                            })
                          }
                          size="xs"
                          required
                          error={salesforceConfig.clientId.length === 0 ? 'Client ID is required' : null}
                        />
                        <TextInput
                          label="Client Secret"
                          value={salesforceConfig.clientSecret}
                          autoComplete="clientSecret"
                          type="password"
                          onChange={(event) =>
                            setSalesforceConfig({
                              ...salesforceConfig,
                              clientSecret: event.currentTarget.value,
                            })
                          }
                          size="xs"
                          required
                          error={salesforceConfig.clientSecret.length === 0 ? 'Client Secret is required' : null}
                        />
                        <Button
                          variant="default"
                          size="sm"
                          radius="sm"
                          leftSection={<FaSalesforce size={12} stroke="1" fill="#00a1e1" />}
                          onClick={() => {
                            if (
                              salesforceConfig.clientId &&
                              salesforceConfig.clientSecret &&
                              typeof salesforceConfig.isProduction === 'boolean'
                            ) {
                              backend.initiateSalesforceOAuth2(salesforceConfig)
                            }
                          }}
                          disabled={!salesforceConfig.clientId || !salesforceConfig.clientSecret}
                        >
                          Connect your Salesforce
                        </Button>
                      </Stack>
                    </Card>
                  )}

                  {settings && settings.salesforceAuth ? (
                    <Stack>
                      <Card shadow="sm" padding="lg" radius="md" withBorder w={300}>
                        <Group>
                          <Group>
                            <Stack gap={0}>
                              <FaSalesforce size={32} stroke="1" fill="#00a1e1" />
                              <Text size="xs" c="dimmed">
                                OAuth2
                              </Text>
                            </Stack>
                            <Stack gap={0}>
                              <Text size="xs" fw={500} c="green.8">
                                Connected
                              </Text>
                              <Text size="xs" c="dimmed">
                                {formatDistance(new Date(settings.salesforceAuth.lastModified), new Date(), { addSuffix: true })}
                              </Text>
                            </Stack>
                          </Group>
                          <Stack gap="sm">
                            <Group wrap="nowrap">
                              <Text size="xs" style={{ whiteSpace: 'nowrap' }} span>
                                <Text size="xs" style={{ whiteSpace: 'nowrap' }} span>
                                  InstanceUrl:&nbsp;
                                </Text>
                                <Text size="xs" fw={500} span>
                                  {settings.salesforceAuth.instanceUrl}
                                </Text>
                              </Text>
                            </Group>
                          </Stack>
                        </Group>
                      </Card>
                      <Paper withBorder p="md" radius="md">
                        <Stack>
                          <Text size="sm">Salesforce Sync</Text>
                          <SalesforceSyncButton />
                        </Stack>
                      </Paper>
                    </Stack>
                  ) : null}
                </Group>
              </Stack>
            </Preload>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="apiKey">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconApi size={28} stroke="1" />
              <div>
                <Text size="sm">Manage ApiKey</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Create API key.
                </Text>
              </div>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack gap="xs">
              <ApiKey edge={edge} />
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
}

export default Settings
