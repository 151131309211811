import { client } from './http'

class AccountApi {
  getMyAccount = () => client.get('./sales/accounts/my')
  updateMyAccount = (data) => client.put('./sales/accounts/my', data)
  updateAccountSettings = (data) => client.put('./sales/accounts/settings', data)

  createUser = (data) => client.post(`./sales/accounts/users`, data)
  updateUser = (userId, data) => client.put(`./sales/accounts/users/${userId}`, data)
  deleteUser = (userId) => client.delete(`./sales/accounts/users/${userId}`)
}

const accountApi = new AccountApi()
export default accountApi
