import { useState } from 'react'
import {  Text, Radio, Group, SimpleGrid, Checkbox } from '@mantine/core'

import termsDefault from './termsDefault.json'

const TermsForm = ({ children, list = termsDefault, terms, onSubmit }) => {
  const initialValues = terms
    ? terms.reduce((buf, item) => {
        buf[item.name] = item.selected?.value
        return buf
      }, {})
    : list.reduce((buf, item) => {
        buf[item.name] = item.defaultValue
        return buf
      }, {})

  const [values, setValues] = useState(initialValues)

  return (
    !!list?.length && (
      <div>
        <h4>Delivery, payments and terms</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit(values)
          }}
        >
          {list.map(({ label, name, options, defaultValue }) => (
            <div key={name}>
              <Checkbox.Group value={values[name]} onChange={(v) => setValues({ ...values, [name]: v })} label={label} pt="xs" pb="xs">
                <SimpleGrid cols={{ base: 1, sm: 2, md: 2, lg: 4, xl: 6 }} pt="xs">
                  {options.map((item) => (
                    <Checkbox.Card radius="md" style={{ background: 'white' }} value={item.value} key={item.value}>
                      <Group wrap="nowrap" p={'xs'}>
                        <Checkbox.Indicator />
                        <div>
                          <Text size="sm" fw={500}>
                            {item.label}
                          </Text>
                          <Text size="xs" fw={300}>
                            {item.description}
                          </Text>
                        </div>
                      </Group>
                    </Checkbox.Card>
                  ))}
                </SimpleGrid>
              </Checkbox.Group>
            </div>
          ))}

          {children}
        </form>
      </div>
    )
  )
}

export default TermsForm
