import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

export class ExcelExporter {
  private wb = XLSX.utils.book_new();
  
  addSheet(data: Record<string, any>[] | Record<string, any>, name = 'Sheet1', columns?: string[]): this {
    const dataArray = Array.isArray(data) ? data : [data];
    let filteredData = dataArray;
    if (columns && columns.length > 0) {
      filteredData = dataArray.map(item => {
        const filtered: Record<string, any> = {};
        columns.forEach(col => {
          if (col in item) {
            filtered[col] = item[col];
          }
        });
        return filtered;
      });
    }
    
    XLSX.utils.book_append_sheet(this.wb, XLSX.utils.json_to_sheet(filteredData), name);
    return this;
  }

  download(filename = 'export.xlsx'): void {
    const binary = XLSX.write(this.wb, { bookType: 'xlsx', type: 'binary' });
    const buf = ExcelExporter.stringToArrayBuffer(binary);
    const blob = new Blob([buf], { type: 'application/octet-stream' });
    ExcelExporter.downloadBlob(blob, filename);
  }

  static export(data: Record<string, any>[] | Record<string, any>, filename = 'export.xlsx', columns?: string[]): void {
    new ExcelExporter().addSheet(data, 'Sheet1', columns).download(filename);
  }
  
  private static stringToArrayBuffer(s: string): ArrayBuffer {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }
  
  private static downloadBlob(blob: Blob, filename: string): void {
    FileSaver.saveAs(blob, filename);
  }
} 