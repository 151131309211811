import { useState } from 'react'
import { ScrollArea, Modal, TextInput, Textarea, Button, Group, Stack, Text } from '@mantine/core'

const BookModal = ({ opened, onClose, bookType, onSuccess, booksStore }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleCreateBook = async () => {
    if (!formData.name.trim()) return

    setError(null)
    setLoading(true)
    try {
      const payload = {
        ...formData,
        isPricebook: bookType === 'pricebook',
        isCostbook: bookType === 'costbook',
        bookType: bookType,
      }

      const success = await booksStore.createBook(payload)
      if (success) {
        onSuccess()
        onClose()
      } else {
        setError(booksStore.error || 'Failed to create book. Please try again.')
      }
    } catch (error) {
      setError('Failed to create book. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const isPrice = bookType === 'pricebook'
  const bookTypeLabel = isPrice ? 'Price Book' : 'Cost Book'

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={`Create a new ${bookTypeLabel}`}
      overlayProps={{
        backgroundOpacity: 0.3,
        blur: 1.5,
      }}
      scrollAreaComponent={ScrollArea.Autosize}
    >
      <form onSubmit={(e) => {
        e.preventDefault()
        if (formData.name.trim()) handleCreateBook()
      }}>
        <Stack>
          <Text size="sm" color="dimmed">
            You are creating a new {bookTypeLabel.toLowerCase()} to manage the {isPrice ? 'pricing' : 'cost'} for your products.
          </Text>

          <TextInput required label="Name" name="name" value={formData.name} onChange={handleChange} placeholder={`Enter ${bookTypeLabel.toLowerCase()} name`} />

          <Textarea label="Description" name="description" value={formData.description} onChange={handleChange} placeholder="Enter description" />

          {error && (
            <Text color="red" size="sm">
              {error}
            </Text>
          )}

          <Group position="right" mt="md">
            <Button variant="outline" onClick={onClose} type="button">
              Cancel
            </Button>
            <Button loading={loading} type="submit" disabled={!formData.name.trim()}>
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default BookModal
