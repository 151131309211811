import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { GroupProps, ElementProps, Title, Breadcrumbs, Anchor, Text, Group } from '@mantine/core'

interface PageHeaderProps extends Omit<GroupProps, 'title'>, ElementProps<'header', keyof GroupProps> {
  title: ReactNode
  breadcrumbs?: { label: string; href?: string }[]
}

export function PageHeader({ children, title, breadcrumbs, className, mb = 0, ...props }: PageHeaderProps) {
  return (
    <Group component="header" justify="space-between" className={className} mb={mb} {...props}>
      <div style={{ padding: '1rem 0' }}>
        <Title component="h2" order={2}>
          {title}
        </Title>

        {breadcrumbs && (
          <Breadcrumbs mt="sm" mb={0}>
            {breadcrumbs.map((breadcrumb, key) =>
              breadcrumb.href ? (
                <Anchor fz="sm" underline="never" c="inherit" component={NavLink} key={key} to={breadcrumb.href}>
                  {breadcrumb.label}
                </Anchor>
              ) : (
                <Text key={key} c="dimmed" fz="sm">
                  {breadcrumb.label}
                </Text>
              )
            )}
          </Breadcrumbs>
        )}
      </div>

      {children}
    </Group>
  )
}
