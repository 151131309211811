import { useState } from 'react'
import { Avatar, Button, Group } from '@mantine/core'
import { IconPencil } from '@tabler/icons-react'

// @ts-ignore
import css from './Account.module.css'

import accountApi from 'api/account'
import adminApi from 'api/admin'

import AccountView from './AccountView'
import AccountEdit from './AccountEdit'

type AccountDetailsType = {
  _key?: string
  name?: string
  logo?: string
  description?: string
  companyType?: string
  employeeRange?: string
  foundationDate?: string
  createdAt?: string
  website?: string
  linkedinProfile?: string
  hqLocation?: string
  activityDomain?: string
  businessPhoneNumber?: string
  businessWhatsapp?: string
}

type AccountDetailsProps = {
  data: AccountDetailsType
  onRetry: any
  role?: 'user' | 'admin'
}

const AccountDetails = ({ data, onRetry, role = 'user' }: AccountDetailsProps) => {
  const { _key, name, logo, description } = data
  const [edit, setEdit] = useState(false)

  const onEdit = (values) => {
    onRetry(() => {
      if (role === 'admin') {
        return adminApi.updateAccount(_key, values)
      }
      return accountApi.updateMyAccount(values)
    })
    setEdit(false)
  }

  return (
    <div className={css.details}>
      <header>
        <div>
          <h2>
            <Group>{name}</Group>
          </h2>
          <div className="desc">{description}</div>
        </div>
        {logo ? (
          <img src={logo} alt={name} className={css.logo} />
        ) : (
          <Avatar color="cyan" radius="sm">
            {name?.split(' ').map(([v]) => v)}
          </Avatar>
        )}
      </header>

      <Button
        leftSection={<IconPencil stroke={1} size={18} />}
        size="xs"
        variant="subtle"
        className={css.editBtn}
        onClick={() => setEdit(!edit)}
      >
        Edit
      </Button>

      {edit ? <AccountEdit data={data} onClose={() => setEdit(false)} onSubmit={onEdit} /> : <AccountView data={data} />}
    </div>
  )
}

export default AccountDetails
