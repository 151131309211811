import { makeAutoObservable, runInAction } from 'mobx'
import opportunities from 'api/opportunities'
import oneLink from 'api/onelink'
import BasketStore from './BasketStore'
import { BooksStore } from './BooksStore'
export interface OpportunityData {
  _key: string
  basket?: BasketStore
  quote?: any
  order?: any
  customer?: any
  account?: any
  settings?: any
  urlKey?: string
  books?: { price?: string; cost?: string }
  [key: string]: any
}

export class OpportunityStore {
  _opportunity: OpportunityData | null = null
  error: any = null
  bookStore: BooksStore
  basketStore: BasketStore | null = null
  waitCounter: number = 0
  isCustomerView: boolean = false

  private constructor() {
    makeAutoObservable(this)
    this.waitCounter = 0
  }

  get opportunity() {
    return this._opportunity
  }

  static init = async ({ id, urlKey }: { id?: string; urlKey?: string }) => {
    const os = new OpportunityStore()
    const bookStore = await BooksStore.init()
    os.bookStore = bookStore
    if (id) {
      await os.fetchOpportunity(id)
    } else if (urlKey) {
      await os.getWithUrlKey(urlKey)
    }
    return os
  }

  runAndFetch = async (fn: () => Promise<any>) => {
    runInAction(() => {
      this.waitCounter++
    })

    if (fn && this._opportunity) {
      await fn()
      await this.fetchOpportunity(this._opportunity._key)
    }
    runInAction(() => {
      this.waitCounter--
    })
  }

  getWithUrlKey = async (urlKey: string) => {
    if (!urlKey) return

    runInAction(() => {
      this.waitCounter++
      this.error = null
    })

    try {
      const response = await opportunities.getWithUrlKey(urlKey)
      if (!response) return
      runInAction(() => {
        this._opportunity = response as any as OpportunityData
        this.isCustomerView = true
        if (this._opportunity) {
          this.basketStore = BasketStore.fromOpportunity(this._opportunity, this.bookStore, this.isCustomerView)
        }
      })
    } catch (error: any) {
      runInAction(() => {
        this.error = error
      })
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }

  fetchOpportunity = async (opportunityId: string) => {
    if (!opportunityId) return

    runInAction(() => {
      this.waitCounter++
      this.error = null
    })

    try {
      const response = await opportunities.getOne(opportunityId)
      if (!response) return
      runInAction(() => {
        this._opportunity = response as any as OpportunityData
        if (this._opportunity) {
          this.assignOpportunityBooks()
          this.basketStore = BasketStore.fromOpportunity(this._opportunity, this.bookStore)
        }
      })
      await this.bookStore.fetchEntries4BooksInUse()
    } catch (error) {
      runInAction(() => {
        this.error = error
      })
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }

  get lastestBasketSnapshot() {
    if (!this._opportunity) return null
    const basket = BasketStore.fromOpportunity(this._opportunity, this.bookStore)
    basket.isSnapshot = true
    basket.booksStore = null
    return basket
  }

  assignOpportunityBooks = () => {
    runInAction(() => {
      if (!this._opportunity) return
      const oppBooks = {
        price:
          this._opportunity.books && this._opportunity.books.price ? this._opportunity.books.price : this.bookStore.defaultPricebook?._id,
        cost: this._opportunity.books && this._opportunity.books.cost ? this._opportunity.books.cost : this.bookStore.defaultCostbook?._id,
      }
      this._opportunity.books = oppBooks
      this.bookStore.setSelectedBooks(oppBooks.price, oppBooks.cost)
    })
  }

  reply = async (message: string, terms?: any) => {
    if (!this._opportunity || !this.basketStore) return

    try {
      runInAction(() => {
        this.waitCounter++
      })
      await opportunities.reply(this._opportunity._key, {
        selected: this.basketStore.basket,
        message,
        terms,
      })
      await this.fetchOpportunity(this._opportunity._key)
    } catch (error) {
      runInAction(() => {
        this.error = error
      })
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }

  customerReply = async (urlKey: string, message: string) => {
    if (!this._opportunity || !this.basketStore) return

    try {
      runInAction(() => {
        this.waitCounter++
      })
      await oneLink.customerReply(urlKey, {
        message,
        selected: this.basketStore.basket,
      })
      await this.fetchOpportunity(this._opportunity._key)
    } catch (error) {
      runInAction(() => {
        this.error = error
        this.waitCounter--
      })
    }
  }

  createOrder = async (message: string, terms?: any) => {
    if (!this._opportunity || !this.basketStore) return

    try {
      runInAction(() => {
        this.waitCounter++
      })
      await opportunities.createOrder(this._opportunity._key, {
        selected: this.basketStore.basket,
        message,
        terms,
      })
      await this.fetchOpportunity(this._opportunity._key)
    } catch (error) {
      runInAction(() => {
        this.error = error
      })
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }

  updateProductQuantity = (product: any, units: number) => {
    if (this.basketStore) {
      this.basketStore.updateProductQuantity(product._key, units)
    }
  }

  revertBasket = () => {
    if (this.basketStore) {
      this.basketStore.revert()
    }
  }

  get isLoading() {
    return this.waitCounter > 0 || (this.bookStore && this.bookStore.isLoading)
  }

  get hasError() {
    return !!this.error
  }

  updateOpportunityBooks = async ({ price, cost }: { price: string | undefined; cost: string | undefined }) => {
    runInAction(() => {
      this.waitCounter++
    })
    try {
      if (!this._opportunity || !this._opportunity._key) {
        this.error = 'No opportunity loaded'
        return
      }

      const result = await opportunities.updateBooks(this._opportunity._key, { price, cost })

      runInAction(() => {
        if (result && this._opportunity) {
          this._opportunity.books = { price, cost }
          this.assignOpportunityBooks()
        }
      })
      await this.bookStore.fetchEntries4BooksInUse()
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Failed to update books'
      })
    } finally {
      runInAction(() => {
        this.waitCounter--
      })
    }
  }
}
