import AllUsers from './Users/Users'
import AdminAccounts from './Accounts/Accounts'
import AdminAccountPage from './Accounts/Page/AccountPage'

import Users from './Users/Users'
import UsersPage from './Users/Page/UsersPage'

import Emails from './Emails/Emails'
import EmailsPage from './Emails/Page/EmailsPage'

const adminRoutes = [
  {
    path: '/admin/users/:userId',
    element: <UsersPage />,
    permissions: 'admin',
  },
  {
    path: '/admin/users',
    element: <AllUsers />,
    permissions: 'admin',
  },
  {
    path: '/admin/accounts',
    element: <AdminAccounts />,
    permissions: ['admin'],
  },
  {
    path: '/admin/accounts/:id',
    element: <AdminAccountPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/emails',
    element: <Emails />,
    permissions: ['admin'],
  },

  {
    path: '/admin/emails/:name',
    element: <EmailsPage />,
    permissions: ['admin'],
  },
]

export default adminRoutes
