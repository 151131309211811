import { client } from './http'

class SalesApi {
  getEnquiries = () => client.get('/sales/enquiries')
  getEnquiriesForCustomer = (customerId) => client.get('/sales/enquiries/customer/' + customerId)

  getEnquiry = (id) => client.get('/sales/enquiries/' + id)
  deleteEnquiry = (id) => client.delete('/sales/enquiries/' + id)

  replyToEnquiry = (id, body) => client.put('/sales/enquiries/' + id, body)

  customerReplyToEnquiry = (id, body) => client.put('/sales/enquiries/' + id + '/customer-reply', body)

  getCustomerByID = (id) => client.get('/sales/customers/' + id)
  getCustomerInfo = (id) => client.get(`/sales/customers/${id}/info`)
  updateCustomer = (id, body) => client.put(`/sales/customers/${id}`, body)

  query = (body) => client.post('/sales/products/query', body)
  createOrder = (enquiryId, body) => client.post('/sales/enquiries/' + enquiryId + '/create-order', body)
  getOrders = () => client.get('/sales/orders')
  getOrder = (orderId) => client.get('/sales/orders/' + orderId)

  getProducts = () => client.get('/sales/products')
  getProductById = (productId) => client.get('/sales/products/' + productId)
  deleteProducts = () => client.delete('/sales/products')

  createApiKey = () => client.post('/sales/accounts/create-api-key')

  upload = (url, file, extra) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('fileName', file.name)

    if (extra) {
      Object.entries(extra).forEach(([name, value]) => {
        formData.append(name, JSON.stringify(value))
      })
    }

    return client.post(url, formData, { headers: { 'content-type': 'multipart/form-data' } })
  }

  uploadProducts = (file, labels) => this.upload('/sales/products', file, { labels })
  getProductLabels = () => client.get('/sales/products/labels')

  getSalesforceDefinitions = async (name) => {
    const { description } = await client.get('/sales/salesforce/definitions/' + name)

    return description?.fields
      .filter(({ idLookup, defaultedOnCreate }) => !idLookup)
      .map(({ compoundFieldName, name, type, label, picklistValues, custom, nillable, updateable }) => ({
        compoundFieldName,
        name,
        type,
        label,
        picklistValues,
        custom,
        disabled: !updateable,
        optional: nillable,
        validate: !nillable && { type: 'isNotEmpty', message: 'Sould not be empty!' },
      }))
  }

  sign = ({ _id, _rev }, urlKey) => client.post('/sales/opportunities/sign' + (urlKey ? '/' + urlKey : ''), { _id, _rev })
}

const sales = new SalesApi()
export default sales
