import { Text, Button, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import Preload, { usePreload } from '../../../components/PreLoad/PreLoad'

import orders from 'api/orders'

import css from './Form.module.css'
import { useState } from 'react'

const EnquiryForm = ({ apiKey }) => {
  const resolve = usePreload()
  const { onUpdate, data } = resolve

  const [retry, setRetry] = useState()
  const disabled = !!data && !retry

  const form = useForm({
    initialValues: {
      firstName: 'John',
      lastName: 'Smith',
      email: '',
      phone: '+1 646 388 7899',
      userQuery: `
Hello,

I'd like to buy a 1500 sets of tires Dunlop 80R20 and 300 pieces of Goodyear 235/50R17 .
i'd like all of these to be in one single shipment/ delivery.
Can you deliver to Austin, Texas? I'm happy to pay for out of state delivery.

Thanks
`,
    },
  })

  const onSubmit = (body) => {
    setRetry(false)
    onUpdate(() => orders.createEnquiry(body, apiKey))
  }

  return (
    <Preload {...resolve}>
      <form className={css.form} onSubmit={form.onSubmit(onSubmit)}>
        <div className={css.row}>
          <TextInput disabled={data} required label="First name" placeholder="First name" {...form.getInputProps('firstName')} />

          <TextInput disabled={data} required label="Last name" placeholder="Last name" {...form.getInputProps('lastName')} />
        </div>

        <div className={css.row}>
          <TextInput disabled={data} required label="Email" placeholder="Your email" {...form.getInputProps('email')} />

          <TextInput disabled={data} label="Phone" placeholder="Your phone" {...form.getInputProps('phone')} />
        </div>
        <Textarea disabled={disabled} label="Enquiry" placeholder="Your enquiry" rows={10} {...form.getInputProps('userQuery')} />

        {!disabled && (
          <div className="edit-user__form__actions">
            <Button type="submit" variant="default" color="gray" size="sm" radius="sm">
              <Text size="xs">Send</Text>
            </Button>
          </div>
        )}
      </form>

      {!!data?.result?.length && <div>Enquiry submitted</div>}
    </Preload>
  )
}

export default EnquiryForm
