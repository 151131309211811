import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { BooksStore } from './BooksStore'
import { OpportunityData } from './OpportunityStore'

type BasketItemDetails = { quantity: number; unitCost?: number; unitPrice?: number }
type BasketItem = { product: any; basketLine: BasketItemDetails }
type Selected = { [key: string]: BasketItemDetails }
//-----------------------------------------------------------------------------
export const totalDetails = (products: { basketLine: { totalPrice: number } }[], taxPercent = 15) => {
  const subTotal = products.reduce((buf: number, { basketLine: { totalPrice } }) => (buf += totalPrice), 0)

  const tax = Math.round(subTotal * taxPercent) / 100
  const total = subTotal + tax

  return { subTotal, tax, total, taxPercents: taxPercent }
}

//-----------------------------------------------------------------------------
export default class BasketStore {
  //---------------------------------------------------------------------------
  taxPercentage: number
  //must not update the basket or item pricing
  isSnapshot: boolean
  _products = {}
  _selected: Selected = {}
  initial: BasketItem[] = []
  keep = false
  booksStore: BooksStore | null = null

  //---------------------------------------------------------------------------
  constructor({
    keep = false,
    taxPercentage = 15,
    isSnapshot = true,
    booksStore,
  }: { keep?: boolean; taxPercentage?: number; isSnapshot?: boolean; booksStore?: BooksStore | null } = {}) {
    this.keep = keep
    this.isSnapshot = isSnapshot
    this.taxPercentage = taxPercentage
    if (booksStore) this.booksStore = booksStore
    makeAutoObservable(this)
  }

  //---------------------------------------------------------------------------
  fromSearchResults = (searchResults: { suggestedProducts: any[]; quantity: any }[]) => {
    const products: any = []
    searchResults.forEach(({ suggestedProducts, quantity }) => {
      suggestedProducts.forEach((product: any, index: any) => products.push({ product, basketLine: { quantity: index ? 0 : quantity } }))
    })
    return products
  }

  addProductToBasket = (product: any, quantity: number = 1) => {
    runInAction(() => {
      this._products[product._key] = product
      this._selected[product._key] = this.calculateBasketLine(product, { quantity })
    })
  }
  //---------------------------------------------------------------------------
  updateProductQuantity = (product: any, quantity: number) => {
    if (!product || !product._key) return
    runInAction(() => {
      this._products[product._key] = product
      this._selected[product._key] = this.calculateBasketLine(product, { ...this._selected[product._key], quantity })

      if (!quantity && !this.keep) {
        delete this._products[product._key]
        delete this._selected[product._key]
      }
    })
  }

  //---------------------------------------------------------------------------
  revert = () => {
    this.initWithProducts(this.initial)
  }

  basketSize = () => {
    return Object.values(this._selected).reduce((buf: number, { quantity }) => (buf += quantity), 0)
  }

  calculateProductSpecs = (product: any) => {
    const {
      _key, //
      _id,
      _rev,
      Name,
      Description,
      ProductCode,
      sellingPrice,
      photos,
      currency,
      createdAt,
      updatedAt,
      type,
      ownerAccountId,
      source,
      ...rest
    } = product
    const specs =
      source !== 'salesforce' &&
      Object.entries(rest)
        .filter(([n, v]) => v)
        .map(([label, value]) => ({ label, value }))

    return specs
  }

  //---------------------------------------------------------------------------
  calculateBasketLine = (product: any, lineDetails: any = {}) => {
    let unitPrice = lineDetails.unitPrice || 0
    let unitCost = lineDetails.unitCost || 0
    if (!this.isSnapshot && this.booksStore) {
      unitPrice = this.booksStore.getSelectedPricebookPrice(product._key)
      unitCost = this.booksStore.getSelectedCostbookPrice(product._key)
    }
    const totalPrice = unitPrice * lineDetails.quantity
    const totalCost = unitCost * lineDetails.quantity
    const totalMargin = (unitPrice - unitCost) * lineDetails.quantity
    const marginPercent = (totalMargin / totalCost) * 100

    return { quantity: lineDetails.quantity, unitPrice, unitCost, totalPrice, totalCost, totalMargin, marginPercent }
  }

  recalculateBasket = () => {
    const recalculated = Object.fromEntries(
      Object.entries(this._selected)
        // .filter(([_, details]) => details.quantity > 0)
        .map(([key, details]) => {
          const product = this._products[key]
          return [key, this.calculateBasketLine(product, details)]
        })
    )
    runInAction(() => {
      this._selected = recalculated
    })
  }
  getBasketLineForProduct = (key: string) => {
    return this._selected[key]
  }

  get basket() {
    return this._selected
  }

  //---------------------------------------------------------------------------
  get basketProducts() {
    return Object.entries(this._selected).map(([key, details]) => {
      const product = this._products[key]
      return {
        product,
        basketLine: this.calculateBasketLine(product, details),
        specs: this.calculateProductSpecs(product),
      }
    })
  }

  //---------------------------------------------------------------------------
  get totalDetails() {
    return totalDetails(this.basketProducts, this.taxPercentage)
  }

  //---------------------------------------------------------------------------
  get totalProducts() {
    return Object.values(this._selected).length
  }

  //---------------------------------------------------------------------------
  get selected() {
    const selected = toJS(this._selected)
    return Object.fromEntries(Object.entries(selected).filter(([_, details]) => details && details.quantity > 0))
  }

  //---------------------------------------------------------------------------
  static fromOpportunity = (opportunity: OpportunityData, booksStore: BooksStore, isSnapshot = false) => {
    const orderOrQuote = opportunity.order || opportunity.quote
    if (orderOrQuote && orderOrQuote.basket && orderOrQuote.basket.length > 0)
      return BasketStore.fromQuoteOrOrder(orderOrQuote, booksStore, isSnapshot)
    return BasketStore.fromSuggestedProducts(opportunity.thread[0].searchResults, booksStore, isSnapshot)
  }
  //---------------------------------------------------------------------------
  static fromQuoteOrOrder = (quoteOrOrder: any, booksStore: BooksStore, isSnapshot = false) => {
    const basket = new BasketStore({ isSnapshot, booksStore })
    basket.initWithProducts(quoteOrOrder.basket)
    return basket
  }
  //---------------------------------------------------------------------------
  static fromSnapshot = (thread: any) => {
    const basket = new BasketStore({ keep: true, booksStore: null, isSnapshot: true })
    basket.initWithProducts(thread.basket)
    return basket
  }
  //---------------------------------------------------------------------------
  static fromSuggestedProducts = (suggestedProducts: any[] = [], booksStore: BooksStore, isSnapshot = false) => {
    const basket = new BasketStore({ isSnapshot, booksStore })
    const products = basket.fromSearchResults(suggestedProducts)
    basket.initWithProducts(products)
    return basket
  }

  //---------------------------------------------------------------------------
  initWithProducts = (products: BasketItem[] = []) => {
    this.initial = products

    this._selected = {}
    this._products = {}

    products?.forEach(({ product, basketLine }) => {
      this._products[product._key] = product
      this._selected[product._key] = basketLine
    })

    this.recalculateBasket()
  }
}
