import { useState } from 'react'
import { Button, Timeline, Textarea, Avatar, ThemeIcon, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'

// Import the css file without type checking
// @ts-ignore
import css from './Thread.module.css'
import { OpportunityStore, OpportunityData } from '@/stores/OpportunityStore'
import SuggestedProducts from './SuggestedProducts/SuggestedProducts'
import TermsForm from '../Terms/Form/TermsForm'
import TimelineContent from './Timeline/TimelineContent'
import TermsView from '../Terms/View/TermsView'
import TimelineTitle from './Timeline/TimelineTitle'
import Spinner from 'components/Spinner/Spinner';

const Thread = observer(({ opportunityStore }: { opportunityStore: OpportunityStore }) => {
  const [message, setMessage] = useState<string>('')
  const [terms, setTerms] = useState<any>()

  const opportunity = opportunityStore.opportunity as any
  const {
    account,
    customer,
    status,
    order,
    view,
    urlKey,
    thread = [],
    quote,
    settings
  } = opportunity || {}

  const isNew = status === 'new'
  const isQuote = !order
  const isOrder = !!order

  const updateQuote = () => {
    setMessage('')
    if (view && urlKey) {
      opportunityStore.customerReply(urlKey, message)
    } else {
      opportunityStore.reply(message, terms)
    }
  }

  const onCreateOrder = () => {
    setMessage('')
    opportunityStore.createOrder(message, terms)
  }
  const company = { account, customer }

  if (opportunityStore.isLoading) return <Spinner />

  // console.log('Thread', JSON.parse(JSON.stringify({
  //   books: opportunityStore.bookStore,
  //   opportunity: opportunityStore.opportunity,
  //   basket: opportunityStore.basketStore
  // })))

  return (
    <div className={css.section}>
      <Timeline active={thread.length} bulletSize={40} lineWidth={1}>
        {thread.map((item: any, key: number) => {
          return (
            <Timeline.Item
              bullet={
                <ThemeIcon variant="white" radius={'xl'} size={38}>
                  <Avatar radius="xl" src={company[item.from]?.logo} alt={`Logo ${company[item.from]?.name}`} size={32} />
                </ThemeIcon>
              }
              title={<TimelineTitle quote={quote} customer={customer} account={account} item={item} />}
              key={`ti-${key}`}
            >
              <TimelineContent item={item} customer={customer} status={status} />
            </Timeline.Item>
          )
        })}
        {urlKey ? (
          <Timeline.Item
            bullet={
              <ThemeIcon variant="white" radius={'xl'} size={38}>
                <Avatar radius="xl" src={customer?.logo} alt={customer?.name} size={32} />
              </ThemeIcon>
            }
            title="Reply"
          >
            {isQuote && (
              <div>
                <SuggestedProducts basketStore={opportunityStore.basketStore!} label="Previous quote" isCustomerView={opportunityStore.isCustomerView} />
              </div>
            )}

            <div>
              <Textarea autosize resize="both" value={message} onChange={({ target }) => setMessage(target.value)} minRows={3} w={600} placeholder="Message..." />
            </div>

            <div className={css.actions}>
              <Button onClick={() => updateQuote()}>Submit</Button>
            </div>
          </Timeline.Item>
        ) : (
          <Timeline.Item
            bullet={
              <ThemeIcon variant="white" radius={'xl'} size={38}>
                <Avatar radius="xl" src={account?.logo} alt={account?.name} size={32} />
              </ThemeIcon>
            }
            title="Reply"
          >
            {isQuote && (
              <div>
                <SuggestedProducts basketStore={opportunityStore.basketStore!} label="Update existing quote" isCustomerView={opportunityStore.isCustomerView} />
                {/* <TermsForm onChange={setTerms} list={settings?.availableTerms} terms={quote?.terms} /> */}
                <TermsView terms={quote?.terms} />
              </div>
            )}

            <div>
              <Textarea autosize resize="both" value={message} onChange={({ target }) => setMessage(target.value)} minRows={3} w={600} placeholder="Message..." />
            </div>

            <div className="gap-05">
              <Button onClick={updateQuote} variant="outline" color="green" size="sm" radius="sm">
                <Text size="xs">{isNew ? 'Create' : isOrder ? 'Revert to' : 'Update '} quote</Text>
              </Button>

              {isQuote && (
                <Button variant="default" color="gray" size="sm" radius="sm" onClick={onCreateOrder}>
                  <Text size="xs">Create order</Text>
                </Button>
              )}
            </div>
          </Timeline.Item>
        )}
      </Timeline>
    </div>
  )
})

export default Thread 