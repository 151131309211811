import './Spinner.scss'

const Spinner = (props) => {
  return (
    <div className="Spinner">
      <div className="c1" />
      <div className="c2" />
      <div className="c3" />
    </div>
  )
}

export default Spinner
