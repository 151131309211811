import { client } from './http'

class AdminApi {
  getUser = (uid) => client.get(`/admin/users/${uid}`)
  getUsers = () => client.get('/admin/users')
  updateUser = (uid, data) => client.put(`/admin/users/${uid}`, data)
  deleteUser = (uid) => client.delete(`/admin/users/${uid}`)
  createUser = (user) => client.post('/admin/users', user)
  getUserJWT = (uid) => client.get(`/admin/users/${uid}/login`)
  unassingFromAccount = (userId) => client.delete(`/admin/users/${userId}/account`)

  getAccounts = () => client.get('/admin/accounts')
  getAccountById = (accountId) => client.get(`/admin/accounts/${accountId}`)
  createAccount = (data) => client.post('/admin/accounts', data)
  updateAccount = (accountId, data) => client.put(`/admin/accounts/${accountId}`, data)
  deleteAccount = (accountId) => client.delete(`/admin/accounts/${accountId}`)
}

const admin = new AdminApi()
export default admin
