import { useState, useEffect, useCallback, useMemo } from 'react'

import { Group, TextInput } from '@mantine/core'
import TableFilter from './Filter/Filter'
import TableRow from './Row/Row'
import TablePagging from './Pagging/Pagging'
import ActionTableHeader from './ActionHeader/ActionHeader'
import Create, { CreateType } from './Create/Create'

// @ts-ignore
import css from './Table.module.css'
import { ExcelExporter } from 'utils/excelExport'
import { TableSearcher, SearchOptions } from 'utils/TableSearcher'

type FilterType = {
  key?: string
  render?: (item?: any) => any
}
type SearchOptionsType = SearchOptions

type ColumnsType = {
  title?: string
  label?: string
  textAlign?: 'left' | 'center' | 'right' | string
  render?: (item: any, actions: { showContent: (fn: (v: any) => boolean) => any; showConfirm: () => any }) => any
  content?: (item: any, actions: { setContent: (body: any) => any; close: () => void }) => any
  id?: string
  key?: string
  name?: string
  ellipsis?: number
  icon?: any
  style?: React.CSSProperties
}

type TableProps = {
  label?: string
  data: any
  columns: ColumnsType[]
  rowKey?: any
  children?: any
  filter?: FilterType
  orderBy?: any
  sticky?: boolean
  limit?: number
  footer?: boolean
  displayExtendedMenu?: boolean
  style?: React.CSSProperties
  searchOptions?: SearchOptionsType
  create?: CreateType | false
}

//-----------------------------------------------------------------------------------------------
const Table = ({
  label,
  data, //
  columns,
  rowKey,
  children,
  filter,
  orderBy = [],
  sticky,
  limit = 50,
  footer = true,
  displayExtendedMenu = false,
  style,
  searchOptions,
  create,
}: TableProps) => {
  const defaultFilter = useMemo(() => orderBy[0] || 'All', [orderBy])

  const [rows, setRows] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const [hidden, setHidden] = useState({})
  const [selected, setSelected] = useState(defaultFilter)
  const [isExporting, setIsExporting] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredRows, setFilteredRows] = useState<any[]>([])
  const searcher = useMemo(() => (searchOptions ? new TableSearcher(searchOptions).setData(rows) : null), [searchOptions])

  useEffect(() => {
    setPage(0)
  }, [data, limit])

  const from = page * limit
  const displayRows = filteredRows.slice(from, from + limit)
  const visibleColumns = columns.filter((col) => !hidden[col.title || ''])

  const onUpdate = useCallback((list, name) => {
    setSelected(name)
    setRows(Array.isArray(list) ? [...list] : [])
    setFilteredRows(Array.isArray(list) ? [...list] : [])
    setPage(0)
  }, [])

  useEffect(() => {
    setSelected(defaultFilter)
  }, [defaultFilter])

  useEffect(() => {
    if (!Array.isArray(data) || !data.length) {
      setRows([])
      setFilteredRows([])
      return
    }

    if (filter) {
      const initGroups = data.reduce(
        (acc, item) => {
          const key = filter.render ? filter.render(item) : item[filter.key || '']

          acc[key] = acc[key] || []
          acc[key].push(item)
          return acc
        },
        { All: data }
      )

      const filteredData = initGroups[selected] || data
      setRows(filteredData)
      setFilteredRows(filteredData)
    } else {
      setRows(data)
      setFilteredRows(data)
    }
  }, [data, selected, filter])

  useEffect(() => {
    if (searcher && searchQuery) {
      const results = searcher.search(searchQuery)
      setFilteredRows(results)
      setPage(0)
    } else if (rows.length) {
      setFilteredRows(rows)
    }
  }, [searchQuery, rows, searcher])

  useEffect(() => {
    const maxPage = Math.max(0, Math.ceil(filteredRows.length / limit) - 1)
    if (page > maxPage && maxPage >= 0) {
      setPage(maxPage)
    }
  }, [filteredRows, limit, page])

  const onExportData = () => {
    try {
      setIsExporting(true)
      if (filteredRows && filteredRows.length > 0) {
        const exporter = new ExcelExporter().addSheet(filteredRows, 'Products')
        exporter.download(`${new Date().toISOString()} broadn-products-export.xlsx`)
      }
    } catch (error) {
      console.error('Export failed:', error)
    } finally {
      setIsExporting(false)
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  return (
    <div>
      <div className={css.head}>
        <h4>{label}</h4>
        {create && <Create {...create} />}
      </div>

      <div className={css.table} style={{ ...style }}>
        <Group
          justify="flex-end"
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            background: 'white',
            borderBottom: 'solid 1px var(--border-color)',
            width: '100%',
          }}
        >
          {data && filter ? (
            <TableFilter data={data} selected={selected} filter={filter} onUpdate={onUpdate} orderBy={orderBy} />
          ) : (
            <div></div>
          )}
          {searchOptions && (
            <TextInput
              size="xs"
              w={250}
              placeholder="Search ..."
              value={searchQuery}
              onChange={handleSearch}
              style={{ padding: '4px 0px', borderRadius: '4px' }}
            />
          )}

          {displayExtendedMenu && (
            <ActionTableHeader
              columns={columns}
              hiddenColumns={hidden}
              onColumnsChange={setHidden}
              triggerExport={() => onExportData()}
              isExporting={isExporting}
            />
          )}
        </Group>

        <div className={css.tableContent}>
          <table>
            <thead className={css.thead}>
              <tr className={css.tr}>
                {visibleColumns.map((item, key) => (
                  <th className={css.th} key={key} style={{ ...item.style }}>
                    <div className={css.header} style={{ justifyContent: item.textAlign }}>
                      {item.icon && <div>{item.icon}</div>}
                      {item.title || item.label}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={css.tbody}>
              {filteredRows.length === 0 ? (
                <tr>
                  <td colSpan={visibleColumns.length} style={{ textAlign: 'center', padding: '20px' }}>
                    No data available
                  </td>
                </tr>
              ) : (
                displayRows.map((row, index) => (
                  <TableRow row={row} columns={visibleColumns} key={row[rowKey] || `${page}-${index}`} index={undefined} />
                ))
              )}
            </tbody>
            {children}
          </table>
        </div>
        {footer && (
          <div style={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 5 }}>
            <TablePagging rows={filteredRows} limit={limit} page={page} setPage={setPage} />
          </div>
        )}
      </div>
    </div>
  )
}

//-----------------------------------------------------------------------------------------------
export default Table
