import { Link } from 'react-router-dom'
import { Avatar, Button, ActionIcon } from '@mantine/core'
import { IconEye, IconProgressCheck, IconTrashX } from '@tabler/icons-react'
import dayjs from 'dayjs'

import { PageHeader } from 'components/PageHeader/PageHeader'
import { Preload, usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import auth from 'api/auth'
import admin from 'api/admin'

const breadcrumbs = [
  { label: 'Home', href: '/' }, //
  { label: 'Admin', href: '/admin' },
  { label: 'Users' },
]

const Users = () => {
  const resolve = usePreload(async () => {
    const [users, accounts] = await Promise.all([admin.getUsers(), admin.getAccounts()])
    return { users, accounts }
  })
  const { data, onRetry } = resolve

  const columns = [
    {
      render: ({ profileImageUrl, displayName }) => (
        <Avatar src={profileImageUrl || '/user.jpg'} alt={displayName} radius="xl" size={24} w={24} color="violet" variant="subtle" />
      ),
    },
    {
      title: 'Full name',
      render: ({ displayName, account }) => (
        <div>
          <b>{displayName}</b>
          <div>{account?.name}</div>
        </div>
      ),
    },
    {
      title: 'Joined',
      render: ({ createdAt }) => <time>{dayjs(createdAt).format('DD/MM/YYYY HH:mm')}</time>,
    },
    {
      title: 'Email',
      id: 'email',
    },
    {
      title: 'Roles',
      render: ({ roles }) => (roles || []).join(', '),
    },
    {
      title: 'Account Roles',
      render: ({ accountRoles }) => (accountRoles || []).join(', '),
    },
    {
      title: 'Providers',
      render: ({ providers }) => Object.keys(providers || []).join(', '),
    },
    {
      textAlign: 'right',
      render: ({ _key }) => (
        <Button
          component={Link}
          to={`/admin/users/${_key}`}
          // leftSection={<IconEye size="1rem" stroke={1} />}
          variant="subtle"
          size="xs"
        >
          Open
        </Button>
      ),
    },
    {
      textAlign: 'right',
      render: ({ _key }) => (
        <Button
          onClick={async () => {
            const { jwt } = (await admin.getUserJWT(_key)) as any
            auth.setToken(jwt)
          }}
          leftSection={<IconProgressCheck size="1rem" stroke={1} />}
          variant="subtle"
          size="xs"
        >
          LogIn
        </Button>
      ),
    },
    {
      render: ({ _key }, { showConfirm }) => (
        <ActionIcon
          variant="light" //
          color="red"
          onClick={() => showConfirm(() => onRetry(() => admin.deleteUser(_key)))}
        >
          <IconTrashX size={18} stroke={1.5} />
        </ActionIcon>
      ),
    },
  ]

  const create = {
    label: 'Invite user',
    form: [
      { label: 'Email', name: 'email' }, //
      { label: 'First name', name: 'firstName' },
      { label: 'LastName', name: 'lastName' },
      {
        label: 'Assign to company',
        name: 'accountId',
        type: 'select',
        optional: true,
        data: data?.accounts?.map(({ _key, name }) => ({ label: name, value: _key })),
      },
      {
        label: 'Account roles',
        name: 'accountRoles',
        type: 'Checkbox',
        data: [
          { label: 'Owner', value: 'owner' },
          { label: 'User', value: 'user' },
          { label: 'Viewer', value: 'viewer' },
          { label: 'Approver', value: 'approver' },
          { label: 'Signatory', value: 'signatory' },
        ],
      },
    ],
    onSubmit: (values) => onRetry(() => admin.createUser(values)),
  }

  const filter = {
    key: 'account.name',
  }

  return (
    <div>
      <PageHeader title="All users" breadcrumbs={breadcrumbs} />

      <Preload {...resolve}>
        <Table data={data?.users} columns={columns} filter={filter} create={create} />
      </Preload>
    </div>
  )
}

export default Users
