import { makeAutoObservable, toJS } from 'mobx';
import MixpanelAnalytics from './mixpanelAnalytics';

export interface QuestionOption {
  id: string;
  label: string;
  value: string | number | boolean | null;
}

export interface SimpleQuestionDependency {
  id: string;
  validWhen: string[];
}

export interface ComplexQuestionDependency {
  operator: 'AND' | 'OR' | 'NOT';
  conditions: (SimpleQuestionDependency | ComplexQuestionDependency)[];
}

export type QuestionDependency = SimpleQuestionDependency | ComplexQuestionDependency;

export interface QuestionItem {
  id: string;
  name: string;
  description: string;
  howToFind: string;
  group: string;
  options?: QuestionOption[];
  dependsOn?: QuestionDependency;
  multiSelect?: boolean;
  type: 'radio' | 'checkbox' | 'select' | 'numeric' | 'text' | 'textarea';
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  optional?: boolean;
}

export interface FormField {
  id: string;
  label: string;
  type: string;
  validation?: string;
  optional: boolean;
}

export interface RequestorDetails {
  fields: FormField[];
}

export interface QuestionnaireData {
  questions: QuestionItem[];
  requestorDetails: RequestorDetails;
}

export class QuestionnaireStore {
  questionnaire: QuestionnaireData;
  answers: Record<string, any> = {};
  activeQuestionIndex: number = 0;
  answeredQuestionIds: string[] = [];
  invalidQuestionIds: string[] = [];
  validationError: string | null = null;
  mixpanelToken?: string;
  sessionId: string = '';
  analyticsInitialized: boolean = false;
  previousAnswers: Record<string, any> = {};
  formSubmitted: boolean = false;
  touchedFields: Set<string> = new Set();

  constructor(questionnaire: QuestionnaireData, mixpanelToken?: string) {
    this.questionnaire = questionnaire;
    this.mixpanelToken = mixpanelToken;
    this.initializeAnswers();
    makeAutoObservable(this);
    this.initializeAnalytics();
  }

  private initializeAnswers() {
    // Initialize question answers
    this.questionnaire.questions.forEach(question => {
      this.answers[question.id] = question.multiSelect ? [] : '';
    });

    // Initialize requestor details
    this.questionnaire.requestorDetails.fields.forEach(field => {
      this.answers[field.id] = '';
    });
  }

  private initializeAnalytics() {
    if (!this.mixpanelToken) return;
    const analyticsData = MixpanelAnalytics.init(this.mixpanelToken);
    this.sessionId = analyticsData.sessionId;
    this.analyticsInitialized = analyticsData.initialized;
  }

  setAnswer(questionId: string, value: any) {
    this.answers[questionId] = value;
    this.touchedFields.add(questionId);
    this.updateAnsweredQuestions();
    this.handleAnswerDependencies();
    this.removeInvalidQuestionIfAnswered(questionId);
    this.advanceActiveQuestionIfNeeded();
    this.trackQuestionAnswer(questionId);
  }

  private updateAnsweredQuestions() {
    const requesterFieldIds = this.questionnaire.requestorDetails.fields.map(f => f.id);
    
    this.answeredQuestionIds = Object.entries(this.answers)
      .filter(([key, value]) => {
        if (requesterFieldIds.includes(key)) return false;
        return Array.isArray(value) ? value.length > 0 : value !== '';
      })
      .map(([key]) => key);
  }

  private removeInvalidQuestionIfAnswered(questionId: string) {
    if (this.invalidQuestionIds.includes(questionId)) {
      const value = this.answers[questionId];
      if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== '')) {
        this.invalidQuestionIds = this.invalidQuestionIds.filter(id => id !== questionId);
      }
    }
  }

  private handleAnswerDependencies() {
    const formQuestions = this.questionnaire.questions;
    const requesterFieldIds = this.questionnaire.requestorDetails.fields.map(f => f.id);

    formQuestions.forEach((item, index) => {
      const questionId = item.id;
      if (requesterFieldIds.includes(questionId)) return;

      if (this.answers[questionId] && 
          ((Array.isArray(this.answers[questionId]) && this.answers[questionId].length > 0) ||
           (!Array.isArray(this.answers[questionId]) && this.answers[questionId])) &&
          !this.shouldShowQuestion(index)) {
        const initialValue = item.multiSelect ? [] : '';
        this.answers[questionId] = initialValue;
      }
    });
  }

  private advanceActiveQuestionIfNeeded() {
    const currentActiveQuestion = this.questionnaire.questions[this.activeQuestionIndex];
    if (!currentActiveQuestion) return;

    if (this.answeredQuestionIds.includes(currentActiveQuestion.id) && 
        this.activeQuestionIndex < this.questionnaire.questions.length - 1) {
      let nextIndex = this.activeQuestionIndex + 1;
      while (nextIndex < this.questionnaire.questions.length && !this.shouldShowQuestion(nextIndex)) {
        nextIndex++;
      }
      this.activeQuestionIndex = nextIndex;
    }
  }

  private trackQuestionAnswer(questionId: string) {
    if (!this.mixpanelToken || !this.sessionId || !this.analyticsInitialized) return;
    
    const currentValue = this.answers[questionId];
    const previousValue = this.previousAnswers[questionId];
    const requesterFieldIds = this.questionnaire.requestorDetails.fields.map(f => f.id);
    
    if (requesterFieldIds.includes(questionId)) return;
    
    if (JSON.stringify(currentValue) !== JSON.stringify(previousValue) &&
        ((Array.isArray(currentValue) && currentValue.length > 0) ||
         (!Array.isArray(currentValue) && currentValue))) {
      
      const question = this.questionnaire.questions.find(item => item.id === questionId);
      if (question) {
        const questionIndex = this.questionnaire.questions.findIndex(q => q.id === questionId);
        const questionNumber = this.questionnaire.questions.filter((q, idx) => {
          return idx < questionIndex && this.shouldShowQuestion(idx);
        }).length + 1;

        MixpanelAnalytics.trackQuestionAnswer({
          questionId: question.id,
          questionName: question.name,
          questionGroup: question.group,
          questionNumber,
          sessionId: this.sessionId
        });
      }
    }
    
    this.previousAnswers[questionId] = JSON.parse(JSON.stringify(currentValue));
  }

  setActiveQuestionIndex(index: number) {
    this.activeQuestionIndex = index;
  }

  setValidationError(error: string | null) {
    this.validationError = error;
    
    if (error === null) {
      this.invalidQuestionIds = [];
    }
     
    if (error) {
      setTimeout(() => {
        this.validationError = null;
      }, 4000);
    }
  }

  get isQuestionAnswered() {
    return (questionId: string) => this.answeredQuestionIds.includes(questionId);
  }

  get isQuestionActive() {
    return (index: number) => index === this.activeQuestionIndex;
  }
  
  get visibleQuestionNumber() {
    return (questionIndex: number) => {
      return this.questionnaire.questions.filter((q, idx) => {
        return idx < questionIndex && this.shouldShowQuestion(idx);
      }).length + 1;
    };
  }

  private evaluateSimpleDependency(dependency: SimpleQuestionDependency): boolean {
    const { id, validWhen } = dependency;
    const currentValue = this.answers[id];

    if (!currentValue) return false;

    if (Array.isArray(currentValue)) {
      const selectedOptionIds = currentValue.map(v => typeof v === 'object' ? v.id : v);
      return validWhen.some(validValue => selectedOptionIds.includes(validValue));
    }

    const currentOptionId = typeof currentValue === 'object' ? currentValue.id : currentValue;
    return validWhen.includes(currentOptionId);
  }

  evaluateDependency(dependency: QuestionDependency): boolean {
    if (!dependency) return true;

    if ('validWhen' in dependency) {
      return this.evaluateSimpleDependency(dependency);
    }

    const { operator, conditions } = dependency;

    switch (operator) {
      case 'AND':
        return conditions.every(condition => this.evaluateDependency(condition));
      case 'OR':
        return conditions.some(condition => this.evaluateDependency(condition));
      case 'NOT':
        return !this.evaluateDependency(conditions[0]);
      default:
        return false;
    }
  }

  isQuestionDependencySatisfied(item: QuestionItem): boolean {
    if (!item || !item.dependsOn) return true;
    return this.evaluateDependency(item.dependsOn);
  }

  get shouldShowQuestion() {
    return (index: number) => {
      const item = this.questionnaire.questions[index];
      if (!item) return false;
      const isActiveOrPrevious = index <= this.activeQuestionIndex;
      const dependencySatisfied = this.isQuestionDependencySatisfied(item);
      return isActiveOrPrevious && dependencySatisfied;
    };
  }

  validateAndSubmit(): {valid: boolean, data?: Record<string, any>} {
    this.formSubmitted = true;
    const values = this.answers;
    const requiredFields = this.questionnaire.requestorDetails.fields
      .filter(field => !field.optional)
      .map(field => field.id);

    const missingRequiredFields = requiredFields
      .filter(fieldId => values[fieldId] === undefined || values[fieldId] === null || values[fieldId] === '')
      .map(fieldId => {
        const field = this.questionnaire.requestorDetails.fields.find(f => f.id === fieldId);
        return field ? field.label : fieldId;
      });

    const invalidQuestionIds = this.questionnaire.questions
      .map((item, index) => ({ item, index }))
      .filter(({ item, index }) => this.shouldShowQuestion(index))
      .filter(({ item }) => {
        if (item.optional) return false;
        const value = values[item.id];
        if (value === undefined || value === null) return true;
        return Array.isArray(value) ? value.length === 0 : value === '';
      })
      .map(({ item }) => item.id);

    const unansweredQuestions = invalidQuestionIds
      .map(id => this.questionnaire.questions.find(q => q.id === id)?.name || id);

    const allMissingFields = [...unansweredQuestions, ...missingRequiredFields];

    if (allMissingFields.length > 0) {
      this.invalidQuestionIds = invalidQuestionIds;
      this.setValidationError(`Please answer all required fields. Currently missing: ${allMissingFields.join(', ')}`);
      return {valid: false};
    }

    this.setValidationError(null);

    if (this.mixpanelToken && this.sessionId && this.analyticsInitialized && values.email) {
      MixpanelAnalytics.trackFormSubmission({
        email: values.email,
        name: values.name || '',
        sessionId: this.sessionId
      });
    }

    return {valid: true, data: toJS(this.answers)};
  }

  reset() {
    this.initializeAnswers();
    this.activeQuestionIndex = 0;
    this.answeredQuestionIds = [];
    this.invalidQuestionIds = [];
    this.validationError = null;
    this.previousAnswers = {};
    this.formSubmitted = false;
    this.touchedFields.clear();
  }

  getQuestionDisplayValue(questionId: string): string {
    const question = this.questionnaire.questions.find(q => q.id === questionId);
    if (!question) return '';
    
    const value = this.answers[questionId];
    
    if (Array.isArray(value)) {
      const selectedLabels = value.map(v => {
        const options = question.options || [];
        const option = options.find(opt => opt.id === v);
        return option ? option.label : v;
      });
      return selectedLabels.join(', ');
    } else if (question.type === 'text' || question.type === 'textarea' || question.type === 'numeric') {
      return value || '';
    } else {
      const options = question.options || [];
      const option = options.find(opt => opt.id === value);
      return option ? option.label : (value || '');
    }
  }

  get getQuestionsByGroup() {
    const groups: Record<string, QuestionItem[]> = {};
    
    this.questionnaire.questions.forEach(item => {
      const group = item.group || 'Other';
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(item);
    });
    
    return groups;
  }

  get getAnsweredQuestionsByGroup() {
    const answeredItems = this.questionnaire.questions.filter(item => {
      const index = this.questionnaire.questions.findIndex(q => q.id === item.id);
      return this.isQuestionAnswered(item.id) && this.shouldShowQuestion(index);
    });

    const groups: Record<string, QuestionItem[]> = {};
    
    answeredItems.forEach(item => {
      const group = item.group || 'Other';
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(item);
    });
    
    return groups;
  }

  validateEmail(email: string): string | null {
    if (!this.formSubmitted && !this.touchedFields.has('email')) return null;
    
    const trimmedValue = email.trim();
    if (!trimmedValue) return 'Email is required';
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedValue)) {
      return 'Invalid email format';
    }
    if (email !== trimmedValue) {
      this.answers.email = trimmedValue;
    }
    return null;
  }

  validateZipCode(zipCode: string): string | null {
    if (!this.formSubmitted && !this.touchedFields.has('zipCode')) return null;
    
    if (!zipCode.trim()) return 'Zip code is required';
    return null;
  }

  shouldShowValidation(questionId: string): boolean {
    return this.formSubmitted || this.touchedFields.has(questionId);
  }
} 