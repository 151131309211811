import css from './View.module.css'

const View = ({ label, description, placeholder, name, defaultValue }) => {
  return (
    <div className={css.Row}>
      <div className={css.Label}>{label}</div>
      {defaultValue !== undefined ? <div className={css.Value}>{defaultValue}</div> : <div className={css.Placeholder}>{placeholder}</div>}
    </div>
  )
}

export default View
