import auth from 'api/auth'
import opportunitiesStore from '../../stores/opportunitiesStore'
import { observer } from 'mobx-react'
import { useEffect, useCallback } from 'react'
import { VscDashboard } from 'react-icons/vsc'
import { PiGraphThin } from 'react-icons/pi'
import {
  IconUsersGroup,
  IconSeeding,
  IconBriefcase,
  IconTags,
  IconPlugConnected,
  IconBrightness2,
  IconRoute,
  IconMailSpark,
  IconMail,
  IconCashRegister,
} from '@tabler/icons-react'
import { Badge, Group, Text, NumberFormatter, Stack, Indicator, Tooltip } from '@mantine/core'

import UserCard from '../UserCard/UserCard'
import NavList from './NavList'

const enquiryList = [{ label: 'Enquiry', Icon: IconMailSpark, to: '/enquiry' }]

const settingsList = [
  { label: 'Workflows', Icon: IconRoute, to: '/workflows' },
  { label: 'Integrations', Icon: IconPlugConnected, to: '/integrations' },
  { label: 'Settings', Icon: IconBrightness2, to: '/settings' },
]

const adminList = [
  { label: 'All users', Icon: IconUsersGroup, to: '/admin/users' },
  { label: 'All accounts', Icon: IconUsersGroup, to: '/admin/accounts' },
  { label: 'Email', Icon: IconMail, to: '/admin/emails' },
]

const onboardingList = [{ label: 'Onboarding', Icon: IconUsersGroup, to: '/' }]

const LayoutNav = () => {
  const user = auth.user
  const { roles, account } = user || {}

  const isUser = roles?.includes('user')
  const isAdmin = roles?.includes('admin')
  const isOnboarding = roles?.includes('onboarding')

  const fetchOpportunities = useCallback(() => {
    if (user && !isOnboarding) {
      opportunitiesStore.fetchOpportunities()
    }
  }, [user, isOnboarding])

  useEffect(() => {
    fetchOpportunities()
    const intervalId = setInterval(fetchOpportunities, 5 * 60 * 1000)
    return () => clearInterval(intervalId)
  }, [fetchOpportunities])

  const OpportunitiesMenuItem = opportunitiesStore.hasNewOpportunities ? (
    <Stack align="center" gap={0} align="flex-start">
      <Indicator color="green" processing size={7}>
        Opportunities
      </Indicator>
      {/* {opportunitiesStore.newOpportunitiesCount > 0 && (
        <Badge color="green" size="xs" variant="transparent" fw={300}>
          <Group gap="xs">
            <Text size="xs">{opportunitiesStore.newOpportunitiesCount} - </Text>
            <Text size="xs">
              <NumberFormatter prefix="$" value={opportunitiesStore.newOpportunitiesValue} thousandSeparator decimalScale={0} />
            </Text>
          </Group>
        </Badge>
      )} */}
    </Stack>
  ) : (
    'Opportunities'
  )

  const userList = [
    { label: 'Dashboard', Icon: VscDashboard, to: '/' },
    { label: OpportunitiesMenuItem, Icon: IconCashRegister, to: '/opportunities' },
    { label: 'Products', Icon: IconTags, to: '/products' },
    { label: 'Bundles', Icon: PiGraphThin, to: '/bundles' },
    { label: 'Leads', Icon: IconSeeding, to: '/leads' },
    { label: 'Customers', Icon: IconBriefcase, to: '/customers' },
  ]

  return (
    <div className="layout__nav">
      <div className="--sticky">
        <UserCard />

        {isUser && (
          <>
            {account && <NavList list={enquiryList} />}
            {account && <NavList list={userList} />}
            <NavList list={settingsList} />
          </>
        )}

        {isAdmin && <NavList list={adminList} />}
        {isOnboarding && <NavList list={onboardingList} />}
      </div>
    </div>
  )
}

export default observer(LayoutNav)
