import React, { useEffect } from 'react';
import { Textarea, Stack, Title, Radio, Group, SimpleGrid, Paper, Checkbox, Text, Grid, Box, Card, TextInput, Select, Container, Button, Alert } from '@mantine/core';
import { FiCheckSquare } from 'react-icons/fi';
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoWarningOutline } from "react-icons/io5";
import { QuestionnaireStore, QuestionItem, QuestionnaireData } from './QuestionnaireStore';
import opportunitiesApi from 'api/opportunities'
import { observer } from 'mobx-react-lite';


interface QuestionnaireProps {
  questionnaire: QuestionnaireData;
  broadnApiKey: string
  title?: string;
  description?: string;
  mixpanelToken?: string;
}

const Questionnaire = observer(({ questionnaire, title = "Questionnaire", description = '', mixpanelToken, broadnApiKey }: QuestionnaireProps) => {
  const store = React.useMemo(() => new QuestionnaireStore(questionnaire, mixpanelToken), [questionnaire, mixpanelToken, broadnApiKey]);
  const [isSubmitSuccess, setIsSubmitSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleSubmit = () => {
    setIsLoading(true);
    opportunitiesApi.verifyUserContact({
      email: store.answers.email || '',
      zipCode: store.answers.zipCode || '',
    }, broadnApiKey).then(async (response: any) => {
      if (response.email.isValidEmail && !response.email.isDisposable && response.zipCode.isValid) {
        const result = store.validateAndSubmit();
        if (result.valid && result.data) {
          result.data.formUrl = window.location.href
          await opportunitiesApi.sendInquiryForm(result.data, broadnApiKey).then((sendFormResponse: any) => {
          }).finally(() => {
            setIsSubmitSuccess(true);
            // store.reset();
          })
        }
      } else {
        const errorMessage: string[] = [];
        if (!response.zipCode.isValid) {
          errorMessage.push('Please enter a valid zip code.');
        }
        if (!response.email.isValidEmail || response.email.isDisposable) {
          errorMessage.push('Please enter a valid email.');
        }
        store.setValidationError(errorMessage.join(' '));
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const renderSummaryPanel = () => {
    const answeredItems = questionnaire.questions.filter(item => {
      const index = questionnaire.questions.findIndex(q => q.id === item.id);
      return store.isQuestionAnswered(item.id) && store.shouldShowQuestion(index);
    });

    if (answeredItems.length === 0) {
      return (
        <Paper p="xs" shadow="none" withBorder={false} style={{ position: "sticky", top: "20px" }}>
          <Text c="dimmed">No questions answered yet</Text>
        </Paper>
      );
    }

    const groupedAnsweredItems = store.getAnsweredQuestionsByGroup;

    return (
      <Paper p="xs" shadow="none" withBorder={false} style={{
        position: "sticky",
        top: "20px",
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        fontFamily: "Inter, Helvetica, Arial, sans-serif"
      }}>
        <Stack gap="xs" style={{ backgroundColor: 'rgb(255, 250, 240)', border: '1px solid rgb(249, 236, 211)', borderRadius: '8px', padding: '20px' }}>
          <Title order={4} mb="xs" c="gray.7" fw={500}>Summary of your request</Title>

          {Object.entries(groupedAnsweredItems).map(([group, items]) => (
            <Box key={group} mb="xs">
              <Text fw={500} size="sm" c="gray.6" mb={3}>{group}</Text>

              {items.map(item => {
                const displayValue = store.getQuestionDisplayValue(item.id);

                return (
                  <Box key={item.id} ml="xs" mb={3}>
                    <Group justify="space-between" wrap="wrap" align="flex-start">
                      <Text fw={400} size="xs" c="gray.7" style={{ flexShrink: 0 }}>{item.name}:</Text>
                      <Box style={{ maxWidth: '60%' }}>
                        <Group gap="xs" align="flex-start" wrap="nowrap">
                          <IoCheckmarkOutline color="#4CAF50" size={14} style={{ flexShrink: 0, marginTop: '3px' }} />
                          <Text fw={500} size="xs" c="gray.7" style={{ wordBreak: 'break-word' }}>{displayValue}</Text>
                        </Group>
                      </Box>
                    </Group>
                  </Box>
                );
              })}
            </Box>
          ))}
        </Stack>
      </Paper>
    );
  };

  const renderInputByType = (item: QuestionItem, index: number) => {
    const isRequestorField = index < 0;
    let fieldErrors: { error: string | null | undefined } = { error: null }

    if (item.id === 'email' && store.shouldShowValidation('email')) {
      const emailError = store.validateEmail(store.answers.email);
      fieldErrors = { error: emailError };
    } else if (item.id === 'zipCode' && store.shouldShowValidation('zipCode')) {
      const zipCodeError = store.validateZipCode(store.answers.zipCode);
      fieldErrors = { error: zipCodeError };
    }

    return (
      <>
        {fieldErrors.error && <Text c="red" size="xs" mb={5}>{fieldErrors.error}</Text>}
        {(() => {
          switch (item.type) {
            case 'checkbox':
              return (
                <Checkbox.Group
                  value={store.answers[item.id] || []}
                  onChange={(value) => store.setAnswer(item.id, value)}
                  error={null}
                >
                  <SimpleGrid cols={2} spacing="xs">
                    {item.options?.map((option) => (
                      <Checkbox
                        key={option.id}
                        value={option.id}
                        label={<Text c="gray.7" fw={400} size="sm" style={{ wordBreak: 'break-word' }}>{option.label}</Text>}
                        disabled={!isRequestorField && !store.shouldShowQuestion(index)}
                        color="teal.8"
                        size="sm"
                        variant="outline"
                      />
                    ))}
                  </SimpleGrid>
                </Checkbox.Group>
              );

            case 'select':
              return (
                <Select
                  data={item.options?.map(option => ({
                    value: option.id,
                    label: option.label
                  })) || []}
                  placeholder="Select an option"
                  value={store.answers[item.id]}
                  onChange={(value) => store.setAnswer(item.id, value || '')}
                  error={null}
                  mt="xs"
                  searchable
                  disabled={!isRequestorField && !store.shouldShowQuestion(index)}
                  styles={{
                    input: { color: '#495057', fontWeight: 400 }
                  }}
                />
              );

            case 'radio':
              return (
                <Radio.Group
                  value={store.answers[item.id]}
                  onChange={(value) => store.setAnswer(item.id, value)}
                  error={null}
                >
                  <SimpleGrid cols={{ base: 1, sm: item.options?.length || 1, md: item.options?.length || 1 }} spacing="xs">
                    {item.options?.map((option) => (
                      <Card
                        key={option.id}
                        shadow="none"
                        padding="xs"
                        radius="md"
                        withBorder={false}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: store.answers[item.id] === option.id ? 'rgb(244, 254, 236)' : 'transparent',
                          border: store.answers[item.id] === option.id ? '1px solid #b9d79e' : '1px solid #eeeeee',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            border: '1px solid #e9ecef'
                          }
                        }}
                        styles={{
                          root: {
                            '&:hover': {
                              border: '1px solid #e9ecef'
                            }
                          }
                        }}
                        onClick={() => store.setAnswer(item.id, option.id)}
                      >
                        <Group align="flex-start" gap="xs" wrap="nowrap">
                          <Text fw={400} size="sm" c="gray.7" style={{ wordBreak: 'break-word' }}>{option.label}</Text>
                        </Group>
                      </Card>
                    ))}
                  </SimpleGrid>
                </Radio.Group>
              );

            case 'numeric':
              return (
                <TextInput
                  type="number"
                  min={item.min}
                  max={item.max}
                  step={item.step || 1}
                  placeholder="Enter a number"
                  value={store.answers[item.id]}
                  onChange={(e) => store.setAnswer(item.id, e.currentTarget.value)}
                  error={null}
                  mt="xs"
                  disabled={!isRequestorField && !store.shouldShowQuestion(index)}
                  styles={{
                    input: { color: '#495057', fontWeight: 400 }
                  }}
                />
              );

            case 'textarea':
              return (
                <Textarea
                  placeholder={item.placeholder || "Enter text"}
                  value={store.answers[item.id]}
                  onChange={(e) => store.setAnswer(item.id, e.currentTarget.value)}
                  error={null}
                  mt="xs"
                  disabled={!isRequestorField && !store.shouldShowQuestion(index)}
                  styles={{
                    input: { color: '#495057', fontWeight: 400 }
                  }}
                  autosize
                  minRows={2}
                  maxRows={5}
                />
              );

            case 'text':
            default:
              return (
                <TextInput
                  placeholder={item.placeholder || "Enter text"}
                  value={store.answers[item.id]}
                  onChange={(e) => store.setAnswer(item.id, e.currentTarget.value)}
                  error={fieldErrors.error || null}
                  mt="xs"
                  label={item.name}
                  c={'gray.7'}
                  withAsterisk={item.optional ? false : true}
                  description={item.description}
                  disabled={!isRequestorField && !store.shouldShowQuestion(index)}
                  styles={{
                    input: { color: '#495057', fontWeight: 400 }
                  }}
                />
              );
          }
        })()}
      </>
    );
  };

  const renderContactForm = () => {
    const contactFields = questionnaire.requestorDetails.fields.map(field => ({
      id: field.id,
      name: field.label,
      label: field.label,
      description: '',
      howToFind: '',
      group: 'Contact',
      type: field.type === 'email' ? 'text' : field.type,
      optional: field.optional,
      placeholder: `Your ${field.label.toLowerCase()}`
    }));

    return (
      <Box mb="sm">
        <Title order={4} mb="sm" c="gray.6" fw={500}>Contact Information</Title>
        <Grid>
          {contactFields.map((item: any) => (
            <Grid.Col key={item.id} span={{ base: 12, sm: 6 }}>
              {renderInputByType(item, -1)}
            </Grid.Col>
          ))}
          <Grid.Col span={{ base: 12, sm: 6 }} style={{ display: 'flex', alignItems: 'flex-end', }}>
            <Button
              onClick={handleSubmit}
              loading={isLoading}
              variant="outline"
              fullWidth
              color="gray.7"
            >
              Submit
            </Button>
          </Grid.Col>
        </Grid>
        {isSubmitSuccess && !store.invalidQuestionIds.length > 0 && (
          <Alert
            icon={<IoCheckmarkOutline size={16} />}
            title="Success"
            color="green"
            variant="light"
            styles={{ root: { width: '100%' } }}
            mt="sm"
          >
            Your quote request was received. Check your email.
          </Alert>
        )}
      </Box>
    );
  };

  return (
    <Container size="80%" px={0}>
      <Grid p="xs">
        <Grid.Col span={{ base: 12, md: 8 }} order={{ base: 1, md: 1 }}>
          <Box
            style={{
              position: "sticky",
              top: 0,
              zIndex: 100,
              backgroundColor: "white",
              padding: "10px 0",
              borderBottom: store.validationError ? "1px solid #f8d7da" : "1px solid #f1f1f1"
            }}
          >
            <Stack gap={0} mb="lg">
              <Title order={2} mb="sm" c="gray.7" fw={500}>{title}</Title>
              <Text size="sm" c="gray.7" >{description}</Text>
            </Stack>

            {store.validationError && (
              <Alert
                icon={<IoWarningOutline size={16} />}
                title="Form Validation Error"
                color="red"
                withCloseButton
                onClose={() => store.setValidationError(null)}
              >
                {store.validationError}
              </Alert>
            )}
          </Box>

          <form>
            <Stack gap="md">
              {Object.entries(store.getQuestionsByGroup).map(([group, groupItems]) => {
                const visibleQuestions = groupItems.filter((item) => {
                  const index = questionnaire.questions.findIndex(q => q.id === item.id);
                  return store.shouldShowQuestion(index);
                });

                if (visibleQuestions.length === 0) return null;

                return (
                  <Box key={group}>
                    <Title order={4} mb="sm" c="gray.6" fw={500}>{group}</Title>

                    {groupItems.map((item) => {
                      const index = questionnaire.questions.findIndex(q => q.id === item.id);
                      const visible = store.shouldShowQuestion(index);
                      if (!visible) return null;
                      const visibleQuestionNumber = store.visibleQuestionNumber(index);

                      const isInvalid = store.formSubmitted && store.invalidQuestionIds.includes(item.id);

                      return (
                        <Paper
                          p="sm"
                          key={item.id}
                          mb="sm"
                          withBorder={false}
                          shadow="none"
                          style={{
                            opacity: index <= store.activeQuestionIndex ? 1 : 0.5,
                            pointerEvents: index <= store.activeQuestionIndex ? 'auto' : 'none',
                            borderRadius: 0
                          }}
                        >
                          <Stack gap="xs">
                            <Text fw={500} c={isInvalid ? "red" : "gray.7"}>
                              {visibleQuestionNumber}. {item.name}
                              {store.isQuestionAnswered(item.id) && (
                                <IoCheckmarkOutline color="#4CAF50" size={18} style={{ marginLeft: '8px', verticalAlign: 'middle' }} />
                              )}
                            </Text>
                            <Text size="sm" c="gray.7">{item.description}</Text>
                            <Text size="sm" c="gray.7" fs="italic">{item.howToFind}</Text>

                            {renderInputByType(item, index)}
                          </Stack>
                        </Paper>
                      );
                    })}
                  </Box>
                );
              })}
            </Stack>
          </form>
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 4 }} order={{ base: 2, md: 2 }} mt={{ base: 'sm', md: 36 }}>
          {renderSummaryPanel()}
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 8 }} order={{ base: 2, md: 2 }}>
          {renderContactForm()}
        </Grid.Col>
      </Grid>
    </Container>
  );
});

export default Questionnaire;
