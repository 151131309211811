import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import opportunities from 'api/opportunities'
import OpportunitySteps from '../Opportunities/Page/OpportunitiesSteps'
import OpportunitiesContent from '../Opportunities/Page/OpportunitiesContent'
import { OpportunityStore } from '../../stores/OpportunityStore'
// @ts-ignore
import css from './OneLink.module.css'

const OneLink = () => {
  const [os, setOS] = useState<OpportunityStore>()
  const resolve = usePreload(() => opportunities.getWithUrlKey(urlKey))
  const { data, onUpdate }: { data: any; onUpdate: any } = resolve
  const { urlKey } = useParams()

  useEffect(() => {
    if (urlKey) {
      OpportunityStore.init({ urlKey }).then(setOS)
    }
  }, [urlKey])

  if (!os) return null

  let opportunityComponent: React.ReactNode | null = null
  if (!urlKey) {
    opportunityComponent = <div>Your URL is expired</div>
  } else {
    opportunityComponent = data?.onboardingCompleted ? (
      <OpportunitiesContent opportunityStore={os} urlKey={urlKey} />
    ) : (
      <OpportunitySteps data={data} onUpdate={onUpdate} view="customer" urlKey={urlKey} opportunityStore={os} />
    )
  }

  return (
    <div className={css.Layout}>
      <div className={css.Container}>
        <Preload {...resolve} isLoading={resolve.isLoading || os.isLoading}>
          {data ? (
            <div>
              <div className="flex">
                {data.account?.logo && <img src={data.account?.logo} alt={data.account.name} />}
                <h1>{data.account?.name}</h1>
              </div>
              {/* <Opportunity data={data} onUpdate={onUpdate} onRetry={onRetry} view="customer" urlKey={urlKey} /> */}
              {opportunityComponent}
            </div>
          ) : (
            <div>
              <h4>Account not found!</h4>
            </div>
          )}
        </Preload>
      </div>
    </div>
  )
}

export default OneLink
