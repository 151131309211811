import { Link } from 'react-router-dom'
import { Avatar, Button, ActionIcon } from '@mantine/core'
import { IconEye, IconTrashX } from '@tabler/icons-react'

import { PageHeader } from 'components/PageHeader/PageHeader'
import { Preload, usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import admin from 'api/admin'

const breadcrumbs = [
  { label: 'Home', href: '/' }, //
  { label: 'Admin', href: '/admin' },
  { label: 'Accounts' },
]

const AdminAccounts = () => {
  const resolve = usePreload(admin.getAccounts)
  const { data, onRetry } = resolve

  const columns = [
    {
      title: 'Logo',
      render: ({ logo, name }) => (
        <Avatar color="cyan" radius="sm" src={logo}>
          {name.split(' ').map(([v]) => v.toUpperCase())}
        </Avatar>
      ),
    },
    {
      title: 'Company name',
      id: 'name',
    },
    {
      title: 'User count',
      id: 'users.length',
    },
    {
      title: 'HQ Location',
      id: 'hqLocation',
    },
    {
      title: 'Web Domain',
      id: 'webDomain',
    },
    {
      textAlign: 'right',
      render: ({ _key }) => (
        <Button
          component={Link}
          to={`/admin/accounts/${_key}`}
          leftSection={<IconEye size="1rem" stroke={1} />}
          variant="subtle"
          color="green"
          size="xs"
        >
          view
        </Button>
      ),
    },
    {
      render: ({ _key, users }, { showConfirm }) => (
        <ActionIcon
          variant="light" //
          color="red"
          disabled={users?.length}
          onClick={() => showConfirm(() => onRetry(() => admin.deleteAccount(_key)))}
        >
          <IconTrashX size={18} stroke={1.5} />
        </ActionIcon>
      ),
    },
  ]

  const create = {
    label: 'Create new',
    form: [
      { label: 'Company name', name: 'name' }, //
      { label: 'Description', name: 'description' },
      { label: 'Logo URL', name: 'logo' },
      { label: 'Website', name: 'website' },
      { label: 'Industry', name: 'industry' },

      { label: 'Foundation Date', name: 'foundationDate' },
      { label: 'Address', name: 'hqLocation' },
      { label: 'Phone Number', name: 'businessPhoneNumber' },
      { label: 'Whatsapp', name: 'businessWhatsapp' },
      { label: 'LinkedIn', name: 'linkedinProfile' },
    ],
    onSubmit: (values) => onRetry(() => admin.createAccount(values)),
  }

  return (
    <div>
      <PageHeader title="All accounts" breadcrumbs={breadcrumbs} />

      <Preload {...resolve}>
        <Table data={data} columns={columns} create={create} />
      </Preload>
    </div>
  )
}

export default AdminAccounts
