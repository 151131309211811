import React, { useEffect } from 'react';
import Questionnaire from './Questionnaire';
import questionnaire from './EVQuestionnaire.json';
import { EventCategories } from './mixpanelAnalytics';

// Default Mixpanel token
const MIXPANEL_TOKEN = '03986a0e4777b986a2524323786188b5';
const BroadnAPIKey = '01J8XQBPBD4Y8T4Y4JWZK6JJBA.a87b5d83-34b7-4733-a646-607d30e994ff'; //preview

interface EVQuestionnaireProps {
  mixpanelToken?: string;
  broadnApiKey: string;
}

const EVQuestionnaire = ({ mixpanelToken = MIXPANEL_TOKEN, broadnApiKey = BroadnAPIKey }: EVQuestionnaireProps) => {
  const title = 'Custom installation questionnaire';
  const description = 'Help us understand your existing setup, needs and preferences for an EV charging point installation';

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Questionnaire
      questionnaire={questionnaire as any}
      title={title}
      description={description}
      mixpanelToken={mixpanelToken}
      broadnApiKey={broadnApiKey}
    />
  );
};

export default EVQuestionnaire;
