import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { makeAutoObservable, toJS } from 'mobx'
import { Text, Stepper, Tabs, Group, Button } from '@mantine/core'
import { IconX } from '@tabler/icons-react'

import BasketStore from '../../../stores/BasketStore'

import StatusSections from 'components/StatusSections/StatusSections'

import css from './OpportunitiesPage.module.css'

import Basket from './Basket/Basket'
import TermsView from './Terms/View/TermsView'
import TermsForm from './Terms/Form/TermsForm'

import Address from './Address/Address'
import Search from './Search/Search'
import Thread from './Thread/Thread'
import PdfPreview from './PdfPreview/PdfPreview'
import Details from './Details/Details'
import Preview from './Preview/Preview'

import oneLink from 'api/onelink'

const statusList = [
  { status: 'new', label: 'New Inquiry' },
  { status: 'quotation', label: 'Quotation' },
  { status: 'orderCreated', label: 'Order created' },
  { status: 'signed', label: 'Signed' },
  { status: 'shipping', label: 'Shipping' },
  { status: 'completed', label: 'Completed' },
]

const getActiveSteps = ({ basket, shippingAddress, billingAddress, terms }) => {
  return [!!(shippingAddress && billingAddress), !!terms, false]
}

//---------------------------------------------------------------------------
const OpportunitySteps = ({ data, onUpdate, view, urlKey, opportunityStore }) => {
  const { status, order, quote, customer, settings, thread } = data || {}

  const activeSteps = getActiveSteps(quote)
  const step = activeSteps.findIndex((v) => v === false)

  const [active, setActive] = useState(step)
  const nextStep = () => setActive((current) => (current < 6 ? current + 1 : current))
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current))

  const isOrder = !!order
  const activeTab = isOrder ? 'order' : 'thread'

  const onSave = (data) => {
    onUpdate(() => oneLink.updateQuote(urlKey, data))
    nextStep()
  }

  const onCompleted = (data) => {
    onUpdate(() => oneLink.updateQuoteStatus(urlKey, data))
    nextStep()
  }

  return (
    <section className={css.Section}>
      <div className={css.header}>
        <StatusSections list={statusList} status={status || 'draft'} />
      </div>

      <div className={css.content}>
        {/* <Tabs keepMounted={false} color="teal" defaultValue={activeTab}>
          <Tabs.List>
            {!isOrder && <Tabs.Tab value="search">Search</Tabs.Tab>}
            {!isOrder && (
              <Tabs.Tab value="basket" disabled={!basket.totalProducts}>
                Basket ({basket.totalProducts})
              </Tabs.Tab>
            )}

            {isOrder && <Tabs.Tab value="order">Order</Tabs.Tab>}
            {isOrder && <Tabs.Tab value="pdf">Documents</Tabs.Tab>}

            <Tabs.Tab value="details">Company details</Tabs.Tab>
            {!isOrder && <Tabs.Tab value="terms">Terms</Tabs.Tab>}
            {!isOrder && <Tabs.Tab value="address">Delivery address</Tabs.Tab>}

            <Tabs.Tab value="thread" ml="auto">
              Thread
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="search">
            <Search {...data} urlKey={urlKey} basket={basket} />
          </Tabs.Panel>

          <Tabs.Panel value="address">
            <Address />
          </Tabs.Panel>

          <Tabs.Panel value="basket">
            <Basket basketStore={basket.products} label="Quote" />
          </Tabs.Panel>

          <Tabs.Panel value="order">
            <Basket basketStore={order?.basket} label="Order" />

            <TermsView options={order?.terms} />
          </Tabs.Panel>

          <Tabs.Panel value="terms">
            <TermsForm onChange={(v) => console.log(v)} />
          </Tabs.Panel>

          <Tabs.Panel value="thread">
            <Thread {...data} basket={basket} onUpdate={onUpdate} view={view} urlKey={urlKey} />
          </Tabs.Panel>

          <Tabs.Panel value="pdf">
            <PdfPreview {...data} onUpdate={onUpdate} urlKey={urlKey} />
          </Tabs.Panel>

          <Tabs.Panel value="details">
            <Details {...data} urlKey={urlKey} />
          </Tabs.Panel>
        </Tabs> */}
      </div>

      <div className={css.content}>
        <Stepper active={active} onStepClick={setActive}>
          {/* <Stepper.Step label="Search" description="Search for products">
            <Search {...data} urlKey={urlKey} basketStore={basket} nextStep={nextStep} />
          </Stepper.Step> */}
          {/* <Stepper.Step label={`Quote (${basket.totalProducts})`} description="Your selected products">
            <div>
              <Basket basket={basket} label="Quote" canEdit />
              <Group justify="center" mt="xl">
                <Button onClick={prevStep} variant="default" color="blue" size="sm" radius="sm">
                  <Text size="xs">Back</Text>
                </Button>
                <Button onClick={() => onSave({ selected: basket.selected })} variant="outline" color="blue" size="sm" radius="sm">
                  <Text size="xs">Save and continue</Text>
                </Button>
              </Group>
            </div>
          </Stepper.Step> */}
          <Stepper.Step label="Company info" description="Shipping, billing address" color={!activeSteps[0] && 'red'} completedIcon={!activeSteps[0] && <IconX />}>
            <h2>Company info</h2>

            <Address onSubmit={(v) => onSave(v)} quote={quote} customer={customer}>
              <Group justify="center" mt="xl">
                <Button onClick={prevStep} variant="default" color="blue" size="sm" radius="sm">
                  <Text size="xs">Back</Text>
                </Button>
                <Button type="submit" variant="outline" color="blue" size="sm" radius="sm">
                  <Text size="xs">Save and continue</Text>
                </Button>
              </Group>
            </Address>
          </Stepper.Step>

          <Stepper.Step label="Terms" description="Delivery, payment, warranty">
            <div>
              <TermsForm onSubmit={(selectedTerms) => onSave({ selectedTerms })} list={settings.availableTerms} terms={quote?.terms}>
                <Group justify="center" mt="xl">
                  <Button onClick={prevStep} variant="default" color="blue" size="sm" radius="sm">
                    <Text size="xs">Back</Text>
                  </Button>

                  <Button type="submit" variant="outline" color="blue" size="sm" radius="sm">
                    <Text size="xs">Save and continue</Text>
                  </Button>
                </Group>
              </TermsForm>
            </div>
          </Stepper.Step>

          <Stepper.Step label="Preview and confirm" description="Preview and confirm your details">
            <Preview quote={quote} customer={customer} onSubmit={onCompleted} />
          </Stepper.Step>

          <Stepper.Completed>
            <Group justify="center" mt="xl">
              <h2>Completed!</h2>
            </Group>
          </Stepper.Completed>
        </Stepper>
      </div>
    </section>
  )
}

export default observer(OpportunitySteps)
