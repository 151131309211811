import { useState, useEffect, useRef } from 'react'
import { useDisclosure } from '@mantine/hooks'
import { Modal } from '@mantine/core'

import Spinner from 'components/Spinner/Spinner'

export const usePreload = (asyncFn: () => any, key?: string) => {
  const isCalledRef = useRef(false)
  const [getDataFn] = useState(() => asyncFn)

  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  const onRetry = async (fn) => {
    setIsLoading(true)

    try {
      if (fn) {
        await fn()
      }
      const result = await getDataFn()
      setData(result)
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
    return data
  }

  const onUpdate = async (fn) => {
    setIsLoading(true)

    let result
    if (fn) {
      result = await fn()
      setData(result)
    }
    setIsLoading(false)
    return result
  }

  useEffect(() => {
    const noop = () => null
    const handlePromise = async () => {
      isCalledRef.current = true
      setIsLoading(true)

      try {
        const result = await ((getDataFn && getDataFn()) || noop)
        setData(result)
      } catch (e) {
        console.error('-- ERROR --', e)
        setError(e)
      } finally {
        setIsLoading(false)
      }
    }

    if (!isCalledRef.current) handlePromise()
  }, [key, getDataFn])

  return { isLoading, data, error, onUpdate, onRetry }
}

export const Preload = ({ children, isLoading, data, error, className = '' }) => {
  if (isLoading === null) return null

  if (isLoading && !data) return <Spinner />

  return (
    <div className={className} style={{ opacity: isLoading ? 0.5 : 1 }}>
      {children}

      {error && <ErrorModal error={error} />}
    </div>
  )
}

const ErrorModal = ({ error }) => {
  const [opened, { open, close }] = useDisclosure(true)
  delete error?.error

  return (
    <Modal opened={opened} onClose={close} title={error?.data?.message || error?.message || 'Error'} size="md">
      Error:
      <pre>
        <small>{JSON.stringify(error, null, 2)}</small>
      </pre>
    </Modal>
  )
}

export default Preload
