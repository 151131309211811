import { makeObservable, observable, action, computed, runInAction } from 'mobx'
import opportunities from '../api/opportunities'

class OpportunitiesStore {
  opportunities: any[] = []
  loading = false
  error = null
  lastFetched: Date | null = null
  isInitialized = false

  constructor() {
    makeObservable(this, {
      opportunities: observable,
      loading: observable,
      error: observable,
      lastFetched: observable,
      isInitialized: observable,
      fetchOpportunities: action,
      setOpportunities: action,
      count: computed,
      newOpportunities: computed,
      newOpportunitiesCount: computed,
      hasNewOpportunities: computed,
      newOpportunitiesValue: computed,
    })
  }

  get count() {
    return this.opportunities.length
  }

  get newOpportunities() {
    return this.opportunities.filter((opportunity: any) => opportunity.status === 'new')
  }

  get newOpportunitiesCount() {
    return this.newOpportunities.length
  }

  get hasNewOpportunities() {
    return this.newOpportunitiesCount > 0
  }

  get newOpportunitiesValue() {
    if (!this.hasNewOpportunities) return 0

    return this.newOpportunities.reduce((total, opp) => {
      const oppValue = (opp.thread || [])
        .filter((item) => item?.searchResults)
        .flatMap((item) => item.searchResults || [])
        .reduce((value, result) => {
          if (!result) return value
          const quantity = result.quantity || 0
          const price = result.suggestedProducts?.[0]?.sellingPrice || 0
          return value + quantity * price
        }, 0)

      return total + oppValue
    }, 0)
  }

  setOpportunities(data) {
    this.opportunities = Array.isArray(data) ? data : []
    this.lastFetched = new Date()
    this.isInitialized = true
  }

  async fetchOpportunities() {
    if (this.lastFetched && new Date().getTime() - this.lastFetched.getTime() < 10000) {
      return this.opportunities
    }
    if (this.loading) return this.opportunities

    try {
      runInAction(() => {
        this.loading = true
        this.error = null
      })

      const response = await opportunities.getAll()
      runInAction(() => {
        this.setOpportunities(response)
        this.loading = false
      })

      return response
    } catch (error: any) {
      runInAction(() => {
        this.loading = false
        this.error = error
      })

      console.error('Error fetching opportunities:', error)
      return []
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const opportunitiesStore = new OpportunitiesStore()
export default opportunitiesStore
