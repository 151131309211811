import { useEffect, useState } from 'react'
import { Text, Button } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { usePdf } from 'common/hooks/usePdf'

import sales from 'api/sales'
import auth from 'api/auth'
// @ts-ignore
import css from './PdfPreview.module.css'
import { observer } from 'mobx-react'

const getPayload = (data, type) => {
  const current = data[type]

  const signedBy = current?.signatures?.reduce((buf, item) => {
    buf[item.type] = item
    return buf
  }, {})

  return { ...data, me: auth.user, signedBy, current }
}

const toQuoteProduct = ({ product, basketLine, specs }) => ({
  ProductCode: product.productCode || product.ProductCode,
  Description: product.description || product.Description,
  Name: product.Name || product.name,
  unitPrice: basketLine.unitPrice,
  quantity: basketLine.quantity,
  total: basketLine.totalPrice,
  photos: product.photos,
  specs,
})

const PdfPreview = observer(({ onRetry, type = 'quote', urlKey, canSign = true, opportunityStore, ...data }) => {
  const [style, setStyle] = useState()
  const [pdfRef, setPdfRef] = useState()
  const { download, getCompiled, isLoading } = usePdf()
  const [displayBasket, setDisplayBasket] = useState<{ products: any; totalDetails: any }>()


  const view = urlKey ? 'customer' : 'account'
  const payload = getPayload(data, type)
  const { current, signedBy } = payload
  const signatory = signedBy && signedBy[view]
  const title = `${type.at(0)?.toUpperCase()}${type.slice(1)}: #${current?._key}`


  useEffect(() => {
    const lastBasketSnapshot = opportunityStore.lastestBasketSnapshot
    let basketProducts = lastBasketSnapshot?.basketProducts.map(toQuoteProduct)
    const displayBasket = { products: basketProducts, totalDetails: lastBasketSnapshot?.totalDetails }
    setDisplayBasket(displayBasket)
  }, [opportunityStore])

  const execute = (ref, data) => {
    const el = getCompiled({ ...data, basket: displayBasket }, type)
    ref.replaceChildren(el)
  }

  const onSetRef = (ref) => {
    if (ref && !style) {
      setPdfRef(ref)

      execute(ref, { ...payload, basket: displayBasket })

      const signatureHtml = document.getElementById(view + 'Signature')
      if (signatureHtml) {
        const offsets = signatureHtml.getBoundingClientRect()
        const { top, left } = offsets
        setStyle({ top, left } as any)
      }
    }
  }

  const onSign = async () => {
    const res = await opportunityStore.runAndFetch(async () => sales.sign(current, urlKey))
    const payload = getPayload(opportunityStore.opportunity, type)
    execute(pdfRef, { ...payload, basket: displayBasket })
  }
  if (!opportunityStore || opportunityStore.isLoading || !displayBasket) return null
  console.log('PDFPrevier.displayBasket', displayBasket)
  return (
    <div className="print-page">
      <div className={css.layout}>
        <section>
          <div className="print-invisible">
            <div className={css.header}>
              <Button
                disabled={isLoading}
                loading={isLoading}
                variant="default"
                leftSection={<IconDownload size={12} />}
                size="compact-sm"
                radius="sm"
                onClick={() => download({ ...payload, basket: displayBasket }, type, { title })}
              >
                <Text size="xs">Download PDF</Text>
              </Button>
            </div>
          </div>

          <div className={css.page} ref={onSetRef} />

          {!signatory?.signatureBody && canSign && (
            <div className={css.signButton} style={style}>
              <Button onClick={onSign}>Sign</Button>
            </div>
          )}
        </section>
      </div>
    </div>
  )
})

export default PdfPreview
