import { Link } from 'react-router-dom'

import { PiSealPercentThin } from 'react-icons/pi'
import { formatDistance } from 'date-fns'
import { Avatar, NumberFormatter } from '@mantine/core'
import { IconBuilding, IconMapPin, IconUsers, IconCalendar, IconChartBar, IconBrandTwitter, IconTags, IconBrandLinkedin, IconCoin } from '@tabler/icons-react'

import { PageHeader } from 'components/PageHeader/PageHeader'
import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import backend from 'api/backend'

const Leads = () => {
  const resolve = usePreload(backend.getLeads)
  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Leads' }]

  const columns = [
    {
      title: 'Company',
      icon: <IconBuilding stroke={1} size={18} />,
      render: ({ logo, company, name, Name, _key }) => (
        <Link to={`/customers/${_key}`}>
          <div className="flex">
            <Avatar variant="outline" radius="xl" size="sm" color="orange" src={logo} alt={`Logo ${company}`} />
            <b>{company || Name || name}</b>
          </div>
        </Link>
      ),
    },
    { key: 'salesPipelineStage', title: 'Sales Stage', icon: <IconChartBar stroke={1} size={18} /> },
    {
      title: 'RFQs',
      icon: <PiSealPercentThin stroke={1} size={18} />,
      render: ({ rfqCount }) => <NumberFormatter value={rfqCount} thousandSeparator />,
    },
    {
      title: 'Last contact',
      icon: <IconCalendar stroke={1} size={18} />,
      render: ({ lastInteractionAt }) => {
        if (!lastInteractionAt) return ''
        return formatDistance(new Date(lastInteractionAt), new Date(), { addSuffix: true }).replaceAll('about', '').trim()
      },
    },
    { key: 'city', title: 'City', icon: <IconMapPin stroke={1} size={18} /> },
    { title: 'Description', ellipsis: '200px', icon: <IconTags stroke={1} size={18} />, render: ({ shortDescription, description }) => description || shortDescription },

    { key: 'employeeRange', title: 'Employees', icon: <IconUsers stroke={1} size={18} /> },
    { key: 'estimatedARR', title: 'Estimated ARR', icon: <IconCoin stroke={1} size={18} /> },
    {
      title: 'LinkedIn',
      icon: <IconBrandLinkedin stroke={1} size={18} />,
      render: ({ linkedinProfile }) =>
        linkedinProfile && (
          <a target="_blank" href={linkedinProfile} rel="noreferrer">
            {linkedinProfile.split('/').pop()}
          </a>
        ),
    },
    { key: 'twitter', title: 'Twitter', icon: <IconBrandTwitter stroke={1} size={18} /> },
    { key: 'industry', title: 'Categories', icon: <IconTags stroke={1} size={18} /> },
    {
      title: 'Known since',
      icon: <IconCalendar stroke={1} size={18} />,
      render: ({ createdAt }) => {
        if (!createdAt) return ''
        return formatDistance(new Date(createdAt), new Date(), { addSuffix: true }).replaceAll('about', '').trim()
      },
    },
    { key: 'foundationDate', title: 'Incorporation', icon: <IconCalendar stroke={1} size={18} /> },
    { id: 'webDomain', title: 'WebDomain' },
  ]

  const filter = {
    key: 'salesPipelineStage',
  }

  const { data } = resolve
  return (
    <Preload {...resolve}>
      <PageHeader title="Leads" breadcrumbs={breadcrumbs} />

      <div className="container2">
        <Table data={data} columns={columns} filter={filter} />
      </div>
    </Preload>
  )
}

export default Leads
