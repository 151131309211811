import get from 'lodash/get'
import cn from 'classnames'
import { useEffect, useRef } from 'react'
import css from '../Table.module.css'
import { Button, Group, Badge } from '@mantine/core'

const TableFilter = ({ data, filter, onUpdate, selected, orderBy = [] }) => {
  const isInitialMount = useRef(true)
  
  const group = data.reduce(
    (buf, item) => {
      const name = filter.render ? filter.render(item) : get(item, filter.key)

      buf[name] = buf[name] || []
      buf[name].push(item)
      return buf
    },
    { All: data }
  )

  const sortedEntries = Object.entries(group).sort(([labelA], [labelB]) => {
    const indexA = orderBy.indexOf(labelA)
    const indexB = orderBy.indexOf(labelB)
    if (indexA !== -1 && indexB !== -1) return indexA - indexB
    if (indexA !== -1) return -1
    if (indexB !== -1) return 1
    return 0
  })

  useEffect(() => {
    // Skip first render to let Table handle initial filter
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    
    // Apply selected filter when data changes
    if (selected && group[selected]) {
      onUpdate(group[selected], selected)
    } else if (sortedEntries.length > 0) {
      const [firstLabel, firstList] = sortedEntries[0]
      onUpdate(firstList, firstLabel)
    }
  }, [selected, data.length]);

  return (
    <Group className={css.filter} spacing={'xs'} wrap="nowrap" ml={'10px'} gap="xl">
      {sortedEntries.map(([label, list]) => (
        <Button
          key={label}
          variant="default"
          className={cn(selected === label && css.selected)}
          onClick={() => onUpdate(list, label)}
          styles={{
            root: { fontWeight: 'normal', padding: 0 },
          }}
          rightSection={
            <Badge variant="white" c="gray.7" size="sm" radius="xl" sx={{ marginLeft: '4px' }}>
              {list.length}
            </Badge>
          }
        >
          {label === 'undefined' ? 'N/A' : label}
        </Button>
      ))}
      <div></div>
    </Group>
  )
}

export default TableFilter
