import cn from 'classnames'

import css from './Pagging.module.css'

const TablePagging = ({ rows, limit, page, setPage }) => {
  const total = rows.length
  const totalPages = Math.ceil(total / limit)
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1)

  const itemsFrom = page * limit
  let itemsTo = Math.min(itemsFrom + limit, total)

  // Calculate which page numbers to display
  let paginationRange = []
  const SIBLING_COUNT = 1
  const DOTS = 'dots'
  
  // Always show first page
  paginationRange.push(1)
  
  // Calculate left ellipsis
  if (page + 1 > 2 + SIBLING_COUNT) {
    paginationRange.push(DOTS)
  }
  
  // Calculate main pagination range
  const startPage = Math.max(2, page + 1 - SIBLING_COUNT)
  const endPage = Math.min(totalPages - 1, page + 1 + SIBLING_COUNT)
  
  for (let i = startPage; i <= endPage; i++) {
    if (i > 1 && i < totalPages) {
      paginationRange.push(i)
    }
  }
  
  // Calculate right ellipsis
  if (page + 1 < totalPages - SIBLING_COUNT) {
    paginationRange.push(DOTS)
  }
  
  // Always show last page if we have more than one page
  if (totalPages > 1) {
    paginationRange.push(totalPages)
  }
  
  const onUpdate = (nr) => {
    window.scrollTo(0, 0)
    setPage(nr - 1)
  }

  return (
    <footer className={css.footer}>
      {total === 0 ? (
        <div>No records</div>
      ) : total <= limit ? (
        <div>Total: {total}</div>
      ) : (
        <>
          <div>
            Showing {itemsFrom + 1} to {itemsTo} of <span>{total}</span>
          </div>
          <div className={css.pages}>
            Per page: {limit}
            <div className={css.numbers}>
              {/* Previous page button */}
              <button 
                onClick={() => page > 0 && onUpdate(page)}
                disabled={page === 0}
                className={cn(page === 0 && css.disabled)}
              >
                &lt;
              </button>
              
              {/* Page number buttons */}
              {paginationRange.map((pageNumber, idx) => 
                pageNumber === DOTS ? (
                  <span key={`dots-${idx}`}>...</span>
                ) : (
                  <button 
                    key={pageNumber} 
                    className={cn(pageNumber === page + 1 && css.active)} 
                    onClick={() => onUpdate(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                )
              )}
              
              {/* Next page button */}
              <button 
                onClick={() => page < totalPages - 1 && onUpdate(page + 2)}
                disabled={page >= totalPages - 1}
                className={cn(page >= totalPages - 1 && css.disabled)}
              >
                &gt;
              </button>
            </div>
          </div>
        </>
      )}
    </footer>
  )
}

export default TablePagging
