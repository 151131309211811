import { useState } from 'react'
import { Button, TextInput, Group } from '@mantine/core'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'

import ProductsTable from 'screens/Products/ProductsTable'

import oneLink from 'api/onelink'
import { observer } from 'mobx-react'
import css from './Search.module.css'

const Search = observer(({ basketStore, urlKey, nextStep }) => {
  const [search, setSearch] = useState('')
  const resolve = usePreload()
  const { onUpdate, data } = resolve

  const onSearch = () => {
    onUpdate(() => oneLink.search(urlKey, { search }))
  }

  return (
    <Preload {...resolve}>
      <div>
        <h2>Product search</h2>

        <form
          className={css.SearchLine}
          onSubmit={(e) => {
            e.preventDefault()
            onSearch()
          }}
        >
          <TextInput placeholder="Input placeholder" size="md" onChange={({ target }) => setSearch(target.value)} />
          <Button size="md" type="submit">
            Search
          </Button>
        </form>

        {data && <ProductsTable basketStore={basketStore} products={data} onAddToBasket limit={15} />}

        <Group justify="center" mt="xl">
          <Button onClick={nextStep}>Continue to basket</Button>
        </Group>
      </div>
    </Preload>
  )
})

export default Search
