import { Button, Text } from '@mantine/core'
import { useState } from 'react'
import { notifications } from '@mantine/notifications'
import backend from 'api/backend'

export function SalesforceSyncButton() {
  const [isLoading, setIsLoading] = useState(false)
  const [lastSyncDuration, setLastSyncDuration] = useState<number | null>(null)

  const handleSync = async () => {
    setIsLoading(true)
    try {
      const response = await backend.syncSalesforce() as any

      if (response.success) {
        setLastSyncDuration(response.duration)
        notifications.show({
          title: 'Sync Complete',
          message: `Salesforce sync completed in ${response.duration.toLocaleString()}ms`,
          color: 'green'
        })
      } else {
        throw new Error(response.message)
      }
    } catch (error: any) {
      notifications.show({
        title: 'Sync Failed',
        message: error.message || 'Failed to sync with Salesforce',
        color: 'red'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button
        onClick={handleSync}
        loading={isLoading}
        disabled={isLoading}
        variant="default"
        size="sm"
        radius="sm"
      >
        {isLoading ? 'Syncing...' : 'Sync Salesforce Data'}
      </Button>

      {lastSyncDuration && !isLoading && (
        <Text size="xs" c="dimmed" fw={400}>
          Last sync took {lastSyncDuration.toLocaleString()} ms
        </Text>
      )}
    </>
  )
}
