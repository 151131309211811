import { useState } from 'react'
import { Accordion, Group, Text, Select } from '@mantine/core'
import { GiSpellBook } from 'react-icons/gi'
import { IoPricetagOutline } from 'react-icons/io5'
import { PiCurrencyDollarThin } from 'react-icons/pi'

//@ts-ignore
import css from '../CompanyAside.module.css'

const PriceAndCostBooks = ({ opportunityStore }) => {
  const [editBooks, setEditBooks] = useState(false)

  const handlePricebookChange = (pricebookId: string | null) => {
    if (!opportunityStore.opportunity || !pricebookId) return
    opportunityStore.updateOpportunityBooks({ price: pricebookId, cost: opportunityStore.opportunity.books!.cost })
  }

  const handleCostbookChange = (costbookId: string | null) => {
    if (!opportunityStore.opportunity || !costbookId) return
    opportunityStore.updateOpportunityBooks({ price: opportunityStore.opportunity.books!.price, cost: costbookId })
  }

  return (
    <Accordion.Item value="books">
      <Accordion.Control>
        <Group wrap="nowrap">
          <GiSpellBook size={20} style={{ strokeWidth: 1 }} />
          <Text>Price and cost books</Text>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        {!opportunityStore.loading && (
          <ul className={css.list}>
            {editBooks ? (
              <>
                <li>
                  Pricebook:
                  <Select
                    description="Active pricebook for this opportunity"
                    data={opportunityStore.bookStore.priceBooks.map((book: any) => ({ label: book.name, value: book._id }))}
                    value={opportunityStore.opportunity.books!.price}
                    onChange={handlePricebookChange}
                    variant="filled"
                    allowDeselect={false}
                    searchable
                    checkIconPosition="left"
                    maxDropdownHeight={400}
                    size="xs"
                    radius="xs"
                    leftSection={<IoPricetagOutline style={{ strokeWidth: 2, color: '#036672' }} />}
                    w={230}
                  />
                </li>
                <li>
                  Costbook:
                  <Select
                    description="Active costbook for this opportunity"
                    data={opportunityStore.bookStore.costBooks.map((book: any) => ({ label: book.name, value: book._id }))}
                    value={opportunityStore.opportunity.books.cost}
                    onChange={handleCostbookChange}
                    variant="filled"
                    allowDeselect={false}
                    searchable
                    checkIconPosition="left"
                    maxDropdownHeight={400}
                    size="xs"
                    radius="xs"
                    leftSection={<PiCurrencyDollarThin style={{ strokeWidth: 2, color: '#036672' }} />}
                    w={230}
                  />
                </li>
                <li>
                  <a style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => setEditBooks(false)}>
                    <Text size="xs" c="var(--teal-600)">
                      Save
                    </Text>
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  Pricebook: <b>{opportunityStore.bookStore.getBook(opportunityStore.opportunity.books.price)?.name}</b>
                </li>
                <li>
                  Costbook: <b>{opportunityStore.bookStore.getBook(opportunityStore.opportunity.books.cost)?.name}</b>
                </li>
                <li>
                  <a style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => setEditBooks(true)}>
                    <Text size="xs" c="var(--teal-600)">
                      Edit
                    </Text>
                  </a>
                </li>
              </>
            )}
          </ul>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default PriceAndCostBooks
