import { useParams } from 'react-router-dom'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import { PageHeader } from 'components/PageHeader/PageHeader'

import adminApi from 'api/admin'

// @ts-ignore
import css from './AccountPage.module.css'

import AccountDetails from 'screens/User/Account/AccountDetails'
import AccountUsers from 'screens/User/Account/AccountUsers'

const UserAccount = () => {
  const { id } = useParams()
  const resolve = usePreload(() => adminApi.getAccountById(id))
  const { data, onRetry }: { data: any; onRetry: any } = resolve

  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Admin', href: '/admin' },
    { label: 'Accounts', href: '/admin/accounts' },
    { label: data?.name || '' },
  ]

  return (
    <Preload {...resolve} className={css.layout}>
      {data && (
        <div>
          <PageHeader title={data?.name} breadcrumbs={breadcrumbs} />

          <AccountDetails data={data || {}} onRetry={onRetry} role="admin" />

          <AccountUsers users={data?.users} onRetry={onRetry} role="admin" accountId={data?._key} />
        </div>
      )}
    </Preload>
  )
}

export default UserAccount
