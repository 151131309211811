import { client } from './http'

class EmailTemplatesApi {
  getAll = () => client.get('/sales/email-templates')
  getOne = (name) => client.get(`/sales/email-templates/${name}`)
  update = (name, body) => client.put(`/sales/email-templates/${name}`, body)
}

const emailTemplates = new EmailTemplatesApi()
export default emailTemplates
