import { Link } from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import { TfiReload } from "react-icons/tfi";
import { IconTrashX } from '@tabler/icons-react'
import { Stack, Text, ActionIcon, Badge, Avatar, NumberFormatter, Loader, Group, Button } from '@mantine/core'
import { formatDistance } from 'date-fns'

import Table from 'components/Table/Table'

import opportunities from 'api/opportunities'
import opportunitiesStore from '../../stores/opportunitiesStore'

const statusColors = {
  initiated: 'teal',
  new: 'cyan',
  inProgress: 'blue',
  pending: 'indigo',
  orderCreated: 'violet',
  completed: 'green',
}

const OpportunitiesTable = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>(null)

  const fetchData = useCallback(async () => {
    if (isLoading) return

    try {
      setIsLoading(true)
      await opportunitiesStore.fetchOpportunities()
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData()
    const intervalId = setInterval(fetchData, 300000) // 5 mins
    return () => clearInterval(intervalId)
  }, [fetchData])

  const handleDelete = useCallback(async (_key) => {
    try {
      await opportunities.delete(_key)
      await opportunitiesStore.fetchOpportunities()
    } catch (err: any) {
      setError(err)
    }
  }, [])

  const columns = [
    {
      title: 'Company',
      render: ({ customer }) => (
        <div className="flex">
          <Avatar variant="outline" radius="xl" size="sm" color="orange" src={customer?.logo} alt={`Logo ${customer?.name || customer?.companyName}`} />
          <b>{customer?.name || customer?.companyName}</b>
        </div>
      ),
    },
    {
      title: 'Rep',
      render: ({ thread }) => {
        const createdBy = thread?.[0]?.createdBy
        return createdBy && (
          <div>
            <b>{createdBy.firstName} {createdBy.lastName}</b>
            <div><small>{createdBy.email}</small></div>
          </div>
        )
      },
    },
    {
      title: 'Created at',
      render: ({ createdAt }) => createdAt && formatDistance(new Date(createdAt), new Date(), { addSuffix: true }),
    },
    {
      title: 'Updated at',
      render: ({ updatedAt }) => updatedAt && formatDistance(new Date(updatedAt), new Date(), { addSuffix: true }),
    },
    {
      title: 'Type',
      textAlign: 'center',
      render: ({ order }) => (order ? 'Order' : 'Quote'),
    },
    {
      title: 'Status',
      textAlign: 'center',
      render: ({ status }) => status && (
        <Badge size="sm" color={statusColors[status]} variant="white">
          {status.split(/(?=[A-Z])/).join(' ')}
        </Badge>
      ),
    },
    {
      title: 'Items',
      textAlign: 'center',
      render: ({ quote, order }) => (order || quote)?.basket?.length,
    },
    {
      title: 'Last event',
      textAlign: 'right',
      render: ({ thread }) => {
        if (!thread?.length) return null
        const nonAutoReplies = thread.filter(t => t?.status !== 'autoReply')
        const myTurn = nonAutoReplies.at(-1)?.from === 'account'
        return (
          <Badge size="xs" variant="light" color={myTurn ? 'orange' : undefined}>
            {myTurn ? 'Our action' : 'Customer'}
          </Badge>
        )
      },
    },
    {
      title: 'Total order value',
      textAlign: 'right',
      render: ({ quote, order }) => {
        const basket = (order || quote)?.basket
        if (!basket?.length) return null

        const total = basket.reduce((sum, item) =>
          sum + ((item?.product?.sellingPrice || 0) * (item?.quantity || 0)), 0)

        return <NumberFormatter prefix="$ " value={total} thousandSeparator decimalScale={2} />
      },
    },
    { render: ({ _key }) => _key && <Link to={`/opportunities/${_key}`}>Open</Link> },
    {
      render: ({ _key }, { showConfirm }) => _key && (
        <ActionIcon
          variant="light"
          color="red"
          onClick={() => showConfirm(() => handleDelete(_key))}
        >
          <IconTrashX size={18} stroke={1.5} />
        </ActionIcon>
      ),
    },
  ]

  const filter = {
    render: ({ thread }) => {
      const nonAutoReplies = thread?.filter(t => t?.status !== 'autoReply') || []
      if (!nonAutoReplies.length) return 'All'
      return nonAutoReplies.at(-1)?.from === 'account' ? 'Pending customer' : 'Our turn'
    }
  }

  if (isLoading && opportunitiesStore.opportunities.length === 0) {
    return <Loader size="md" />
  }

  if (error) {
    return <div>Error loading opportunities: {error.message}. <button onClick={fetchData}>Retry</button></div>
  }

  return (
    <Stack gap={2}>
      <Group justify="flex-end" mb={0} pb={0}>
        <Button loading={isLoading} disabled={isLoading} onClick={fetchData} variant="default" leftSection={<TfiReload size={12} />} size="sm" radius="sm">
          <Text size="xs">Reload</Text>
        </Button>
      </Group>
      <Table
        data={opportunitiesStore.opportunities || []}
        columns={columns as any}
        filter={filter}
        orderBy={['Our turn', 'All', 'Pending customer']}
        style={{ marginTop: 0 }}
      />
    </Stack>
  )
}

export default observer(OpportunitiesTable)
