import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { Text, NumberFormatter, Group } from '@mantine/core'
import { observer } from 'mobx-react'
import Table from 'components/Table/Table'
import AddToBasket from '../Thread/SuggestedProducts/AddToBasket'
import BasketStore from '@/stores/BasketStore'

const Basket = ({ basketStore, label = '', canEdit = true }: { basketStore: BasketStore; label: string; canEdit?: boolean }) => {
  if (!basketStore || basketStore.basketSize() === 0) return null

  const columns = [
    {
      title: 'Product Code',
      render: ({ product: { ProductCode } }) => <small className="rounded outline w-600">{ProductCode}</small>,
    },
    {
      title: 'Name',
      render: ({ product: { Name, Description } }) => (
        <div>
          <div>{Name}</div>
          <div>
            <small>{Description}</small>
          </div>
        </div>
      ),
    },
    {
      title: 'Unit Price',
      textAlign: 'right',
      style: { whiteSpace: 'nowrap' },
      render: ({ product: { currency = '$' }, basketLine: { unitPrice } }) => (
        <NumberFormatter prefix={`${currency} `} value={unitPrice} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
      ),
    },
    {
      title: 'Quantity',
      textAlign: 'right',
      style: { whiteSpace: 'nowrap' },
      render: ({ product, basketLine: { quantity } }) => (
        <AddToBasket
          style={{ textAlign: 'right' }}
          quantity={quantity}
          canEdit={canEdit}
          onUpdateQuantity={(q: number) => basketStore.updateProductQuantity(product, q)}
        />
      ),
    },

    {
      title: 'Total',
      textAlign: 'right',
      style: { whiteSpace: 'nowrap' },
      render: ({ basketLine: { totalPrice } }) => (
        <NumberFormatter prefix="$ " value={totalPrice} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
      ),
    },
  ]

  const { total, subTotal, tax } = basketStore.totalDetails || {}

  return (
    <div>
      <Group>
        {label && (
          <Text size="md" fw={500}>
            {label}
          </Text>
        )}
        {/* {opportunityStore.opportunity?.quote?.version && <Badge color="orange">V {opportunityStore.opportunity?.quote?.version}</Badge>} */}
      </Group>

      <Table data={basketStore?.basketProducts} columns={columns} footer={false} rowKey="productCode">
        <tbody>
          <tr>
            <td colSpan={4} style={{ textAlign: 'right' }}>
              <Text size="sm" fw={500}>
                Gross Amount
              </Text>
            </td>
            <td style={{ textAlign: 'right' }}>
              <Text size="sm" fw={500}>
                <NumberFormatter prefix="$ " value={subTotal} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
              </Text>
            </td>
          </tr>
          <tr>
            <td colSpan={4} style={{ textAlign: 'right' }}>
              Tax 15%
            </td>
            <td style={{ textAlign: 'right' }}>
              <NumberFormatter prefix="$ " value={tax} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
            </td>
          </tr>
          <tr>
            <td colSpan={4} style={{ textAlign: 'right' }}>
              <Text size="sm" fw={500}>
                Total Amount
              </Text>
            </td>
            <td style={{ textAlign: 'right' }}>
              <Text size="sm" fw={500}>
                <NumberFormatter prefix="$ " value={total} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
              </Text>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default observer(Basket)
