import { client } from './http'

class OneLinkApi {
  customerReply = (urlKey, body) => client.put(`/sales/onelink/opportunities/${urlKey}`, body)
  updateCustomer = (urlKey, body) => client.put(`/sales/onelink/customers/${urlKey}`, body)
  updateQuote = (urlKey, body) => client.put(`/sales/onelink/quote/${urlKey}`, body)
  updateQuoteStatus = (urlKey, data) => client.put(`/sales/onelink/quote/status/${urlKey}`, data)
  search = (urlKey, body) => client.post(`/sales/onelink/search/${urlKey}`, body)
}

const oneLink = new OneLinkApi()
export default oneLink
